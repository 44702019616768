<template >
  <div class="container" id="quotes">
    <div v-if="loaded">
      <!-- <div>
        <p>Quotes older than 24 hours that have expired will not be shown here</p>
      </div> -->
      <div v-if="quotes.length">
        <h1>Sent Quotes</h1>
        <b-form-group label-cols-md="3" label="Filter" class="mb-3">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search" />
            <b-input-group-append>
              <b-btn :disabled="!filter" @click="filter = ''">Clear</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-table :items="quotes" :fields="fields" :filter="filter" @filtered="onFiltered" :current-page="currentPage" :per-page="perPage" striped stacked="md">

          <template #cell(propertyCode)="data">
            <b-link :to="propertyLinks[data.item.bookRef]">{{data.item.propertyCode}}</b-link>
          </template>

          <template #cell(bookRef)="data">
            <b-button size="sm" :to="'/admin/agent/bookings/' + data.item.bookRef" :variant="data.item.available?'primary':'danger'">{{data.item.bookRef}}</b-button>
          </template>

          <template #cell(arrival)="data">
            {{ $moment(data.item.arrival, 'YYYY-MM-DD').format('D[&nbsp;]MMM[&nbsp;]YYYY') }}
          </template>

          <template #cell(available)="data">
            {{ data.item.available?'Yes':'No' }}
          </template>

          <template #cell(quoteExpiry)="data">
            <span v-html="data.item.quoteExpiry"></span>
          </template>

          <template #cell(total)="data">
            {{ data.item.total | currency }}
          </template>

        </b-table>

        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
      </div>
      <div v-else>
        <p>No sent quotes to display</p>
      </div>
    </div>
    <div v-else class="loader-outer">
      <loader width="100px" height="100px"></loader>
    </div>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

export default {
  name: 'SentQuotes',

  components: {
    loader
  },

  mounted () {
    this.loadQuotes()
  },

  methods: {

    loadQuotes () {
      this.loaded = false
      this.quotes = []
      this.totalRows = 0
      this.propertyLinks = {}
      const apiUrl = '/quotes-sent'
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        if (response.data.response.hasOwnProperty('quoteDetails')) {
          for (const i in response.data.response.quoteDetails) {
            const thisQuote = response.data.response.quoteDetails[i]
            const quoteExpiryFormatted = this.$moment(thisQuote.quoteExpiry.expires, 'YYYY-MM-DD HH:mm:ss').format('D[&nbsp;]MMM[&nbsp;]YYYY[<br />][at][&nbsp;]HH:mm')// 09:33 on Saturday 13th July 2019
            this.quotes.push({
              bookRef: thisQuote.bookRef,
              propertyCode: thisQuote.property.propertyCode,
              recipientEmail: thisQuote.recipientEmail,
              arrival: thisQuote.property.arrival,
              nights: thisQuote.property.nights,
              total: thisQuote.total,
              available: (thisQuote.bookedStatus === 'AVAILABLE' && thisQuote.property.propertyStatus === 'AVAILABLE' && !thisQuote.quoteExpiry.expired),
              quoteExpiry: quoteExpiryFormatted + (thisQuote.quoteExpiry.expired ? '<br /><b>EXPIRED</b>' : '')
            })
            this.propertyLinks[thisQuote.bookRef] = '/villa/' + thisQuote.property.propertyId
          }
          this.quotes.reverse()
        }
        this.totalRows = this.quotes.length
        this.loaded = true
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },

  data () {
    return {
      loaded: false,
      filter: null,
      currentPage: 1,
      perPage: 20,
      quotes: [],
      totalRows: 0,
      fields: [
        {
          key: 'bookRef',
          label: 'Villa Choices Ref',
          sortable: true
        },
        {
          key: 'available',
          label: 'Available',
          sortable: true
        },
        {
          key: 'propertyCode',
          label: 'Property Code',
          sortable: true
        },
        {
          key: 'recipientEmail',
          label: 'Recipient Email',
          sortable: true
        },
        {
          key: 'arrival',
          label: 'Check in',
          sortable: true
        },
        {
          key: 'nights',
          label: 'Nights',
          sortable: true
        },
        {
          key: 'total',
          label: 'Quoted Price',
          sortable: true
        },
        {
          key: 'quoteExpiry',
          label: 'Expires',
          sortable: true
        }
      ]
    }
  }
}
</script>

<style lang="scss">
  #quotes{
    table{
      td, th,{
        font-size:0.9rem;
      }
    }
  }
</style>
