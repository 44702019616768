<template>
  <div class="page-404">
    <div class="bg"></div>
    <div class="content">
      <h1>Oops</h1>
      <p>
        Page not found
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404',
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/404.scss';
</style>
