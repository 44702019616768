<template>
  <b-container id="Signup">
    <div v-if="loaded">
      <div v-if="invalidCredentials">
        <p>{{errorMessage}}</p>
      </div>
      <div v-else>
        <h1>Agent Signup - Complete details</h1>
        <section>
          <p>Please enter your details for administration purposes. These will not be displayed on your customer correspondence so can be different to information already supplied.  If you need to change this information at anytime please email us at <b-link :href="'mailto:' + $t('global.vcEmail')">{{ $t('global.vcEmail') }}</b-link></p>
        </section>
        <b-form @submit.prevent="checkForm" autocomplete="off" :novalidate="true">
          <fieldset :disabled="signupFormSubmitting">

            <b-row>
              <b-col>
                <section class="passenger-address">
                  <h2>Your Address</h2>
                  <client-address @updated="addressUpdated" ref="address"></client-address>
                </section>
              </b-col>
            </b-row>

            <article class="page-content text-center">
              <loader v-if="signupFormSubmitting"></loader>
              <div v-else>
                <b-btn variant="primary" size="lg" type="submit">
                  Complete Signup
                </b-btn>
                <p v-if="formValidationErrors === true">Please fix the form errors above before submitting this form</p>
              </div>
            </article>
          </fieldset>
        </b-form>
        <b-modal ref="serverValidationErrors" title="The following form fields contain errors" header-text-variant="light" centered header-bg-variant="danger" ok-only>
          <ul v-if="serverValidationErrors">
            <li v-for="theError in validationErrors">
              {{theError.error}}
            </li>
          </ul>
        </b-modal>
      </div>
    </div>
    <div v-else>
      <div class="loader-outer">
        <loader width="100px" height="100px"></loader>
      </div>
    </div>
  </b-container>
</template>

<script>
import loader from '@/components/widgets/loader'
import address from '@/components/widgets/form-fields/address'

export default {
  name: 'Signup',

  components: {
    loader,
    'client-address': address
  },

  mounted () {
    this.completeSignup()
  },

  methods: {

    addressUpdated (event) {
      this.formValidationErrors = false
      this.signupUserDetails.address.address1 = event.data.address1
      this.signupUserDetails.address.address2 = event.data.address2
      this.signupUserDetails.address.addTown = event.data.addTown
      this.signupUserDetails.address.addCounty = event.data.addCounty
      this.signupUserDetails.address.addPostcode = event.data.addPostcode
      this.signupUserDetails.address.addCountryId = event.data.addCountryId
    },

    checkForm (evt) {
      this.signupFormSubmitting = true
      this.formValidationErrors = false
      const addressValidator = this.$refs.address.validator()
      Promise.all([addressValidator.validate()]).then((values) => {
        if (values.includes(false)) {
          this.signupFormSubmitting = false
          this.formValidationErrors = true
        } else {
          this.submitForm()
        }
      })
    },

    submitForm () {
      const apiUrl = '/agent/signup-complete'
      this.$apiCaller({ route: apiUrl, method: 'POST', data: this.signupUserDetails, displayErrorAlert: false }).then((response) => {
        localStorage.removeItem('accountSignupIncomplete')
        this.$router.push('/admin/agent/dashboard')
      })
        .catch((error) => {
          const responseData = error.response.data.data
          if (responseData.hasOwnProperty('response') && responseData.response.hasOwnProperty('validationErrors')) {
            this.serverValidationErrors = true
            this.validationErrors = responseData.response.validationErrors
            this.$refs.serverValidationErrors.show()
          } else {
            this.invalidCredentials = true
          }
        })
        .finally(() => {
          this.loaded = true
        })
    },

    completeSignup () {
      this.loaded = false
      const apiUrl = '/agent/signup-complete'
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '', displayErrorAlert: false }).then((response) => {
        // alls good
      })
        .catch(() => {
          this.invalidCredentials = true
        })
        .finally(() => {
          this.loaded = true
        })
    }
  },

  data () {
    return {
      loaded: false,
      invalidCredentials: false,
      errorMessage: 'Sorry we are unable to locate your agent information. It may be you have navigated to this page in error and have already completed this stage in the signup process.',
      signupFormSubmitting: false,
      formValidationErrors: false,
      serverValidationErrors: false,
      signupUserDetails: {
        address: {}
      }
    }
  }
}
</script>

<style lang="scss">

</style>
