import {
  TOGGLE_AGENT_VIEW,
  AGENT_VIEW_OFF
} from '@/store/actions/agent'

let agentViewState = ''
if (localStorage.getItem('agent-view')) {
  if (localStorage.getItem('agent-view') === 'true') {
    document.body.classList.add('agent-view')
    agentViewState = true
  } else {
    document.body.classList.remove('agent-view')
    agentViewState = false
  }
} else {
  agentViewState = false
  document.body.classList.remove('agent-view')
}

const state = {
  agentView: agentViewState
}

const getters = {
  getAgentView: (state) => state.agentView
}

const actions = {
  [TOGGLE_AGENT_VIEW]: ({ commit }) => {
    commit(TOGGLE_AGENT_VIEW)
  },

  [AGENT_VIEW_OFF]: ({ commit }) => {
    commit(AGENT_VIEW_OFF)
  }
}

const mutations = {
  [TOGGLE_AGENT_VIEW]: (state) => {
    const el = document.body

    if (state.agentView === false) {
      state.agentView = true
      el.classList.add('agent-view')
      localStorage.setItem('agent-view', 'true')
    } else {
      state.agentView = false
      el.classList.remove('agent-view')
      localStorage.setItem('agent-view', 'false')
    }
  },

  [AGENT_VIEW_OFF]: (state) => {
    const el = document.body
    state.agentView = false
    el.classList.remove('agent-view')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
