<template>
  <div>
    <div id="mapHolder" ref="mapHolder" style="height: 650px;"></div>
  </div>
</template>

<script>
import GoogleMapsLoader from 'google-maps'

export default {
  name: 'dv-google-map-init',

  props: {
    markers: {
      type: Object,
      required: false
    },

    placeHover: {
      type: Object,
      required: false
    },

    placeClick: {
      type: Object,
      required: false
    }
  },

  watch: {
    placeClick: {
      handler: function (newVal) {
        if (newVal.hasOwnProperty('place') && newVal.hasOwnProperty('index')) {
          this.closeInfoWindow()

          this.dvGoogleMap.panTo({
            lat: this.dvMapMarkers[newVal.place][newVal.index].position.lat(),
            lng: this.dvMapMarkers[newVal.place][newVal.index].position.lng()
          })

          setTimeout(function () {
            this.dvMapMarkers[newVal.place][newVal.index].setAnimation(-1)
            this.dvGoogleMap.setZoom(15)
          }.bind(this), 500)

          setTimeout(function () {
            this.openInfoWindow(newVal.place, newVal.index)
          }.bind(this), 750)
        }
      },
      deep: true
    },

    placeHover: {
      handler: function (newVal) {
        if (newVal.hasOwnProperty('index') && newVal.hasOwnProperty('place')) {
          for (const markerPlace in this.dvMapMarkers) {
            if (this.dvMapMarkers.hasOwnProperty(markerPlace)) {
              for (const marker in this.dvMapMarkers[markerPlace]) {
                if (this.dvMapMarkers[markerPlace].hasOwnProperty(marker)) {
                  this.dvMapMarkers[markerPlace][marker].setAnimation(-1)
                }
              }
            }
          }

          this.dvMapMarkers[newVal.place][newVal.index].setAnimation(this.googlemaps.Animation.BOUNCE)
        }
      },
      deep: true
    }
  },

  methods: {
    updateMarkerState (newState) {
      if (newState.visible === false) {
        this.dvMapMarkers[newState.place][newState.index].setMap(null)
      } else {
        this.dvMapMarkers[newState.place][newState.index].setMap(this.dvGoogleMap)
      }
    },

    closeInfoWindow () {
      this.infoWindow.setContent('')
      this.infoWindow.close()
    },

    openInfoWindow (markerType, markerIndex) {
      this.closeInfoWindow()

      if (this.locationInfo.hasOwnProperty(markerType) && this.dvMapMarkers.hasOwnProperty(markerType)) {
        if (this.locationInfo[markerType].hasOwnProperty(markerIndex) && this.dvMapMarkers[markerType].hasOwnProperty(markerIndex)) {
          this.infoWindow.setContent(this.locationInfo[markerType][markerIndex])
          this.infoWindow.open(this.dvGoogleMap, this.dvMapMarkers[markerType][markerIndex])
        }
      }
    }
  },

  mounted () {
    GoogleMapsLoader.VERSION = '3.34'
    GoogleMapsLoader.KEY = 'AIzaSyD4hLOvCIFFASMc22ObVIQyoa2hUxNcFHo'
    GoogleMapsLoader.REGION = 'GB'

    GoogleMapsLoader.load(function (google) {
      this.googlemaps = google.maps

      /* eslint-disable no-new */
      this.dvGoogleMap = new this.googlemaps.Map(this.$refs.mapHolder, {
        zoom: 12,
        center: this.centerMap,
        styles: this.mapStyles,
        mapTypeControl: false,
        scaleControl: true,
        rotateControl: false,
        streetViewControl: false,
        fullscreenControl: false
      })

      this.infoWindow = new this.googlemaps.InfoWindow({
        content: '',
        maxWidth: 500,
        disableAutoPan: true
      })

      this.dvGoogleMap.addListener('click', function () {
        this.closeInfoWindow()

        for (const markerPlace in this.dvMapMarkers) {
          if (this.dvMapMarkers.hasOwnProperty(markerPlace)) {
            for (const marker in this.dvMapMarkers[markerPlace]) {
              if (this.dvMapMarkers[markerPlace].hasOwnProperty(marker)) {
                this.dvMapMarkers[markerPlace][marker].setAnimation(-1)
              }
            }
          }
        }

        this.$emit('mapClick')
      }.bind(this))

      if (this.markers) {
        Object.keys(this.markers).forEach(function (key, index) {
          this.locationInfo[key] = []
          this.dvMapMarkers[key] = this.markers[key].map(function (location, i) {
            this.locationInfo[key][i] = ''

            const columnWidths = {
              img: 'col-sm-5',
              text: 'col-sm-12'
            }

            this.locationInfo[key][i] += '<div class="info-window-inner">'
            this.locationInfo[key][i] += '<div class="row">'

            if (location.hasOwnProperty('image')) {
              if (location.image) {
                columnWidths.text = 'col-sm-7'

                this.locationInfo[key][i] += '<div class="' + columnWidths.img + '">'
                this.locationInfo[key][i] += '<div class="map-thumb-image ' + key + '" style="background-image: url(' + location.image.imgUrl + ');"></div>'
                this.locationInfo[key][i] += '</div>'
              }
            }

            let locationUrl = ''
            let searchUrl = ''

            if (location.hasOwnProperty('url')) {
              locationUrl = location.url
            }

            if (location.hasOwnProperty('comId')) {
              searchUrl = '/villas?filters%5Bcommunity%5D=' + location.comId
            }

            this.locationInfo[key][i] += '<div class="' + columnWidths.text + '">'
            this.locationInfo[key][i] += '<strong style="display: block; margin-bottom: 5px;">' + location.name + '</strong>'

            if (location.hasOwnProperty('attractionDesc')) {
              this.locationInfo[key][i] += '<p style="font-size: 1.2em; margin-bottom: 5px;">' + location.attractionDesc + '</p>'
            }

            if (locationUrl !== '') {
              this.locationInfo[key][i] += '<p style="font-size: 1.2em; margin-bottom: 5px;"><a href="' + locationUrl + '">Read more about ' + location.name + '</a></p>'
            }

            if (searchUrl !== '') {
              this.locationInfo[key][i] += '<p style="font-size: 1.2em; margin-bottom: 5px;"><a href="' + searchUrl + '">View Villas on ' + location.name + '</a></p>'
            }

            if (location.hasOwnProperty('facilities')) {
              this.locationInfo[key][i] += '<ul class="feature-icons" style="margin: 0; padding: 0; list-style: none;">'

              for (let a = 0; a < location.facilities.length; a++) {
                const desc = location.facilities[a].title
                const icon = location.facilities[a].iconUrl

                this.locationInfo[key][i] += '<li style="float: left; width: 30px; padding: 2px;">'
                this.locationInfo[key][i] += '<img data-toggle="popover" data-trigger="hover" data-content="' + location.facilities[a].title + '" src="' + icon + '" title="' + desc + '" alt="' + desc + '" style="max-width: 100%" class="img-responsive"/>'
                this.locationInfo[key][i] += '</li>'
              }

              this.locationInfo[key][i] += '</ul>'
            }

            this.locationInfo[key][i] += '</div>'
            this.locationInfo[key][i] += '</div>'
            this.locationInfo[key][i] += '</div>'

            const marker = new this.googlemaps.Marker({
              position: {
                lat: parseFloat(location.lat),
                lng: parseFloat(location.lon)
              },
              map: this.dvGoogleMap,
              icon: require('@/assets/images/icons/map-markers/' + key + '.png'),
              title: location.name
            })

            marker.addListener('mouseover', function () {
              this.openInfoWindow(key, i)
              this.$emit('markerHover', { key, index: i })
            }.bind(this))

            marker.addListener('click', function () {
              this.dvGoogleMap.panTo({
                lat: marker.position.lat(),
                lng: marker.position.lng()
              })

              this.openInfoWindow(key, i)
              this.$emit('markerClick', { key, index: i })
            }.bind(this))

            return marker
          }.bind(this))
        }.bind(this))
      }
    }.bind(this))
  },

  data () {
    return {
      googlemaps: null,
      dvGoogleMap: null,
      infoWindow: null,
      locationInfo: {},
      dvMapMarkers: {},

      centerMap: {
        lat: 28.301885,
        lng: -81.564533
      },

      mapStyles: [
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fafafb'
            }
          ]
        },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fafafb'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.attraction',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.government',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.medical',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.park',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.place_of_worship',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.school',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.sports_complex',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#b8d432'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#b8d432'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#8dd8f8'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#8dd8f8'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#00baf2'
            }
          ]
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#00baf2'
            }
          ]
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fdfefe'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#18b288'
            },
            {
              lightness: 55
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#c7eafc'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#c7eafc'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
#mapHolder {
  .info-window-inner {
    @include media-breakpoint-up(sm) {
      width: 500px;
      margin: 0;
      overflow-x: hidden;
    }

    @include media-breakpoint-down(xs) {
      width: 150px;
      max-width: 150px;
      overflow-x: hidden;
    }

    .map-thumb-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      min-height: 125px;

      @include media-breakpoint-down(xs) {
        margin-bottom: 10px;
      }

      &.community {
        padding-bottom: 66.5%;
      }

      &.attraction {
        padding-bottom: 77.73%;
      }
    }
  }
}
</style>
