<template>
  <div class="container">
    <div class="home-jumbo">
      <b-alert :show="roleStatusError" variant="danger" fade>
        <h4>Oh dear...</h4>
        <p>You do not have access to visit the requested url</p>
      </b-alert>
      <!-- <div class="buttons">
        <div class="inner">
          <b-row>
            <b-col cols="12" class="text-center" v-if="this.$store.getters.userRole == 'AGENT'">
              <b-button variant="success" :to="'/admin/agent'">Agent Admin</b-button>
            </b-col>

            <b-col cols="12" class="text-center" v-if="this.$store.getters.userRole == 'ORGANISATION'">
              <b-button variant="success" :to="'/admin/organisation'">Organisation Admin</b-button>
            </b-col>

            <b-col cols="12" class="text-center" v-if="this.$store.getters.userRole == 'ACCOUNTS'">
              <b-button variant="success" :to="'/admin/accounts'">Accounts Admin</b-button>
            </b-col>
          </b-row>
        </div>
      </div> -->
    </div>
  </div>

</template>

<script>
export default {
  name: 'Home',

  mounted () {
    this.homePage()
  },
  watch: {
    '$route' (to, from) {
      // react to route changes... They may be on the homepage when they change the url to one that they do not have access to view
      this.homePage()
    }
  },
  methods: {
    homePage () {
      this.roleStatusError = false
      if (this.$route.query.hasOwnProperty('rolestatus') && this.$route.query.rolestatus === 'invalid') {
        this.roleStatusError = true
      }
    }
  },
  data () {
    return {
      roleStatusError: false
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/Home.scss';
</style>
