<template>
  <b-container>
    <div v-if="loaded">
      <article class="page-content">
        <dv-google-map :markers="mapMarkers"></dv-google-map>
      </article>
    </div>
    <div v-else>
      <div class="loader-outer">
        <loader width="100px" height="100px"></loader>
      </div>
    </div>
  </b-container>
</template>

<script>
import dvGoogleMap from '@/components/widgets/map/dv-google-map.vue'
import loader from '@/components/widgets/loader'

export default {
  name: 'about-florida-map',

  components: {
    'dv-google-map': dvGoogleMap,
    loader
  },

  mounted () {
    this.getMapData()
  },

  methods: {
    getMapData () {
      this.loaded = false
      const apiUrl = this.$route.fullPath
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        if (response.data.response.hasOwnProperty('communities')) {
          for (const i in response.data.response.communities) {
            const community = response.data.response.communities[i]
            for (const j in community.facilities) {
              community.facilities[j].iconUrl = require('@/assets/images/icons/' + community.facilities[j].icon + '.png')
            }
            community.url = '/community/' + community.comId
            this.mapMarkers.communities.push(community)
          }
        }

        if (response.data.response.hasOwnProperty('attractions')) {
          for (const i in response.data.response.attractions) {
            const attraction = response.data.response.attractions[i]
            if (attraction.hasOwnProperty('description')) {
              attraction.attractionDesc = attraction.description
            }
            if (attraction.hasOwnProperty('aboutId')) {
              attraction.url = '/about-florida/theme-parks/' + attraction.aboutId
            }
            this.mapMarkers.attractions.push(attraction)
          }
        }

        if (response.data.response.hasOwnProperty('airports')) {
          this.mapMarkers.airports = response.data.response.airports
        }

        this.loaded = true
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },

  data () {
    return {
      loaded: false,
      mastheadImage: 'https://www.debbiesvillas.co.uk/dist/images/about-florida/head.jpg',
      mapMarkers: {
        communities: [],
        attractions: [],
        airports: []
      }
    }
  }
}
</script>
