<template>
  <div class="masthead">
    <div class="inner" v-bind:class="{'m-bottom': scrollY >= 105}">
      <b-link to="/">
        <b-img src="@/assets/images/Villa-Choices-Logo-whiteborder.png" alt="Villa Choices" class="logo" />
      </b-link>

      <div class="white-label-customer" v-if="($store.getters.isAuthenticated && $store.getters.companyDisplayName)">
        by <strong>{{$store.getters.companyDisplayName}}</strong>

        <template v-if="$store.getters.isImpersonating">
          <div><b-badge variant="danger text-uppercase">Impersonating</b-badge></div>
        </template>
      </div>

      <b-link @click.prevent="$parent.logout" class="log-out" v-if="$store.getters.isAuthenticated">{{ 'global.header.logOut' | translate }}</b-link>
    </div>

    <div class="nav-outer" id="stickyNav" v-bind:class="{sticky: scrollY >= 105}">
      <b-navbar toggleable="sm" type="light" variant="light" v-if="$store.getters.isAuthenticated && $store.getters.userRole === 'AGENT'">
        <b-link to="/">
          <b-img src="@/assets/images/villa_choices_logo_small.png" class="small-logo"></b-img>
        </b-link>

        <div class="agent-button" v-if="$store.getters.isAuthenticated">
          <toggle-button id="agentModeSwitch" @change="toggleAgentView" :value="$store.getters.getAgentView" :labels="{checked: 'Agent View On', unchecked: 'Agent View Off'}" :width="160" :height="35" :color="{checked: '#dc3545', unchecked: '#6c757d'}" />
        </div>

        <div class="container" v-if="!this.$route.fullPath.includes('/admin/agent/complete-signup')">
          <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

          <b-collapse is-nav id="nav_collapse">
            <b-container>
              <b-navbar-nav class="nav-fill w-100">
                <b-nav-item to="/villas">{{ 'global.header.navigation.properties' | translate }}</b-nav-item>
                <b-nav-item to="/optional-extras">{{ 'global.header.navigation.optionalExtras' | translate }}</b-nav-item>
                <b-nav-item to="/communities">{{ 'global.header.navigation.communities' | translate }}</b-nav-item>
                <b-nav-item to="/about-florida">{{ 'global.header.navigation.aboutFlorida' | translate }}</b-nav-item>
                <b-nav-item to="/admin/agent">{{ 'global.header.navigation.agentAdmin' | translate }}</b-nav-item>
              </b-navbar-nav>
            </b-container>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import { TOGGLE_AGENT_VIEW } from '@/store/actions/agent'

export default {

  name: 'Header',
  mounted () {
    window.addEventListener('scroll', (event) => {
      this.scrollY = Math.round(window.scrollY)
    })
  },

  methods: {
    toggleAgentView () {
      this.$store.dispatch(TOGGLE_AGENT_VIEW)
    }
  },
  data () {
    return {
      scrollY: null
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/base/header.scss';
</style>
