<template>
  <div v-if="loaded">
    <div v-if="bookings.length">
    <div v-for="(bookings, key) in bookings" :key="key">
      <h1>{{bookings.statusCode}} ({{bookings.bookings.length}})</h1>
      <b-table :items="bookings.bookings" :fields="fields" striped stacked="md">
        <!--  :filter="filter" @filtered="onFiltered" :current-page="currentPage" :per-page="perPage"  -->
        <template #cell(clientName)="data">
          {{data.item.client.clientTitle}} {{data.item.client.clientSurname}}
        </template>
        <template #cell(bookRef)="data">
          <b-button size="sm" variant="primary" :to="'/admin/agent/bookings/' + data.item.bookRef">{{data.item.bookRef}}</b-button>
        </template>
      </b-table>
    </div>
  </div>
  <div v-else>
    <p>No bookings to display</p>
  </div>
    <!--
    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
    -->
  </div>
  <div v-else>
    <div class="loader-outer">
      <loader width="100px" height="100px"></loader>
    </div>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

export default {
  name: 'CustomerBookings',
  components: {
    loader
  },

  mounted () {
    this.loadBookings()
  },

  methods: {
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    loadBookings () {
      this.loaded = false
      this.bookings = [] // reset the bookings array
      const apiUrl = '/bookings'
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        if (response.data.response.hasOwnProperty('bookings')) {
          for (const i in this.bookingStatusCodes) {
            const bookingStatusCode = this.bookingStatusCodes[i]
            if (response.data.response.bookings.hasOwnProperty(bookingStatusCode)) {
              this.bookings.push({
                statusCode: bookingStatusCode,
                bookings: response.data.response.bookings[bookingStatusCode]
              })
            }
          }
        }
        this.loaded = true
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    },

    formatCheckIn (value) {
      return this.$moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    formatBookDate (value) {
      return this.$moment(value, 'YYYY-MM-DD h:mm:ss').format('DD/MM/YYYY') /* HH:mm -dont need the time */
    }
  },

  data () {
    return {
      bookingStatusCodes: ['RESERVE', 'PAYPENDING', 'BOOKING'],
      loaded: false,
      filter: null,
      currentPage: 1,
      perPage: 5,
      bookings: [],
      fields: [
        {
          key: 'bookRef',
          label: 'Villa Choices Ref',
          sortable: false
        },
        {
          key: 'agentRef',
          label: 'Your Ref',
          sortable: false
        },
        {
          key: 'property.propertyCode',
          label: 'Property Code',
          sortable: false
        },
        {
          key: 'clientName',
          label: 'Client Name',
          sortable: false
        },
        {
          key: 'property.arrival',
          label: 'Check in',
          formatter: this.formatCheckIn,
          sortable: false
        },
        {
          key: 'property.nights',
          label: 'Nights',
          sortable: false
        },
        {
          key: 'bookDate',
          label: 'Date booked',
          formatter: this.formatBookDate,
          sortable: false
        }
      ]
    }
  }
}
</script>
