<template>
  <div id="mapControls" role="tablist">
    <template v-if="initComplete">
      <b-card no-body class="mb-1 no-bg" :key="key" v-for="(place, key) in places" v-if="key !== 'airports'">
        <b-card-header header-tag="header" class="pr-3 pt-0 pb-0" role="tab">
          <b-btn class="section-header-button" block href="#" v-b-toggle="'accordion_' + key" :variant="collapseSections[key].collapsed ? 'primary' : 'secondary'">
            {{ key }}
            <span class="indicator">
              <font-awesome-icon v-show="!collapseSections[key].collapsed" :icon="showContent"></font-awesome-icon>
              <font-awesome-icon v-show="collapseSections[key].collapsed" :icon="hideContent"></font-awesome-icon>
            </span>
          </b-btn>

          <div class="show-hide-all">
            <b-form-checkbox @change="toggleAllMarkers(key, !showHideAllMarkers[key].state)" v-model="showHideAllMarkers[key].state" :indeterminate="showHideAllMarkers[key].indeterminate">
              <template v-if="visibleMarkerCount[key] === place.length">
               Hide all {{ key }}
              </template>

              <template v-if="visibleMarkerCount[key] === 0">
                Show all {{ key }}
              </template>

              <template v-if="(visibleMarkerCount[key] < place.length) && (visibleMarkerCount[key] > 0)">
                Showing {{ visibleMarkerCount[key] }} of {{ place.length }} {{ key }}
              </template>
            </b-form-checkbox>
          </div>
        </b-card-header>
        <b-collapse :id="'accordion_' + key" :visible="collapseSections[key].collapsed" v-model="collapseSections[key].collapsed" accordion="my-accordion" role="tabpanel">
          <b-list-group>
            <b-list-group-item
              :ref="key + '_' + index"
              :id="key + '_' + index"
              @mouseover="placeHover(key, index)"
              :class="{active: sectionStates[key][index].selected}"
              :key="key + '_' + index"
              v-for="(marker, index) in place">

              <div class="marker-name-container" @click="placeClick(key, index, true)">
                {{ marker.name }}
              </div>

              <div class="checkbox-container">
                <b-form-checkbox @input="toggleSingleMarker(key, index)" v-model="sectionStates[key][index].visible"></b-form-checkbox>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-collapse>
      </b-card>
    </template>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'dv-google-map-controls',

  components: {
    FontAwesomeIcon
  },

  props: {
    places: {
      type: Object,
      required: true
    },

    mapMarkerClicked: {
      type: Object,
      required: false
    }
  },

  computed: {
    showContent () {
      return faPlusSquare
    },

    hideContent () {
      return faMinusSquare
    }
  },

  mounted () {
    this.init()
  },

  watch: {
    mapMarkerClicked: {
      handler: function (newVal) {
        if (newVal === null) {
          this.clearSelection()
        } else {
          this.placeClick(newVal.key, newVal.index, false)
        }
      },
      deep: true
    },

    sectionStates: {
      handler: function (newVal, oldVal) {
        for (const category in newVal) {
          let visible = 0
          if (newVal.hasOwnProperty(category)) {
            for (const key in newVal[category]) {
              if (newVal[category].hasOwnProperty(key)) {
                if (newVal[category][key].visible === true) {
                  visible++
                }
              }
            }
          }
          this.visibleMarkerCount[category] = visible
          this.toggleIndeterminateStates(category)
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    toggleSingleMarker (category, key) {
      this.$emit('markerVisibilityChanged', { place: category, index: key, visible: this.sectionStates[category][key].visible })
    },

    toggleAllMarkers (category, state) {
      for (const key in this.sectionStates[category]) {
        if (this.sectionStates[category].hasOwnProperty(key)) {
          this.sectionStates[category][key].visible = state
          this.toggleSingleMarker(category, key)
        }
      }
    },

    toggleIndeterminateStates (category) {
      if ((this.visibleMarkerCount[category] > 0) && (this.visibleMarkerCount[category] < this.places[category].length)) {
        this.showHideAllMarkers[category].indeterminate = true
      }

      if ((this.visibleMarkerCount[category] > 0) && (this.visibleMarkerCount[category] === this.places[category].length)) {
        this.showHideAllMarkers[category].indeterminate = false
      }

      if (this.visibleMarkerCount[category] === 0) {
        this.showHideAllMarkers[category].indeterminate = false
        this.showHideAllMarkers[category].state = false
      } else {
        this.showHideAllMarkers[category].state = true
      }
    },

    init () {
      this.initComplete = false
      if (this.places) {
        let count = 1

        for (const place in this.places) {
          if (this.places.hasOwnProperty(place)) {
            this.$set(this.collapseSections, place, {})
            this.$set(this.visibleMarkerCount, place, this.places[place].length)
            this.$set(this.showHideAllMarkers, place, { state: true, indeterminate: false })

            if (count === 1) {
              this.$set(this.collapseSections[place], 'collapsed', true)
            } else {
              this.$set(this.collapseSections[place], 'collapsed', false)
            }

            this.$set(this.sectionStates, place, {})

            for (let i = 0; i < this.places[place].length; i++) {
              this.$set(this.sectionStates[place], i, { selected: false, visible: true })
            }

            count++
          }
        }
      }

      this.initComplete = true
    },

    placeHover (place, index) {
      this.$emit('itemHover', { place, index })
    },

    clearSelection () {
      for (const p in this.sectionStates) {
        if (this.sectionStates.hasOwnProperty(p)) {
          for (const i in this.sectionStates[p]) {
            if (this.sectionStates[p].hasOwnProperty(i)) {
              this.sectionStates[p][i].selected = false
            }
          }
        }
      }
    },

    placeClick (place, index, emit) {
      for (const p in this.sectionStates) {
        if (this.sectionStates.hasOwnProperty(p)) {
          for (const i in this.sectionStates[p]) {
            if (this.sectionStates[p].hasOwnProperty(i)) {
              this.sectionStates[p][i].selected = false
            }
          }
        }
      }

      if (place !== 'airports') {
        this.sectionStates[place][index].selected = true

        if (this.collapseSections[place].collapsed === true) {
          document.getElementById(place + '_' + index).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
        } else {
          for (const key in this.collapseSections) {
            if (this.collapseSections.hasOwnProperty(key)) {
              if (key === place) {
                this.collapseSections[key].collapsed = true
              } else {
                this.collapseSections[key].collapsed = false
              }
            }
          }
          setTimeout(function () {
            document.getElementById(place + '_' + index).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
          }, 500)
        }

        if (emit === true) {
          this.$emit('itemClicked', { place, index })
        }
      }
    }
  },

  data () {
    return {
      initComplete: false,
      collapseSections: {},
      sectionStates: {},
      showHideAllMarkers: {},
      visibleMarkerCount: {}
    }
  }
}
</script>

<style lang="scss">
#mapControls {
  width: 250px;
  height: 625px; // smaller than actual map, so we don't cover Google logo
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  .no-bg {
    background: none;
  }

  .section-header-button {
    position: relative;

    .indicator {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  header {
    background: transparent;

    .btn-block {
      position: relative;
      z-index: 2;
      outline: none;
      box-shadow: none;
    }

    .show-hide-all {
      padding: 5px 10px;
      font-size: 0.7em;
      background: rgba(0, 0, 0, 0.7);
      color: #ffffff;
      margin-top: -3px;
      border-radius: 0 0 0.25rem 0.25rem;

      .custom-control-inline {
        margin: 0;

        .custom-control-label {
          padding-top: 5px;
        }
      }
    }
  }

  div[role="tabpanel"] {
    max-height: 400px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-right: 15px;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    .list-group {
      .list-group-item {
        padding: 0 34px 0 0;
        font-size: 0.8em;
        position: relative;

        &.active {
          background-color: darken(#ff823e, 15%);
          border-color: darken(#ff823e, 15%);
        }

        .checkbox-container {
          position: absolute;
          right: 0;
          top: 9px;

          .custom-control-inline {
            margin: 0;
          }
        }

        .marker-name-container {
          margin: 0;
          padding: 0.75rem 0 0.75rem 10px;
        }
      }
    }
  }
}
</style>
