<template>
  <div>
    <b-alert class="mb-4" variant="danger" :show="occupancyDiscrepancy">
      <h4>WARNING</h4>
      <p>
        It looks as if there is a discrepancy between the number of booked passengers ({{numberOfOccupantsInitiallyBooked}}), and the maximum permitted occupancy ({{maxOccupancy}}) for this property.<br/>
        Please <strong>call us</strong> on <strong>{{ $t('global.vcPhoneNumber') }}</strong> so we may investigate and rectify the issue.
      </p>
    </b-alert>

    <div class="inner-container" :class="{'pl-3': !editMode, 'pr-3': !editMode}">
      <b-row v-if="!editMode" style="border-bottom: 2px solid #dee2e6; border-top: 1px solid #dee2e6; color: #495057; font-family: Roboto, sans-serif; font-weight: 700; font-size: 16px; line-height: 24px; padding-top: 0.3rem; padding-bottom: 0.3rem;">
        <b-col md="1"><strong>#</strong></b-col>
        <b-col><strong>Forename</strong></b-col>
        <b-col><strong>Surname</strong></b-col>
        <b-col md="3" class="hidden"><strong>Passenger Type</strong></b-col>
        <b-col><strong>Date of Birth</strong></b-col>
        <b-col><strong>Will they be driving?</strong></b-col>
        <b-col md="1"><strong>Lead</strong></b-col>
      </b-row>

      <b-row class="additional-passenger" v-if="showLeadPassengerRow()">
        <b-col md="1">
          <b-form-group
            :label-sr-only="!editMode"
            :id="'customerNumberGroup_lead'"
            :label-for="'customerNumber_lead'">
            <b-form-input plaintext id="customerNumber_lead" value="1."></b-form-input>
            <template #label>&nbsp;</template>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label-sr-only="!editMode"
            :id="'customerForenameGroup_lead'"
            label="Forename"
            :label-for="'customerForename_lead'">
            <b-form-input plaintext :id="'customerForename_lead'" v-model="leadPassenger.forename"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label-sr-only="!editMode"
            :id="'customerSurnameGroup_lead'"
            label="Surname"
            :label-for="'customerSurname_lead'">
            <b-form-input plaintext :id="'customerSurname_lead'" v-model="leadPassenger.surname"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label-sr-only="!editMode"
            id="customerDOBGroup_lead"
            label="Date of Birth"
            label-for="customerDOBGroup_lead">
            <b-form-input
              id="customerDOB_lead"
              v-model="leadPassenger.dob"
              type="date"
              plaintext
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label-sr-only="!editMode"
            id="customerDrivingGroup_lead"
            label="Will they be driving?"
            label-for="customerDrivingGroup_lead">
            <b-form-checkbox id="customerDrivingGroup_lead" :checked="leadPassenger.isDriving == 1 ? true : false" disabled></b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col v-if="editMode">
          <b-form-group
            :id="'passengerButtonGroup_lead'"
            :label-for="'passengerButton_lead'">
            <b-button style="background: transparent;" tabindex="-1" variant="outline-success" disabled>Lead <small>(Edit Above)</small></b-button>
            <template #label>&nbsp;</template>
          </b-form-group>
        </b-col>

        <b-col md="1" v-if="!editMode" class="text-center">
          <b-form-group
            :label-sr-only="true"
            :id="'passengerStatusGroup_lead'"
            :label-for="'passengerStatus_lead'"
            >
            <b-form-checkbox id="passengerStatus_lead" :checked="true" disabled></b-form-checkbox>
            <template #label>&nbsp;</template>
          </b-form-group>
        </b-col>
      </b-row>

      <template v-if="additionalPassengers && showLeadPassengerRow()">
        <template v-for="(fieldSet, index) in additionalPassengers">
          <b-row :key="index" class="additional-passenger" v-if="(!editMode && isRowPopulated(index)) || editMode">
            <b-col md="1">
              <b-form-group
                :label-sr-only="!editMode"
                :id="'passengerTypeGroup_' + index"
                :label-for="'passengerType_' + index">
                <b-form-input plaintext :id="'passengerType_' + index" :value="(showLeadPassengerRow() ? index + 2 : index + 1) + '.'"></b-form-input>
                <template #label>&nbsp;</template>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                :label-sr-only="!editMode"
                :id="'customerForenameGroup_' + index"
                label="Forename"
                :label-for="'customerForename_' + index">
                <b-form-input :plaintext="!editMode" :id="'customerForename_' + index" v-model="additionalPassengers[index].forename"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                :label-sr-only="!editMode"
                :id="'customerSurnameGroup_' + index"
                label="Surname"
                :label-for="'customerSurname_' + index">
                <b-form-input :plaintext="!editMode" :id="'customerSurname_' + index" v-model="additionalPassengers[index].surname"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                :label-sr-only="!editMode"
                :id="'customerDOBGroup_' + index"
                label="Date Of Birth"
                :label-for="'customerDOB_' + index">
                <b-form-input
                  :plaintext="!editMode"
                  :id="'customerDOB_' + index"
                  v-model="additionalPassengers[index].dob"
                  @blur="(e) => updateDOB(e, index)"
                  type="date"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                :label-sr-only="!editMode"
                :id="'customerDrivingGroup_' + index"
                label="Will they be driving?"
                :label-for="'customerDriving_' + index">
                <b-form-checkbox
                  :id="'customerDriving_' + index"
                  v-model="additionalPassengers[index].isDriving"
                  value="1"
                  unchecked-value="0"
                  :disabled="!editMode"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col v-if="editMode">
              <b-form-group
                :id="'passengerButtonGroup_' + index"
                :label-for="'passengerButton_' + index">
                <template #label>&nbsp;</template>
                <b-button
                  tabindex="-1"
                  v-if="((index < (additionalPassengers.length - 1)) || ((numberOfOccupants >= maxOccupancy) && (additionalPassengers[index].passengerType !== 'Infant' && additionalPassengers[index].passengerType !== ''))) && editMode"
                  variant="danger"
                  @click="deletePassengerRow(index)">
                  Delete
                </b-button>
              </b-form-group>
            </b-col>

            <b-col md="1" v-if="!editMode" class="text-center">
              <b-form-group
                :label-sr-only="true"
                :id="'passengerStatusGroup_' + index"
                label="&nbsp;"
                :label-for="'passengerStatus_' + index">

                <b-form-checkbox :id="'passengerStatus_' + index" :checked="false" disabled></b-form-checkbox>

              </b-form-group>
            </b-col>
          </b-row>
        </template>
      </template>
    </div>

    <b-alert variant="warning" :show="!showLeadPassengerRow()">
      You can not add additional passengers, until you have completed the lead passenger information.
    </b-alert>

    <b-alert variant="warning" :show="editMode && (numberOfOccupants >= maxOccupancy)">
      <strong>The maximum occupancy for this property (a combination of {{ maxOccupancy }} adults or children) has been reached.</strong><br/>
        You may continue to add infants, if required.
    </b-alert>

    <b-modal id="passengerDeleteWarning" title="Are you sure?" ref="additionalPassengerWarning" centered header-bg-variant="danger"
             header-text-variant="light" ok-title="Delete Row" ok-variant="danger" @ok="removePassengerArrayItem(modalWarningData.key)">
      <p class="my-4">
        You are about to delete an additional passenger, with the following information:
      </p>
      <div v-if="modalWarningData.obj.forename && modalWarningData.obj.surname">
        <div v-if="(modalWarningData.obj.forename.trim() !== '')"><strong>Forename:</strong> {{ modalWarningData.obj.forename }}</div>
        <div v-if="(modalWarningData.obj.surname.trim() !== '')"><strong>Surname:</strong> {{ modalWarningData.obj.surname }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'passenger-list',

  props: {
    leadPassenger: {
      type: Object,
      required: false
    },

    additionalPassengers: {
      type: Array,
      required: false
    },

    maxOccupancy: {
      type: [Number, String],
      required: false
    },

    arrivalDate: {
      type: String,
      required: false
    },

    editMode: {
      type: Boolean,
      required: false
    }
  },

  watch: {
    additionalPassengers: {
      handler (newVal, oldVal) {
        const newLastKey = (newVal.length - 1)
        if (newLastKey > -1 && this.isRowPopulated(newLastKey)) { // if any of the new values are populated
          this.addPassenger()
        }
      },
      deep: true
    }
  },

  computed: {
    numberOfOccupants () {
      let passengerCount = 1

      for (let i = 0; i < this.additionalPassengers.length; i++) {
        if ((this.additionalPassengers[i].forename !== '' || this.additionalPassengers[i].surname !== '') && this.additionalPassengers[i].passengerType !== '') {
          if (this.additionalPassengers[i].passengerType !== 'Infant') {
            passengerCount++
          }
        }
      }

      return passengerCount
    }
  },

  mounted () {
    this.setupComponent()
  },

  methods: {
    setupComponent () {
      this.$nextTick(() => {
        let addAdditionalPassenger = true
        if (this.additionalPassengers.length >= 1) { // this checks to see if passengers have been passed in as a property
          const lastRowIsEmpty = !this.isRowPopulated((this.additionalPassengers.length - 1))
          if (lastRowIsEmpty) {
            addAdditionalPassenger = false
          }

          // Update Passenger Type, this is used to calculate the number of adults, children and infants
          for (const passenger of this.additionalPassengers) {
            passenger.passengerType = this.calculatePassengerType(passenger.dob)
          }
        }
        if (addAdditionalPassenger) {
          this.addPassenger()
        }
        if (this.numberOfOccupants > this.maxOccupancy) {
          this.occupancyDiscrepancy = true
          this.numberOfOccupantsInitiallyBooked = JSON.parse(JSON.stringify(this.numberOfOccupants)) // need to copy the value and not have it by reference
        }
      })
    },
    calculatePassengerType (dob) {
      // Convert to moment object
      const date = this.$moment(dob, 'YYYY-MM-DD')
      const arrivalDate = this.$moment(this.arrivalDate, 'YYYY-MM-DD')

      // Now work out the age in years from the arrival date
      const age = arrivalDate.diff(date, 'years', true)

      let passengerType = 'Adult'

      if (age >= 2 && age <= 16) {
        passengerType = 'Child'
      } else if (age < 2) {
        passengerType = 'Infant'
      }

      return passengerType
    },
    updateDOB (e, index) {
      const value = e.target.value
      this.additionalPassengers[index].passengerType = this.calculatePassengerType(value)
      this.additionalPassengers[index].dob = value
    },
    deletePassengerRow (index) {
      if (this.isRowPopulated(index)) {
        this.modalWarningData.obj = this.additionalPassengers[index]
        this.modalWarningData.key = index
        this.$refs.additionalPassengerWarning.show()
      } else {
        this.removePassengerArrayItem(index)
      }
    },

    showLeadPassengerRow () {
      let returnVal = false

      if (this.leadPassenger) {
        if (this.leadPassenger.hasOwnProperty('forename') &&
        this.leadPassenger.hasOwnProperty('surname') &&
        this.leadPassenger.hasOwnProperty('isDriving')) {
          if (this.isLeadPassengerComplete()) {
            returnVal = true
          }
        }
      }

      return returnVal
    },

    isLeadPassengerComplete () {
      if ((this.leadPassenger.forename !== '') &&
        (this.leadPassenger.surname !== '') &&
        (this.leadPassenger.dob !== '' && this.leadPassenger.dob !== undefined) &&
        (this.leadPassenger.isDriving !== undefined)) {
        return true
      } else {
        return false
      }
    },

    isRowPopulated (index) {
      return this.additionalPassengers[index].forename.trim().length > 0 &&
        this.additionalPassengers[index].surname.trim().length > 0
    },

    addPassenger () {
      this.additionalPassengers.push({ forename: '', surname: '' })
    },

    removePassengerArrayItem (index) {
      this.additionalPassengers.splice(index, 1)
    }
  },

  data () {
    return {
      occupancyDiscrepancy: false,
      numberOfOccupantsInitiallyBooked: 0,

      modalWarningData: {
        obj: '',
        key: ''
      }
    }
  }
}
</script>
