import {
  UPDATE_USER_ROLE,
  SET_USER_ROLE
} from '@/store/actions/usernew'

const state = {
  userRole: null
}

const getters = {
  userRole: (state) => state.userRole
}

const actions = {
  [UPDATE_USER_ROLE]: ({ commit }, userRole) => {
    commit(SET_USER_ROLE, userRole)
  }
}

const mutations = {
  [SET_USER_ROLE]: (state, userRole) => {
    state.userRole = userRole
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
