<template>
  <b-container id="aboutFlorida" v-if="!loading">
      <masthead :image-url="aboutFloridaPage.mainImage" :title="aboutFloridaPage.mainHeading"></masthead>
      <article class="page-content">
        <h2 v-if="aboutFloridaPage.openingHeading">{{ aboutFloridaPage.openingHeading }}</h2>
        <p class="intro-text" v-if="aboutFloridaPage.openingParagraph">{{ aboutFloridaPage.openingParagraph }}</p>

        <b-row>
          <b-col v-for="(item, key) in aboutFloridaPage.items" :key="key" sm="6" md="4" lg="3">
            <b-card tag="article" class="mb-4">

              <b-link :is="item.link.external?'a':'router-link'" v-if="item.link" :to="item.link.url" :href="item.link.url" class="image-link" :target="(item.link.external === true) ? '_blank' : ''">
                <b-card-img :src="item.img" :alt="item.name" top fluid></b-card-img>
              </b-link>

              <b-card-img v-if="!item.link" :src="item.img" :alt="item.name" top fluid></b-card-img>

              <h4>{{ item.name }}</h4>
              <p class="card-text">{{ item.description }}</p>

              <b-link :is="item.link.external?'a':'router-link'" v-if="item.link" :to="item.link.url" :href="item.link.url" :target="(item.link.external === true) ? '_blank' : ''">{{ item.link.name }}</b-link>
            </b-card>
          </b-col>
        </b-row>
      </article>
  </b-container>

  <div class="loader-outer" v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

const Masthead = () => ({
  component: import('@/components/widgets/masthead'),
  loading: loader
})

export default {
  name: 'AboutFlorida',
  components: {
    Masthead,
    loader
  },

  mounted () {
    this.getPageContent()
  },

  watch: {
    '$route.params': {
      handler (newVal) {
        this.getPageContent()
      },
      deep: true
    }
  },

  methods: {
    getPageContent () {
      const apiUrl = this.$route.fullPath
      this.loading = true
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        this.aboutFloridaPage = response.data.response
        // fix item links from slugs to ids if theme-parks section
        switch (this.$route.params.sectionName) {
          case 'theme-parks':
            let thisItem
            for (const i in this.aboutFloridaPage.items) {
              thisItem = this.aboutFloridaPage.items[i]
              this.aboutFloridaPage.items[i].link.url = '/about-florida/theme-parks/' + thisItem.about_id // bit hacky and hardcoded - the api returns it as the slug - todo perhaps the api should return this url as the id?
            }
            break
        }
        let title = 'About Florida'
        if (this.$route.params.sectionName && this.aboutFloridaPage.mainHeading) {
          title += ' / ' + this.aboutFloridaPage.mainHeading
        }
        this.$emit('setPageMetaInfo', { title })
        this.loading = false
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },
  data () {
    return {
      loading: true,
      aboutFloridaPage: {}
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/about-florida/AboutFlorida.scss';
</style>
