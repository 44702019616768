<template>
  <div>
    <b-row>
      <b-col :lg="this.telCols">
        <b-form-group v-if="!fieldsToExclude.includes('clientTelephone')"
          label="Telephone number"
          label-for="clientTelephone"
          label-class="required">
          <b-form-input
            autocomplete="off"
            id="clientTelephone"
            name="clientTelephone"
            @input="updateReturnValues"
            @change="updateReturnValues"
            data-vv-as="telephone number"
            v-validate="{required: true, min:10, max:30}"
            :state="validationState('clientTelephone')"
            maxlength="30"
            type="text"
            v-model="contactInfo.clientTelephone"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('clientTelephone') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col :lg="this.telCols">
        <b-form-group v-if="!fieldsToExclude.includes('clientMobile')"
          label-for="clientMobile"
          :label-class="this.mobileRequired?'required':''">
          <template #label>
            Mobile number<small v-if="!mobileRequired"> (Leave blank if you only have the one phone number)</small>
          </template>
          <b-form-input
            autocomplete="off"
            id="clientMobile"
            name="clientMobile"
            @input="updateReturnValues"
            @change="updateReturnValues"
            data-vv-as="mobile number"
            v-validate="{required: this.mobileRequired, min:10, max:30}"
            :state="validationState('clientMobile')"
            maxlength="30"
            type="text"
            v-model="contactInfo.clientMobile"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('clientMobile') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      </b-row>
      <b-row>
        <b-col>
        <b-form-group v-if="!fieldsToExclude.includes('clientEmail')"
          label="Email"
          label-for="clientEmail"
          label-class="required">
          <b-form-input
            autocomplete="off"
            id="clientEmail"
            name="clientEmail"
            @input="updateReturnValues"
            @change="updateReturnValues"
            data-vv-as="email"
            v-validate="{required:true, email: true}"
            :state="validationState('clientEmail')"
            type="email"
            v-model="contactInfo.clientEmail"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('clientEmail') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'contact-details',

  props: {
    formData: {
      type: Object,
      required: false
    },
    excludeFields: {
      type: Array,
      required: false
    },
    telCols: {
      type: Number,
      default: 12
    },
    mobileRequired: {
      type: Boolean,
      default: true
    }
  },

  beforeMount () {
    this.fieldsToExclude = []
    if (this.excludeFields) {
      for (const fieldKey in this.excludeFields) {
        const fieldName = this.excludeFields[fieldKey]
        this.$delete(this.contactInfo, fieldName)
        this.$delete(this.output.data, fieldName)
        this.fieldsToExclude.push(fieldName)
      }
    }
  },

  mounted () {
    if (this.formData) {
      for (const key in this.formData) {
        this.contactInfo[key] = this.formData[key]
      }
      this.$validator.validate()
    }
  },

  methods: {
    validator () {
      return this.$validator
    },
    updateReturnValues () {
      for (const key in this.contactInfo) {
        if (this.contactInfo.hasOwnProperty(key)) {
          this.output.data[key] = this.contactInfo[key]
        }
      }
      this.$emit('updated', this.output)
    },

    validationState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    }
  },

  data () {
    return {
      fieldsToExclude: [],
      contactInfo: {
        clientTelephone: '',
        clientMobile: '',
        clientEmail: ''
      },

      output: {
        data: {
          clientTelephone: '',
          clientMobile: '',
          clientEmail: ''
        }
      }
    }
  }
}
</script>
