<template>
  <b-modal
    id="payment-modal"
    title="Payment"
    size="lg"
    centered
    no-close-on-backdrop
    hide-footer
    >
    <iframe
      :src="url()"
      height="600px"
      width="100%"
      frameborder="0"
    >
    </iframe>
  </b-modal>
</template>

<script>
export default {
  name: 'PaymentModal',
  props: {
    bookingReference: {
      type: String,
      required: true
    },
    amount: {
      type: [Number, String], // before the number is filled out its an empty string - fails number validation at this point - but its validated as a number before this is invoked to open anyway.
      required: true
    },
    paymentToken: {
      type: String,
      required: true
    },
    detailsSelected: {
      type: String,
      required: false // this is required in the form so it cannot be passed without a value - in the form its null but we canot accept null as a property type so set required to false here instead.
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_AXCESS_PAYMENT_IFRAME_BASE_URL,
      source: 'VC'
    }
  },
  methods: {
    url () {
      return `${this.baseUrl}/${this.bookingReference}/${this.amount}/${this.paymentToken}/${this.source}?details=${this.detailsSelected}`
    }
  }
}
</script>
