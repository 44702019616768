<template>
  <div class="container" id="villaInfo">
    <div v-if="loaded">
      <pdf-generator ref="pdfGen" :type="pdfGen.type" :typeId="pdfGen.typeId" :recipientMessage="pdfGen.recipientMessage"></pdf-generator>
      <div class="villa-overview">
        <b-row>
          <b-col md="6" lg="8">
            <h1>{{ 'pages.villaInfo.overview.propertyRef' | translate }}: {{ selectedProperty.villaCode }}</h1>
            <div class="short-description-area">
              <strong class="sub-title" v-if="selectedProperty.community.fullName">{{ selectedProperty.community.fullName }},
                <small v-if="selectedProperty.bedrooms">
                  {{ selectedProperty.bedrooms }}
                  {{ 'pages.villaInfo.overview.bedrooms' | translate }}
                  {{ selectedProperty.propertyTypeName }}
                </small>
              </strong>
              <div v-if="selectedProperty.highlight" class="property-highlight-area">
                <b-img :src="selectedProperty.highlight.logoUrl" :alt="selectedProperty.highlight.name"></b-img>
                <p><strong>{{selectedProperty.highlight.name}}: </strong>{{selectedProperty.highlight.highlightText}}</p>
              </div>
              <p v-if="selectedProperty.descriptions.short">{{ selectedProperty.descriptions.short }}</p>
              <ul class="overview">
                <li v-if="selectedProperty.bedrooms">
                  <b-img src="@/assets/images/icons/bedrooms.png"></b-img>
                  {{ 'pages.villaInfo.overview.quickStats.bedrooms' | translate }} <strong>{{ selectedProperty.bedrooms }}</strong></li>
                <li v-if="selectedProperty.counts.maxOccupancy">
                  <b-img src="@/assets/images/icons/sleeps.png"></b-img>
                  {{ 'pages.villaInfo.overview.quickStats.sleeps' | translate }} <strong>{{ selectedProperty.counts.maxOccupancy }}</strong></li>
                <li v-if="selectedProperty.counts.bathrooms">
                  <b-img src="@/assets/images/icons/bathrooms.png"></b-img>
                  {{ 'pages.villaInfo.overview.quickStats.bathrooms' | translate }} <strong>{{ displayBathrooms(selectedProperty.counts.bathrooms) }}</strong></li>
              </ul>
            </div>
          </b-col>
          <b-col md="6" lg="4">
            <aside class="season-info" v-if="pricing.priceFrom">
              <div class="inner">
                <h2>Price From</h2>
                <div class="seasonal-pricing-from">
                  {{pricing.priceFrom | currency}} <span>PER NIGHT</span>
                </div>
              </div>

              <div class="buttons">
                <b-row>
                  <b-col cols="12" class="text-left text-md-center text-xl-left">
                    <b-btn variant="primary" class="check-availability-button mb-md-2 w-100" @click="pdfButtonClicked('property')">
                      {{ 'widgets.sendPdf.buttonNames.property' | translate }}
                    </b-btn>
                  </b-col>

                  <b-col cols="12" class="text-right text-md-center text-xl-right">
                    <b-btn variant="primary" class="check-availability-button w-100" v-scroll-to="'#calendarContainer'">
                      {{ 'pages.villaInfo.pricingInfo.checkAvailability' | translate }}
                    </b-btn>
                  </b-col>
                </b-row>
              </div>
            </aside>

            <price-breakdown v-if="pricing.total" :items="priceTotalBreakdown" @pdfButtonClicked="pdfButtonClicked" v-bind:showCommission="$store.getters.getAgentView"></price-breakdown>
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col>
          <dv-carousel :images="selectedProperty.images"></dv-carousel>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <div class="general-info" v-if="(selectedProperty.counts) || (selectedProperty.icons)">
            <div class="info-item" v-if="selectedProperty.icons">
              <h3>{{ 'pages.villaInfo.generalInfo.facillities.title' | translate }}</h3>
              <ul class="icon-list">
                <li v-for="(item, key) in selectedProperty.icons">
                  <b-img :src="require('@/assets/images/icons/' + item.icon + '.png')"></b-img>
                  <small>{{ item.name }}</small>
                </li>
              </ul>
            </div>

            <div class="info-item" v-if="selectedProperty.counts">
              <h3>{{ 'pages.villaInfo.generalInfo.accommodation.title' | translate }}</h3>
              <ul class="icon-list">
                <li v-if="selectedProperty.counts.king > 0">
                  <b-img src="@/assets/images/icons/beds.png"></b-img>
                  <small><strong>{{ selectedProperty.counts.king }} x</strong> {{ 'pages.villaInfo.generalInfo.accommodation.kingBeds' | translate }}</small>
                </li>

                <li v-if="selectedProperty.counts.queen > 0">
                  <b-img src="@/assets/images/icons/beds.png"></b-img>
                  <small><strong>{{ selectedProperty.counts.queen }} x</strong> {{ 'pages.villaInfo.generalInfo.accommodation.queenBeds' | translate }}</small>
                </li>

                <li v-if="selectedProperty.counts.double_full > 0">
                  <b-img src="@/assets/images/icons/beds.png"></b-img>
                  <small><strong>{{ selectedProperty.counts.double_full }} x</strong> {{ 'pages.villaInfo.generalInfo.accommodation.doubleBeds' | translate }}</small>
                </li>

                <li v-if="selectedProperty.counts.single > 0">
                  <b-img src="@/assets/images/icons/single-bed.png"></b-img>
                  <small><strong>{{ selectedProperty.counts.single }} x</strong> {{ 'pages.villaInfo.generalInfo.accommodation.singleBeds' | translate }}</small>
                </li>

                <li v-if="selectedProperty.counts.ensuite > 0">
                  <b-img src="@/assets/images/icons/en-suite.png"></b-img>
                  <small><strong>{{ selectedProperty.counts.ensuite }} x</strong> {{ 'pages.villaInfo.generalInfo.accommodation.enSuite' | translate }}</small>
                </li>
              </ul>
            </div>

            <div class="info-item" v-if="selectedProperty.counts.sqft">
              <h3>{{ 'pages.villaInfo.generalInfo.squareFootage.title' | translate }}</h3>
              {{ selectedProperty.counts.sqft }}
              <small>{{ 'pages.villaInfo.generalInfo.squareFootage.unit' | translate }}</small>
            </div>

            <div class="info-item" v-if="selectedProperty.counts.maxOccupancy">
              <h3>{{ 'pages.villaInfo.generalInfo.occupancy.title' | translate }}</h3>
              {{ selectedProperty.counts.maxOccupancy }}
              <small>({{ 'pages.villaInfo.generalInfo.occupancy.excluding' | translate }})</small>
            </div>

            <div class="info-item" v-if="selectedProperty.counts.floors">
              <h3>{{ 'pages.villaInfo.generalInfo.floors.title' | translate }}</h3>
              {{ selectedProperty.counts.floors }}
            </div>

            <div class="info-item" v-if="selectedProperty.bedBath.bedroom">
              <h3>{{ 'pages.villaInfo.generalInfo.bedrooms.title' | translate }}</h3>
              <ol class="bedrooms-bathrooms">
                <li v-for="(bedroom, key) in selectedProperty.bedBath.bedroom">
                  {{ bedroom }}
                </li>
              </ol>
            </div>

            <div class="info-item" v-if="selectedProperty.bedBath.bathroom || selectedProperty.bedBath.ensuite">
              <h3>{{ 'pages.villaInfo.generalInfo.additionalBathrooms.title' | translate }}</h3>
              <ol class="bedrooms-bathrooms">
                <li v-if="selectedProperty.bedBath.bathroom"
                    v-for="(bathroom, key) in selectedProperty.bedBath.bathroom">
                  {{ bathroom }}
                </li>
                <li v-if="selectedProperty.bedBath.ensuite" v-for="(bathroom, key) in selectedProperty.bedBath.ensuite">
                  {{ bathroom }}
                </li>
              </ol>
            </div>
          </div>

          <b-card class="mb-3" bg-variant="secondary" text-variant="white" v-if="selectedProperty.testimonials.length > 0">
            <h3 class="pt-3 pl-3 pr-3">Reviews & Comments</h3>
            <div class="pl-3 pr-3" v-show="(index < 3) || testimonialsVisible === true" v-for="(testimonial, index) in selectedProperty.testimonials" :key="'testimonial_' + index">
              <p class="card-text mb-2">
                  &quot;{{ testimonial.testimonial }}&quot;
                  <strong style="display: block;">{{ testimonial.displayName }}</strong>
              </p>

              <p class="card-text" v-if="testimonial.response !== ''">
                <strong>Response from Villa Choices:</strong> {{ testimonial.response }}
              </p>

              <hr v-if="index !== (selectedProperty.testimonials.length - 1)" />
            </div>

            <div class="text-center p-3" v-if="(selectedProperty.testimonials.length > 3)" v-show="(selectedProperty.testimonials.length > 3) && testimonialsVisible === false">
              <b-button variant="secondary" @click="makeTestimonialsVisible()">
                Read more
              </b-button>
            </div>
          </b-card>
        </b-col>

        <b-col md="8">
          <article class="villa-description" v-if="selectedProperty.descriptions">
            <h2>{{ 'pages.villaInfo.propertyDetails.title' | translate }}</h2>
            <p v-if="selectedProperty.descriptions.intro">
              <vue-markdown>{{ selectedProperty.descriptions.intro }}</vue-markdown>
            </p>

            <p v-if="selectedProperty.descriptions.living">
              <span class="highlight">{{ 'pages.villaInfo.propertyDetails.accomodation.title' | translate }}: </span> <vue-markdown>{{ selectedProperty.descriptions.living }}</vue-markdown>
            </p>

            <p v-if="selectedProperty.descriptions.bedroom">
              <span class="highlight">{{ 'pages.villaInfo.propertyDetails.bedrooms.title' | translate }}: </span> <vue-markdown>{{ selectedProperty.descriptions.bedroom }}</vue-markdown>
            </p>

            <p v-if="selectedProperty.descriptions.entertainment">
              <span class="highlight">{{ 'pages.villaInfo.propertyDetails.entertainment.title' | translate }}: </span> <vue-markdown>{{ selectedProperty.descriptions.entertainment }}</vue-markdown>
            </p>

            <p v-if="selectedProperty.descriptions.outdoor">
              <span class="highlight">{{ 'pages.villaInfo.propertyDetails.outdoors.title' | translate }}: </span> <vue-markdown>{{ selectedProperty.descriptions.outdoor }}</vue-markdown>
            </p>

            <p v-if="selectedProperty.descriptions.disabledMods && showAccessibilityFeatures()">
              <span class="highlight">{{ 'pages.villaInfo.propertyDetails.disabledMods.title' | translate }}: </span> <vue-markdown>{{ selectedProperty.descriptions.disabledMods }}</vue-markdown>
            </p>

            <p v-if="selectedProperty.descriptions.poolSafety">
              <span class="highlight">{{ 'pages.villaInfo.propertyDetails.poolSafety.title' | translate }}: </span> {{ selectedProperty.descriptions.poolSafety }}
            </p>
          </article>

          <article class="community-description" v-if="selectedProperty.community">
            <h2>{{ selectedProperty.community.comName }}</h2>
            <p>
              {{ selectedProperty.community.shortDesc }}
            </p>

            <p>
              <b-link :to="'/community/' + selectedProperty.community.comId">{{ $t('pages.villaInfo.propertyDetails.communityLink', {communityName: selectedProperty.community.comName}) }}</b-link>
            </p>
          </article>

          <b-img v-if="selectedProperty.community.mapUrl" class="communityMap" blank-color="#bbb" :src="selectedProperty.community.mapUrl" fluid
                 :alt="selectedProperty.community.comName"/>

          <article class="map-driving">
            <b-row>
              <b-col>
                <h3>Map Key</h3>
                <ol class="key">
                  <li>Epcot</li>
                  <li>Magic Kingdom</li>
                  <li>Universal Studios</li>
                  <li>Sea World</li>
                  <li>Discovery Cove</li>
                </ol>
              </b-col>
              <b-col cols="auto">
                <h3>Driving Times</h3>
                <ul class="driving">
                  <li v-for="drivingTime in selectedProperty.community.distances" :key="drivingTime.placeId">
                    <strong>{{ drivingTime.name }}</strong> - {{ drivingTime.formatted }}
                  </li>
                </ul>
              </b-col>
            </b-row>
          </article>
        </b-col>
      </b-row>

      <div class="calendar-outer" id="calendarContainer">
        <dv-calendar
          v-if="calendarDatesData"
          :week-starts="calendarOptions.weekStarts"
          :calendar-start-month="calendarOptions.calendarStartMonth"
          :calendar-start-year="calendarOptions.calendarStartYear"
          :months-to-display="calendarOptions.monthsToDisplay"
          :calendarDates="calendarDatesData"
          @calendarDayClicked="calendarDayClicked" />
      </div>

      <div id="villaPagination" class="villa-pagination-container" v-if="pagination">
        <b-row>
          <b-col sm="8" md="8" lg="6" xl="4">
            <div class="holder">
              <div class="inner-container">
                <div class="absolute-container">
                  <b-row>
                    <b-col>
                      <b-link :to="paginationSearchResultPreviousUrl" class="previous" v-if="pagination.searchResultPrevious"></b-link>
                    </b-col>

                    <b-col cols="8">
                      <div class="pagination-counter">
                        <div class="counter-inner">
                          Villa
                          <span class="current" id="currentPagination">{{pagination.current}}</span>
                          <span class="of">of</span>
                          <span class="total" id="totalPagination">{{pagination.total}}</span>
                        </div>
                      </div>
                    </b-col>

                    <b-col>
                      <b-link :to="paginationSearchResultNextUrl" class="next" v-if="pagination.searchResultNext"></b-link>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="loader-outer">
      <loading v-if="!loaded" width="100px" height="100px" />
    </div>

    <b-modal size="lg" ref="numberOfNightsChooser" :title="'Check in ' + numberOfNightsForm.chosenCheckInDate" centered hide-footer no-close-on-backdrop>
      <div v-if="!numberOfNightsForm.loading">
        <div v-if="numberOfNightsForm.hasDisabledDates">
          <p>Dates marked with an * indicate that a booking cannot start on this date due to minimum rental periods.</p>
        </div>
      <b-form v-if="numberOfNightsForm.datesAvailable !== false" @submit="nightsSelected">
        <b-form-group id="numberOfNightsGroup"
                      label="Number of nights"
                      label-for="numberOfNights">

              <v-select id="numberOfNights" v-model="numberOfNightsForm.nights" :options="numberOfNightsForm.datesAvailable" :selectable="option => !option.disabled" placeholder="Select number of nights" >
              </v-select>
        </b-form-group>
        <div class="mt-4 text-right">
          <b-button type="submit" variant="primary">{{ 'pages.villaInfo.numberOfNightsModal.submitButton' | translate }}</b-button>
        </div>

      </b-form>
      <p v-else>
        {{ 'pages.villaInfo.numberOfNightsModal.minNightsErrorMessage' | translate }}
        <!-- The property is not available from that date -->
      </p>
      </div>
      <div v-else class="loader-outer">
        <loading width="100px" height="100px" />
      </div>
    </b-modal>

  </div>
</template>

<script>
import loader from '@/components/widgets/loader'
import pdfGenerator from '@/components/widgets/pdf-generator'
import DVCarousel from '@/components/widgets/carousel'
import qs from 'qs'
import moment from 'moment'
import priceBreakdown from '@/components/widgets/price-breakdown'
import dvCalendar from '@/components/widgets/dv-calendar'

export default {
  name: 'VillaInfo',

  components: {
    loading: loader,
    'dv-carousel': DVCarousel,
    'price-breakdown': priceBreakdown,
    'dv-calendar': dvCalendar,
    'pdf-generator': pdfGenerator
  },

  mounted () {
    this.getPropertyInfo()
  },

  watch: {
    '$route.query': {
      handler: function (neVal) {
        this.getPropertyInfo()
      },
      deep: true
    }
  },

  methods: {
    makeTestimonialsVisible () {
      this.testimonialsVisible = !this.testimonialsVisible
    },

    pdfButtonClicked (type) {
      switch (type) {
        case 'property':
          this.pdfGen.type = type// 'Send a PDF of this property'
          this.pdfGen.typeId = this.$route.params.id
          const pdfMessage = this.$t('widgets.sendPdf.recipientMessages.property', {
            bedrooms: this.selectedProperty.bedrooms,
            propertyTypeName: this.selectedProperty.propertyTypeName,
            propertyCode: this.selectedProperty.villaCode,
            communityFullName: this.selectedProperty.community.fullName
          }
          )
          this.pdfGen.recipientMessage = pdfMessage
          this.$refs.pdfGen.init()
          break
      }
    },

    nightsSelected (evt) {
      evt.preventDefault()

      if (this.numberOfNightsForm.nights.value) {
        this.$refs.numberOfNightsChooser.hide()
        const path = this.$route.path
        const qryObj = {}

        for (const queryItems in this.$route.query) {
          if (this.$route.query.hasOwnProperty(queryItems)) {
            qryObj[queryItems] = this.$route.query[queryItems]
          }
        }

        qryObj['criteria[arrival]'] = this.$moment(this.numberOfNightsForm.chosenCheckInDate, 'dddd, Do MMMM YYYY').format('YYYY-MM-DD')
        qryObj['criteria[nights]'] = this.numberOfNightsForm.nights.value
        const qryStr = qs.stringify(qryObj, { arrayFormat: 'brackets', encode: false })
        this.$router.push(path + '?' + qryStr)
      }
    },

    showNightsModal () {
      this.$refs.numberOfNightsChooser.show()
    },

    calendarDayClicked (day) {
      // console.log(day)
      const dayClicked = moment(day, 'YYYY-MM-DD')
      this.numberOfNightsForm.chosenCheckInDate = dayClicked.format('dddd, Do MMMM YYYY')
      this.numberOfNightsForm.loading = true
      this.showNightsModal()
      const apiUrl = '/property-nights-options/' + this.$route.params.id + '/' + day

      this.numberOfNightsForm.hasDisabledDates = false

      this.$apiCaller({ route: apiUrl + '?axios22=true', method: 'GET', data: '' }).then((response) => {
        // console.dir(response.data.response)
        if (response.data.response.hasOwnProperty('cannotBeBookedFromThisNight')) {
          this.numberOfNightsForm.datesAvailable = false
        } else {
          this.numberOfNightsForm.datesAvailable = []
          this.numberOfNightsForm.nights = 0 // reset the nights so we need to enter a value again - it may have already been chosen and closed.
          const nightOptions = response.data.response.nightOptions
          let nightOption; let i
          for (i in nightOptions) {
            nightOption = nightOptions[i]
            if (nightOption.hasOwnProperty('disabled') && nightOption.disabled) {
              this.numberOfNightsForm.hasDisabledDates = true // set that we have a disabled date within the night options - we can then display the blurb to say about minimum booking periods
            }
            const obj = {
              value: nightOption.nights,
              label: nightOption.name,
              disabled: (nightOption.hasOwnProperty('disabled') && nightOption.disabled)
            }
            this.numberOfNightsForm.datesAvailable.push(obj)
          }
          // check if we have disabled dates
        }
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
          this.numberOfNightsForm.loading = false
        })
    },

    splitSeasonName (seasonName) {
      return seasonName.replace(' ', '<br />')
    },

    getPropertyInfo () {
      const queryString = this.queryString
      this.selectedProperty = {}
      this.loaded = false

      const apiUrl = '/property/' + this.$route.params.id + queryString
      this.$apiCaller({ route: apiUrl + '&axios22=true', method: 'GET', data: '' }).then((response) => {
        if (response.data.response.hasOwnProperty('property')) {
          this.selectedProperty = response.data.response.property
        } else {
          this.selectedProperty = {}
        }
        if (response.data.response.hasOwnProperty('calendarData')) {
          this.calendarDatesData = response.data.response.calendarData
        } else {
          this.calendarDatesData = false
        }
        if (response.data.response.hasOwnProperty('pagination')) {
          this.pagination = response.data.response.pagination
        } else {
          this.pagination = false
        }
        if (response.data.response.hasOwnProperty('pricing')) {
          this.pricing = response.data.response.pricing
        } else {
          this.pricing = false
        }
        if (response.data.response.hasOwnProperty('deposit')) {
          this.deposit = response.data.response.deposit
        }
        if (response.data.response.hasOwnProperty('proposedDates')) {
          this.proposedDates = response.data.response.proposedDates
        } else {
          this.proposedDates = false
        }

        if (this.selectedProperty) {
          const title = this.selectedProperty.bedrooms + ' bedroom ' + this.selectedProperty.community.fullName + ' ' + this.selectedProperty.propertyTypeName + ' - ' + this.selectedProperty.villaCode
          this.$emit('setPageMetaInfo', { title })
        }

        this.loaded = true
        this.testimonialsVisible = false
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    },

    makePaginationUrl (propertyId, pageNumber) {
      const queryWithAllAttributes = qs.parse(qs.stringify(this.$route.query, { arrayFormat: 'brackets', encode: true }))
      if (!queryWithAllAttributes.hasOwnProperty('criteria')) {
        queryWithAllAttributes.criteria = {}
      }
      queryWithAllAttributes.criteria.page = pageNumber
      queryWithAllAttributes.criteria.rpp = 1
      return '/villa/' + propertyId + '?' + qs.stringify(queryWithAllAttributes, { arrayFormat: 'brackets', encode: true })
    },
    showAccessibilityFeatures () {
      for (const queryItems in this.$route.query) {
        if (queryItems === 'filters[options]') {
          if (this.$route.query[queryItems].includes('accessible-features')) {
            return true
          }
        }
      }

      return false
    }
  },

  computed: {
    paginationSearchResultNextUrl () {
      return this.makePaginationUrl(this.pagination.searchResultNext.villaId, this.pagination.next)
    },

    paginationSearchResultPreviousUrl () {
      return this.makePaginationUrl(this.pagination.searchResultPrevious.villaId, this.pagination.previous)
    },

    queryString () {
      let queryString = ''

      if (this.$route.query) {
        queryString = '?' + qs.stringify(this.$route.query, { arrayFormat: 'brackets', encode: false })
      }

      return queryString
    },

    queryStringWithoutRPPAndPage () {
      const queryStringFiltered = qs.parse(qs.stringify(this.$route.query, {
        arrayFormat: 'brackets',
        encode: false,
        filter: (key, value) => {
          if (key === 'criteria[rpp]' || key === 'criteria[page]') {
            return
          }
          return value
        }
      }))
      return '?' + qs.stringify(queryStringFiltered, { arrayFormat: 'brackets', encode: false })
    },

    priceTotalBreakdown () {
      const checkoutUrlEnd = 'optional-extras' + this.queryStringWithoutRPPAndPage + '&propertyid=' + this.selectedProperty.villaId
      const obj = {
        topPrices: [
          {
            name: 'Total Price',
            amount: this.pricing.total.totalPrice
          }
        ],
        strapline: this.$t('global.priceDisclaimer'),
        dates: [
          { name: 'Check in', date: this.proposedDates.in + ' @ 16:00' },
          { name: 'Check out', date: this.proposedDates.out + ' @ 10:00' }
        ],
        bookNowURL: '/checkout/all/' + checkoutUrlEnd,
        pdfButtons: [
          { type: 'property', name: this.$t('widgets.sendPdf.buttonNames.property') }
        ]
        // pdfQuoteButton: '#'
      }

      if (this.deposit.hasOwnProperty('available') && this.deposit.available && this.deposit.hasOwnProperty('amount')) {
        obj.topPrices.push({
          name: 'Deposit',
          amount: this.deposit.amount
        })
      }
      if (this.pricing.hasOwnProperty('agentCommission')) {
        obj.agentCommmission = this.pricing.agentCommission
      }
      if (this.pricing.total.discounts.length > 0 && this.pricing.total.hasOwnProperty('discounts')) {
        obj.bottomPrices = [
          { name: 'Original Amount', amount: this.pricing.total.originalPrice }
        ]

        for (const i in this.pricing.total.discounts) {
          const thisDiscount = this.pricing.total.discounts[i]
          obj.bottomPrices.push({
            name: thisDiscount.discountType,
            amount: thisDiscount.amount
          })
        }
      }
      return obj
    }
  },

  data () {
    return {
      testimonialsVisible: false,
      pdfGen: {
        type: '',
        typeId: '',
        recipientMessage: ''
      },
      loaded: false,
      calendarOptions: {
        weekStarts: 7,
        calendarStartMonth: moment().format('M'),
        calendarStartYear: moment().format('YYYY'),
        monthsToDisplay: 24
      },
      selectedProperty: {},
      calendarDatesData: false,
      pagination: false,
      pricing: false,
      deposit: 0,
      numberOfNightsForm: {
        chosenCheckInDate: null,
        nights: 0,
        datesAvailable: [],
        loading: false,
        hasDisabledDates: false
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/villas/VillaInfo.scss';
</style>
