<template>
  <div class="text-center mt-4">
    <template v-if="!failure">
      <h1>{{ title }}</h1>
      <p class="lead">
        {{ message }}
      </p>
    </template>
    <template v-else>
      <b-alert variant="danger" show>
        <h4 class="alert-heading">Impersonation failed</h4>
        <p>
          We were unable to impersonate the user you requested.
        </p>
        <hr>
        <p class="mb-0">
          Please contact the system administrator.
        </p>
      </b-alert>
    </template>
  </div>
</template>

<script>
import {
  UPDATE_USER_ROLE
} from '@/store/actions/usernew'

import {
  UPDATE_CONFIG
} from '@/store/actions/config'

import {
  LOG_IN_SUCCESS,
  LOG_OUT,
  SET_IS_IMPERSONATING
} from '@/store/actions/authnew'

export default {
  name: 'Impersonate',
  data () {
    return {
      title: 'Impersonating',
      message: 'Please wait while we log you in...',
      failure: false
    }
  },
  mounted () {
    try {
      // Get the query string
      const query = this.$route.query

      if (!query.data) {
        this.failure = true

        return
      }

      // Clear storage out first
      localStorage.clear()

      // Base64 decode and parse the data
      const data = JSON.parse(atob(query.data))

      localStorage.setItem('user-token', data.token)

      if (data.userRole) {
        localStorage.setItem('userRole', data.userRole)
        this.$store.dispatch(UPDATE_USER_ROLE, data.userRole)
      }

      if (data.config) {
        localStorage.setItem('config', JSON.stringify(data.config))
        this.$store.dispatch(UPDATE_CONFIG, data.config)
      }

      if (data.accountSignupIncomplete) {
        localStorage.setItem('accountSignupIncomplete', 1)
      }

      localStorage.setItem('session-expires', data.tokenExpirySeconds)

      // Set User Id and email (used by bugsnag to identify users)
      localStorage.setItem('userId', data.userId) // Account User Id
      localStorage.setItem('userEmail', data.email) // Account User Email

      this.$store.dispatch(SET_IS_IMPERSONATING, {
        isImpersonating: true,
        userAdminId: data.userAdminId
      })
      this.$store.dispatch(LOG_IN_SUCCESS)
      this.$router.push({
        path: '/',
        force: true
      }) // redirect to homepage
    } catch {
      this.failure = true

      // Something went wrong, log the user out
      this.$store.dispatch(LOG_OUT)
    }
  }
}
</script>
