import {
  UPDATE_CONFIG
} from '@/store/actions/config'
import moment from 'moment'

const state = {
  config: {
    companyDisplayName: false,
    search: {
      dates: {
        earliest: '',
        latest: ''
      },
      nights: {
        min: 0,
        max: 0
      }
    }
  }
}

const getters = {
  searchDates: (state) => {
    return {
      earliest: state.config.search.dates.earliest,
      latest: state.config.search.dates.latest
    }
  },
  searchNights: (state) => {
    return {
      min: state.config.search.nights.min,
      max: state.config.search.nights.max
    }
    // state.config.search.nights
  },
  companyDisplayName: (state) => {
    return state.config.companyDisplayName
  }
}

const actions = {
  [UPDATE_CONFIG]: ({ commit, dispatch }, newConfig) => {
    const newConfigProcessed = {
      companyDisplayName: newConfig.companyDisplayName,
      search: {
        dates: {
          earliest: moment().add(newConfig.search.dates.earliest.amount, newConfig.search.dates.earliest.add).format('YYYY-MM-DD'),
          latest: moment().add(newConfig.search.dates.latest.amount, newConfig.search.dates.latest.add).format('YYYY-MM-DD')
        },
        nights: {
          min: newConfig.search.nights_bookable.min,
          max: newConfig.search.nights_bookable.max
        }
      }
    }
    commit(UPDATE_CONFIG, newConfigProcessed)
  }
}

const mutations = {
  [UPDATE_CONFIG]: (state, configProcessed) => {
    state.config.search.dates.earliest = configProcessed.search.dates.earliest
    state.config.search.dates.latest = configProcessed.search.dates.latest
    state.config.search.nights.min = configProcessed.search.nights.min
    state.config.search.nights.max = configProcessed.search.nights.max
    state.config.companyDisplayName = configProcessed.companyDisplayName
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
