<template>
  <div id="worldpay-payment">
    <h3>Make a payment by Credit or Debit card</h3>
    <div>
      <b-form @submit.prevent="checkAndSubmitPaymentForm" autocomplete="off" :novalidate="true">
        <fieldset>
          <b-form-row>
            <b-col>
              <b-form-group label="Payment Amount" label-sr-only>
                <b-input-group prepend="£">
                  <b-form-input name="amount" v-model="amount" placeholder="Pay Amount" type="number"
                  v-validate="{required: true, between:[amountMin,amountMax], decimal:2}"
                  :state="validationState('amount')"
                  data-vv-as="Payment amount"
                  ></b-form-input>
                  <b-form-invalid-feedback>
                    {{ errors.first('amount') }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group label="Card registered details" label-sr-only>
                  <b-form-select
                  name="details"
                  v-model="detailsSelected"
                  v-validate="{required: true}"
                  data-vv-as="card registered details"
                  :state="validationState('details')"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Select Card registered details --</b-form-select-option>
                    </template>
                    <option value="AGENT">Your details</option>
                    <option value="ORGANISATION">Your organisations details</option>
                    <option value="NONE">Other details</option>
                  </b-form-select>
                  <b-form-invalid-feedback>
                    {{ errors.first('details') }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-btn variant="primary" type="submit" >Show Payment Form</b-btn>
            </b-col>
          </b-form-row>
        </fieldset>
      </b-form>
    </div>
    <payment-modal :bookingReference="bookingReference" :amount="this.amount" :paymentToken="this.paymentToken" :detailsSelected="this.detailsSelected"></payment-modal>
  </div>
</template>

<script>
import PaymentModal from '@/components/modals/PaymentModal'

export default {
  components: {
    'payment-modal': PaymentModal
  },

  methods: {

    checkAndSubmitPaymentForm () {
      this.$validator.validate().then(validated => {
        if (validated) {
          this.$bvModal.show('payment-modal')
        }
      })
    },

    validationState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    }
  },

  props: {
    bookingReference: {
      type: String,
      required: true
    },
    amountMin: {
      type: Number,
      default: 10
    },
    amountMax: {
      type: Number,
      required: true
    },
    paymentToken: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      amount: '',
      detailsSelected: null
    }
  }
}
</script>
