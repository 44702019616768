<template>
  <b-container id="Signup">
    <div v-if="loaded">
      <div v-if="invalidCredentialsOrError">
        <p>{{errorMessage}}</p>
      </div>
      <div v-else>
        <h1>Create new password</h1>
        <div v-if="passwordChangeSuccess">
          <b-row>
            <b-col>
              <section>
                <p>{{ $t('global.passwordChangedMessage.preLink') }} <b-link @click="logout">log-in</b-link> {{$t('global.passwordChangedMessage.postLink')}}</p>
              </section>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <section>
            <p>Please enter your chosen new Password in the boxes below.</p>
          </section>
          <b-form @submit.prevent="checkForm" autocomplete="off" :novalidate="true">
            <fieldset :disabled="newPasswordFormSubmitting">
              <section>
                <password-and-confirm @updated="passwordUpdated" ref="passwordChange" passwordLabel="Choose a new password"></password-and-confirm>
              </section>
              <article class="page-content text-center">
                <loader v-if="newPasswordFormSubmitting"></loader>
                <div v-else>
                  <b-btn variant="primary" size="lg" type="submit">
                    Set new password
                  </b-btn>
                  <p v-if="formValidationErrors === true">Please fix the form errors above before submitting this form</p>
                </div>
              </article>
            </fieldset>
          </b-form>
          <b-modal ref="serverValidationErrors" title="The following form fields contain errors" header-text-variant="light" centered header-bg-variant="danger" ok-only>
            <ul v-if="serverValidationErrors">
              <li v-for="theError in validationErrors">
                {{theError.error}}
              </li>
            </ul>
          </b-modal>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="loader-outer">
        <loader width="100px" height="100px"></loader>
      </div>
    </div>
  </b-container>
</template>

<script>
import loader from '@/components/widgets/loader'
import passwordAndConfirm from '@/components/widgets/form-fields/password-and-confirm'
import { LOG_OUT } from '@/store/actions/authnew'

export default {
  name: 'PasswordReset',

  components: {
    loader,
    'password-and-confirm': passwordAndConfirm
  },

  mounted () {
    // http://localhost:8080/admin/agent/password-reset/16/1f043530-9a88-11e9-8a4c-b99ae5b3d8eeu16
    // password-reset/:apiUserId/:token/:apiUserPasswordResetId
    this.checkPasswordResetCredentials()
  },

  methods: {

    logout () {
      this.$store.dispatch(LOG_OUT, { timedOut: false })
    },

    checkPasswordResetCredentials () {
      this.loaded = false
      const apiUrl = '/reset-password/' + this.$route.params.apiUserId + '/' + this.$route.params.token + '/' + this.$route.params.apiUserPasswordResetId
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '', displayErrorAlert: false }).then((response) => {
        // console.log(response.data)
      })
        .catch(() => {
          // console.log(error)
          this.invalidCredentialsOrError = true
          this.errorMessage = 'Sorry we are unable to locate your details, it may be that the password reset link has expired.'
        })
        .finally(() => {
          this.loaded = true
        })
    },

    passwordUpdated (event) {
      this.formValidationErrors = false
      this.newPassword = event.data.password
    },

    checkForm (event) {
      this.passwordChangeFormSubmitting = true
      this.formValidationErrors = false
      const passwordsValidator = this.$refs.passwordChange.validator()
      Promise.all([passwordsValidator.validate()]).then((values) => {
        if (values.includes(false)) {
          this.passwordChangeFormSubmitting = false
          this.formValidationErrors = true
        } else {
          this.submitForm()
        }
      })
    },

    submitForm () {
      const apiUrl = '/reset-password/' + this.$route.params.apiUserId + '/' + this.$route.params.token + '/' + this.$route.params.apiUserPasswordResetId
      this.$apiCaller({ route: apiUrl, method: 'POST', data: { password: this.newPassword }, displayErrorAlert: false }).then((response) => {
        this.passwordChangeSuccess = true // change to true to progress to next form
      })
        .catch((error) => {
          const responseData = error.response.data
          if (responseData.hasOwnProperty('validationErrors')) {
            this.serverValidationErrors = true
            this.validationErrors = responseData.validationErrors
            this.$refs.serverValidationErrors.show()
          } else {
            this.invalidCredentialsOrError = true
            this.errorMessage = 'Sorry we are unable to update your password - please contact us for help'
          }
        })
    }
  },

  data () {
    return {
      loaded: false,
      formValidationErrors: false,
      serverValidationErrors: false,
      newPasswordFormSubmitting: false,
      passwordChangeSuccess: false,
      invalidCredentialsOrError: false,
      newPassword: ''
    }
  }
}
</script>
