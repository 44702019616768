<template>
  <div>
    <b-row>
      <b-col lg="6">
        <b-form-group
          :label="passwordLabel"
          label-for="password"
          label-class="required">
          <b-form-input
            autocomplete="off"
            @input="updateReturnValues"
            @change="updateReturnValues"
            id="password"
            name="password"
            data-vv-as="password"
            v-validate="{required: true, max:30, verify_password: true}"
            :state="validationState('password')"
            type="password"
            v-model="passwords.password"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('password') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group
          label="Confirm Password"
          label-for="passwordConfirm"
          label-class="required">
          <b-form-input
            autocomplete="off"
            @input="updateReturnValues"
            @change="updateReturnValues"
            id="passwordConfirm"
            name="passwordConfirm"
            data-vv-as="confirm password"
            v-validate="{required: true, confirmed: passwords.password}"
            :state="validationState('passwordConfirm')"
            type="password"
            v-model="passwords.passwordConfirm"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('passwordConfirm') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'password-and-confirm-fields',

  props: {
    formData: {
      type: Object,
      required: false
    },
    passwordLabel: {
      type: String,
      default: 'Password'
    }
  },

  mounted () {
    if (this.formData) {
      for (const key in this.person) {
        if (this.formData.hasOwnProperty(key)) {
          this.person[key] = this.formData[key]
        }
      }
      this.$validator.validate()
    }
  },

  methods: {
    validator () {
      return this.$validator
    },
    updateReturnValues () {
      for (const key in this.passwords) {
        if (this.passwords.hasOwnProperty(key)) {
          this.output.data[key] = this.passwords[key]
        }
      }
      this.$emit('updated', this.output)
    },

    validationState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    }
  },

  data () {
    return {
      passwords: {
        password: '',
        passwordConfirm: ''
      },

      output: {
        data: {
          password: '',
          passwordConfirm: ''
        }
      }
    }
  }
}
</script>
