<template>
  <div id="userAccount">
    <h1>Your account</h1>
    <div v-if="loaded">
      <div v-if="passwordChangeCompleted">
        <b-row>
          <b-col>
            <section>
              <p>{{ $t('global.passwordChangedMessage.preLink') }} <b-link @click="logout">log-in</b-link> {{$t('global.passwordChangedMessage.postLink')}}</p>
            </section>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col lg="6">
            <section>
              <h2>About you</h2>
              <b-table stacked :items="adminUserInfo"></b-table>
              <p>Please contact us to change any of these details.</p>
            </section>
          </b-col>
          <b-col lg="6">
            <section>
              <h2>Change password</h2>
              <b-form @submit.prevent="checkForm" autocomplete="off" :novalidate="true">
                <fieldset :disabled="passwordChangeFormSubmitting">
                <password-change @updated="passwordFormUpdated" ref="passwordChange"></password-change>

                <article class="page-content text-center">
                  <loader v-if="passwordChangeFormSubmitting"></loader>
                  <div v-else>
                    <b-btn variant="primary" size="lg" type="submit">Change Password</b-btn>
                    <p v-if="formValidationErrors === true"><br />Please fix the form errors above before submitting this form</p>
                  </div>
                </article>
                </fieldset>
              </b-form>
            </section>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else class="loader-outer">
      <loader width="100px" height="100px"></loader>
    </div>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader.vue'
import { LOG_OUT } from '@/store/actions/authnew'
import passwordChange from '@/components/widgets/form-fields/password-change'

export default {
  name: 'Account',

  components: {
    loader,
    'password-change': passwordChange
  },

  mounted () {
    this.loadAdminUserAccount()
  },

  methods: {
    logout () {
      this.$store.dispatch(LOG_OUT, { timedOut: false })
    },

    passwordFormUpdated (event) {
      this.formValidationErrors = false
      this.updatePasswordDetails = {
        password: event.data.existingPassword,
        newPassword: event.data.newPassword
      }
    },

    checkForm (event) {
      this.passwordChangeFormSubmitting = true
      this.formValidationErrors = false
      const passwordsValidator = this.$refs.passwordChange.validator()
      Promise.all([passwordsValidator.validate()]).then((values) => {
        if (values.includes(false)) {
          this.passwordChangeFormSubmitting = false
          this.formValidationErrors = true
        } else {
          this.submitForm()
        }
      })
    },

    submitForm () {
      const apiUrl = '/agent/change-password'
      this.$apiCaller({ route: apiUrl, method: 'POST', data: this.updatePasswordDetails }).then((response) => {
        this.passwordChangeCompleted = true
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          this.passwordChangeFormSubmitting = false
          // set loaded
        })
    },

    loadAdminUserAccount () {
      this.loaded = false
      this.adminUserInfo = [{}]
      const apiUrl = '/agent/account-info'
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        if (response.data.response.hasOwnProperty('agentAccountInfo')) {
          const agentAccountInfo = response.data.response.agentAccountInfo
          this.adminUserInfo = [{
            title: agentAccountInfo.clientTitle,
            forname: agentAccountInfo.clientForename,
            surname: agentAccountInfo.clientSurname,
            telephone: agentAccountInfo.clientTelephone,
            mobile: agentAccountInfo.clientMobile,
            email: agentAccountInfo.clientEmail,
            address_1: agentAccountInfo.address1,
            address_2: agentAccountInfo.address2,
            town: agentAccountInfo.addTown,
            county: agentAccountInfo.addCounty,
            postcode: agentAccountInfo.addPostcode,
            country: agentAccountInfo.countryName,
            company_name: agentAccountInfo.clientCompName,
            organisation: agentAccountInfo.organisation.clientCompName
          }]
        }
        this.loaded = true
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },

  data () {
    return {
      loaded: false,
      formValidationErrors: false,
      passwordChangeFormSubmitting: false,
      passwordChangeCompleted: false,
      adminUserInfo: [
        {}
      ] // its an object inside an array because its drawn out as single item table row.
    }
  }
}
</script>
