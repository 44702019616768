<template>
  <b-container id="terms-conditions" v-if="!loading">
    <masthead image-url="terms_conditions.jpg" title="Terms and Conditions"></masthead>
    <article class="page-content" id="terms_nav">
      <ol v-if="terms.length > 0" class="term_nav">
        <li v-for="(term, key) in terms" :key="key">
          <b-link v-scroll-to="'#term_' + key">{{term.heading}}</b-link>
        </li>
      </ol>
    </article>
    <article class="page-content term" v-for="(term, key) in terms" :key="key" :id="'term_' + key">
      <section class="term-area">
        <h2>{{term.heading}}
          <small>
            <b-link v-scroll-to="'#terms_nav'">Back to terms sections <span class="smaller">▲</span></b-link>
          </small>
        </h2>
        <vue-markdown :anchorAttributes="{target:'blank'}">{{term.body}}</vue-markdown>
      </section>
    </article>
  </b-container>
  <div class="loader-outer" v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader.vue'

const Masthead = () => ({
  component: import('@/components/widgets/masthead.vue'),
  loading: loader
})

export default {
  name: 'TermsConditions',

  components: {
    Masthead,
    loader
  },

  mounted () {
    this.loading = true
    this.terms = []
    const apiUrl = this.$route.fullPath
    this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
      const termsData = response.data.response.terms
      for (const i in termsData) {
        const term = termsData[i]
        this.terms.push({
          heading: term.heading + ' Terms & Conditions',
          body: term.body
        })
      }
      this.loading = false
    })
      .catch(() => {
        // set page errors
      })
      .finally(() => {
        // set loaded
      })
  },

  data () {
    return {
      loading: true,
      terms: []
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/Terms.scss';
</style>
