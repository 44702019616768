<template>
  <b-modal ref="PdfSendForm" :title="modalTitle" size="lg" centered hide-footer @hide="handleHidePdfForm">
    <div v-if="!formSubmitted">
      <b-form :novalidate="true" @submit="checkPdfForm">
        <fieldset :disabled="formSubmitting">
          <b-form-group id="recipientEmailGroup"
                        label="Recipient email address:"
                        label-for="recipientEmail">
            <b-form-input id="recipientEmail"
                          name="recipientEmail"
                          data-vv-as="email"
                          v-validate="{required:true, email: true}"
                          :state="validationState('recipientEmail')"
                          type="email"
                          v-model="sendPdfForm.recipientEmail"
                          required
                          placeholder="Enter email">
            </b-form-input>
            <b-form-invalid-feedback>
              {{ errors.first('recipientEmail') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="messageToRecipientGroup"
                        label="Message to the recipient:"
                        label-for="messageToRecipient">
            <b-form-textarea id="messageToRecipient"
                        name="messageToRecipient"
                        data-vv-as="message to the recipient"
                        v-validate="{required: false, max:500}"
                        :state="validationState('messageToRecipient')"
                         v-model="sendPdfForm.recipientMessage"
                         placeholder="Enter message"
                         rows="6" >
             </b-form-textarea>
             <b-form-invalid-feedback>
               {{ errors.first('messageToRecipient') }}
             </b-form-invalid-feedback>
          </b-form-group>

          <b-form-checkbox id="ccApiUser"
                         v-model="sendPdfForm.ccApiUser"> {{ 'widgets.sendPdf.ccApiUser' | translate }}
          </b-form-checkbox>

          <div class="mt-4 text-right">
            <loader v-if="formSubmitting"></loader>
            <b-button v-else variant="primary" type="submit" :disabled="errors.any()">
              {{ 'widgets.sendPdf.buttonNames.' + type | translate }}
            </b-button>
            <p v-if="errors.any()">Please fix the form errors above before submitting this form</p>
          </div>
        </fieldset>
      </b-form>
      <b-alert v-if="errorMessage" variant="danger" show>{{errorMessage}}</b-alert>
    </div>
    <div v-else>
      <b-alert variant="success" show>The PDF has been placed in a queue to send to the recipient - it will be sent shortly</b-alert>
      <b-alert variant="success" show v-if="type === 'quote'">This quote can now be retrieved from within your admin</b-alert>
      <div class="mt-4 text-right">
        <b-button @click="formSubmitted = false">Send Another</b-button>
    </div>
    </div>
  </b-modal>
</template>

<script>
import loader from '@/components/widgets/loader'

export default {
  name: 'pdf-generator',

  components: {
    loader
  },

  methods: {
    init () {
      this.$nextTick(() => {
        this.sendPdfForm.recipientMessage = this.recipientMessage
        this.modalTitle = this.$t('widgets.sendPdf.modalTitles.' + this.type)
        this.apiUrl = '/send-pdf/' + this.type + '/' + this.typeId
        if (this.pdfFilePath) {
          this.sendPdfForm.pdfFilePath = this.pdfFilePath
        }
      })
      this.$refs.PdfSendForm.show()
    },

    handleHidePdfForm (evt) {
      if (this.formSubmitting) {
        evt.preventDefault()
        this.errorMessage = 'Please wait for the form to finish sending'
      } else {
        this.formSubmitted = false
      }
    },
    validationState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    },

    checkPdfForm (evt) {
      evt.preventDefault()
      this.$validator.validate().then((result) => {
        if (!result) {
          // should not get here as should be able to submit the form when there are errors anyway
          // do stuff if not valid.
        } else {
          // form has validated ok
          // submit the form
          this.formSubmitting = true
          this.$apiCaller({ route: this.apiUrl, method: 'POST', data: this.sendPdfForm, displayErrorAlert: false }).then((response) => {
            this.formSubmitting = false
            this.errorMessage = false
            this.formSubmitted = true
            this.resetForm()
          })
            .catch((error) => {
              this.sendpdferror = error
              this.formSubmitting = false
              this.errorMessage = 'Sorry unable to send the PDF at the moment - please try again shortly'
            })
            .finally(() => {
              // set loaded
            })
        }
      })
    },

    resetForm () {
      this.sendPdfForm.recipientEmail = ''
    }
  },

  props: {

    type: {
      type: String,
      required: true
    },

    typeId: {
      type: String,
      required: true
    },

    recipientMessage: {
      required: true,
      type: String
    },

    pdfFilePath: {
      type: String
    }
  },

  data () {
    return {
      apiUrl: '',
      modalTitle: '',
      errorMessage: false,
      formSubmitting: false,
      formSubmitted: false,
      sendPdfForm: {
        recipientEmail: '',
        recipientMessage: '',
        ccApiUser: true,
        pdfFilePath: ''
      }
    }
  }
}
</script>
