<template>
  <section class="carousel" :class="{'no-small': !carousel.displaySmall}">
    <template v-if="images.hasOwnProperty('l') && images.hasOwnProperty('s')">
      <div class="inner-container">
        <div class="outer-one">
          <slick ref="carouselMain" :options="slickOptions.largeCarousel" id="carousel" v-if="carousel.displayLarge">
            <div class="imgOuter" v-for="(image, key) in images.l">
              <div class="imgInner">
                <div class="caption">
                  {{ key + 1 }} / {{ images.l.length }}: {{ image.title }}
                </div>

                <img :data-lazy="image.imgUrl" :alt="image.title" class="img-fluid"/>
              </div>
            </div>
          </slick>

          <a class="play-pause" @click="playPauseCarousel" v-if="carousel.displaySmall">
            <font-awesome-icon :icon="playPauseIcon"></font-awesome-icon>
            <span class="text">{{ carousel.indicator }}</span>
          </a>
        </div>
        <div class="outer-two" v-if="carousel.displaySmall">
          <slick ref="carouselControl"
            :options="slickOptions.smallCarousel"
            v-if="carousel.displaySmall"
            id="carouselControl">
            <div class="imgOuter" v-for="(image, key) in images.s">
              <div class="imgInner">
                <img :data-lazy="image.imgUrl" :alt="image.title" class="img-fluid"/>
              </div>
            </div>
          </slick>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import Slick from 'vue-slick'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'dv-carousel',

  components: {
    Slick,
    FontAwesomeIcon
  },

  props: {
    images: {
      required: true,
      type: Object
    }
  },

  watch: {
    images: {
      handler: function (newVal) {
        this.rejiggerizeCarousel(newVal).then(function () {
          const currentSlides = {}

          if (this.carousel.displayLarge) {
            currentSlides.large = this.$refs.carouselMain.currentSlide()
            this.$refs.carouselMain.destroy()
          }

          if (this.carousel.displaySmall) {
            currentSlides.small = this.$refs.carouselControl.currentSlide()
            this.$refs.carouselControl.destroy()
          }

          this.$nextTick(() => {
            if (this.carousel.displayLarge) {
              this.$refs.carouselMain.create()
              this.$refs.carouselMain.goTo(currentSlides.large, false)
            }

            if (this.carousel.displaySmall) {
              this.$refs.carouselControl.create()
              this.$refs.carouselControl.goTo(currentSlides.small, false)
            }
          })
        }.bind(this))
      },
      deep: true
    }
  },

  mounted () {
    this.rejiggerizeCarousel(this.images)
  },

  computed: {
    playPauseIcon () {
      if (this.carousel.playing) {
        return faPause
      } else {
        return faPlay
      }
    }
  },

  methods: {
    rejiggerizeCarousel (carouselImages) {
      return new Promise((resolve) => {
        if (carouselImages.hasOwnProperty('l') && carouselImages.hasOwnProperty('s')) {
          if (carouselImages.s.length === 1) {
            this.carousel.displaySmall = false
          } else {
            this.carousel.displaySmall = true
          }

          if (carouselImages.l.length >= 1) {
            this.carousel.displayLarge = true
          } else {
            this.carousel.displayLarge = false
          }

          if (carouselImages.s.length >= 9) {
            this.slickOptions.smallCarousel.slidesToShow = 9
          } else if (carouselImages.s.length > 1 && carouselImages.s.length < 9) {
            this.slickOptions.smallCarousel.slidesToShow = carouselImages.s.length
          } else {
            this.slickOptions.smallCarousel.slidesToShow = 1
          }

          // MD Breakpoint
          if (carouselImages.s.length >= 4) {
            this.slickOptions.smallCarousel.responsive[0].settings.slidesToShow = 4
          } else if (carouselImages.s.length < 4 && carouselImages.s.length > 1) {
            this.slickOptions.smallCarousel.responsive[0].settings.slidesToShow = carouselImages.s.length
          } else {
            this.slickOptions.smallCarousel.responsive[0].settings.slidesToShow = 1
          }

          // XS Breakpoint
          if (carouselImages.s.length >= 3) {
            this.slickOptions.smallCarousel.responsive[1].settings.slidesToShow = 3
          } else if (carouselImages.s.length < 3 && carouselImages.s.length > 1) {
            this.slickOptions.smallCarousel.responsive[1].settings.slidesToShow = carouselImages.s.length
          } else {
            this.slickOptions.smallCarousel.responsive[1].settings.slidesToShow = 1
          }
        }

        resolve(true)
      })
    },

    playPauseCarousel () {
      if (this.carousel.playing) { // if the carousel is currently playing
        this.carousel.playing = false // stop the carousel
        this.carousel.indicator = 'Play'
        this.$refs.carouselMain.pause()
      } else {
        this.carousel.playing = true // start the carousel
        this.carousel.indicator = 'Pause'
        this.$refs.carouselMain.play()
      }
    }
  },

  data () {
    return {
      carousel: {
        init: false,
        playing: false,
        indicator: 'Play',
        displayLarge: false,
        displaySmall: false
      },

      slickOptions: {
        largeCarousel: {
          lazyload: 'ondemand',
          arrows: true,
          dots: false,
          // slide: '.imgOuter',
          asNavFor: '#carouselControl',
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '210px',
          pauseOnFocus: false,
          pauseOnHover: false,
          infinite: true,
          responsive: [
            {
              // md
              breakpoint: 1199,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '110px'
              }
            },
            {
              // sm
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px'
              }

            },
            {
              // xs
              breakpoint: 767,
              settings: {
                arrows: false,
                slidesToScroll: 1,
                slidesToShow: 1,
                centerMode: false,
                centerPadding: '0px'
              }
            }
          ]
        },
        smallCarousel: {
          lazyload: 'ondemand',
          arrows: false,
          dots: false,
          slidesToShow: 9,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '60px',
          focusOnSelect: true,
          infinite: true,
          // slide: 'div > .imgOuter',
          asNavFor: '#carousel',
          pauseOnFocus: false,
          pauseOnHover: false,
          responsive: [
            {
              // md
              breakpoint: 1199,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            },
            {
              // xs
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
                centerPadding: '0px',
                slidesToScroll: 1
              }
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/widgets/carousel.scss';
</style>
