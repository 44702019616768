<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          label="Current Password"
          label-for="existingPassword"
          label-class="required">
          <b-form-input
            autocomplete="off"
            @input="updateReturnValues"
            @change="updateReturnValues"
            id="existingPassword"
            name="existingPassword"
            data-vv-as="password"
            v-validate="{required: true}"
            :state="validationState('existingPassword')"
            type="password"
            v-model="passwords.existingPassword"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('existingPassword') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="New Password"
          label-for="newPassword"
          label-class="required">
          <b-form-input
            autocomplete="off"
            @input="updateReturnValues"
            @change="updateReturnValues"
            id="newPassword"
            name="newPassword"
            data-vv-as="password"
            v-validate="{required: true, max:30, verify_password: true}"
            :state="validationState('newPassword')"
            type="password"
            v-model="passwords.newPassword"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('newPassword') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Confirm New Password"
          label-for="newPasswordConfirm"
          label-class="required">
          <b-form-input
            autocomplete="off"
            @input="updateReturnValues"
            @change="updateReturnValues"
            id="newPasswordConfirm"
            name="newPasswordConfirm"
            data-vv-as="confirm password"
            v-validate="{required: true, confirmed: passwords.newPassword}"
            :state="validationState('newPasswordConfirm')"
            type="password"
            v-model="passwords.newPasswordConfirm"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('newPasswordConfirm') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  // TODO this should make use of the password-and-confirm plugin
  name: 'new-password-and-confirm-fields',

  props: {
    formData: {
      type: Object,
      required: false
    }
  },

  mounted () {
    if (this.formData) {
      for (const key in this.person) {
        if (this.formData.hasOwnProperty(key)) {
          this.person[key] = this.formData[key]
        }
      }
      this.$validator.validate()
    }
  },

  methods: {
    validator () {
      return this.$validator
    },
    updateReturnValues () {
      for (const key in this.passwords) {
        if (this.passwords.hasOwnProperty(key)) {
          this.output.data[key] = this.passwords[key]
        }
      }
      this.$emit('updated', this.output)
    },

    validationState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    }
  },

  data () {
    return {
      passwords: {
        existingPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
      },

      output: {
        data: {
          existingPassword: '',
          newPassword: '',
          newPasswordConfirm: ''
        }
      }
    }
  }
}
</script>
