<template>
  <div id="userGuides">
    <div v-if="!loading">
      <pdf-generator ref="pdfGen" :type="pdfGen.type" :typeId="pdfGen.typeId" :recipientMessage="pdfGen.recipientMessage" :pdfFilePath="pdfGen.pdfFilePath"></pdf-generator>
      <h1>Guides</h1>
      <b-row>
        <b-col>
          <img src="@/assets/images/admin/dashboard/DigitalHolidayGuide-Image.jpg" style="max-width: 100%; max-height: 100%;"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <section>
            <h2>Downloadable guides for your information and / or to forward to clients:</h2>
            <b-table-lite :items="guides" :fields="fields" stacked="md" thead-class="hidden_header">
              <template #cell(name)="data">
                {{data.item.name}}<span v-if="data.item.nameExtra"> {{data.item.nameExtra}}</span>
              </template>
              <template #cell(updated)="data">
                Updated: {{ $moment(data.item.updated, 'YYYY-MM-DD').format('D[&nbsp;]MMM[&nbsp;]YYYY') }}
              </template>
              <template #cell(download)="data">
                <loader v-if="data.item.downloading"></loader>
                <template v-else>
                  <b-button @click="downloadPdf(data.item)" variant="primary" size="sm">Download</b-button>
                </template>
              </template>
              <template #cell(sendToCustomer)="data">
                <template  v-if="data.item.sendToCustomer">
                  <b-button variant="primary" size="sm" @click="pdfButtonClicked(data.item)">Send to Customer</b-button>
                </template>
              </template>
            </b-table-lite>
          </section>
        </b-col>
      </b-row>
    </div>
    <div class="loader-outer" v-else>
      <loader width="100px" height="100px"></loader>
    </div>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader.vue'
import pdfGenerator from '@/components/widgets/pdf-generator'

export default {
  name: 'Guides',

  components: {
    'pdf-generator': pdfGenerator,
    loader
  },

  mounted () {
    this.loading = true
    this.guides = []
    const apiUrl = '/guides'
    this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
      for (const i in response.data.response.guides) {
        const guideInfo = response.data.response.guides[i]
        guideInfo.downloading = false
        this.guides.push(guideInfo)
      }
      this.loading = false
    })
      .catch(() => {
        // set page errors
      })
      .finally(() => {
        // set loaded
      })
  },

  methods: {
    downloadPdf (guideItem) {
      guideItem.downloading = true
      const updatedFormated = this.$moment(guideItem.updated, 'YYYY-MM-DD').format('YYYYMMDD')
      const apiUrl = '/guide/' + updatedFormated + '/' + guideItem.filename
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '', responseType: 'blob' }).then((response) => {
        const pdfBlob = response.data

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(pdfBlob)
          guideItem.downloading = false
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(pdfBlob)
        const link = document.createElement('a')
        document.body.appendChild(link)
        link.href = data
        link.download = guideItem.filename
        link.click()
        guideItem.downloading = false
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    },

    pdfButtonClicked (guideItem) {
      this.pdfGen.recipientMessage = this.$t('widgets.sendPdf.recipientMessages.guide', { guideName: guideItem.name })
      const updatedFormated = this.$moment(guideItem.updated, 'YYYY-MM-DD').format('YYYYMMDD')
      this.pdfGen.pdfFilePath = updatedFormated + '/' + guideItem.filename
      this.$refs.pdfGen.init()
    }
  },

  data () {
    return {
      loading: true,
      pdfGen: {
        type: 'guide',
        typeId: '0',
        recipientMessage: '',
        pdfFilePath: ''
      },
      guides: [],
      fields: ['name', 'updated', 'download', 'sendToCustomer']
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/admin/Guides.scss';
  .hidden_header {
    display: none;
  }
</style>
