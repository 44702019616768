import Vue from 'vue'
import Router from 'vue-router'
// import store from '@/store'

// Generic pages
import NotFound from '@/components/pages/404'
import Home from '@/components/pages/Home'
import Login from '@/components/pages/Login'
import Impersonate from '@/components/pages/Impersonate'

// Admin section
import Admin from '@/components/pages/admin/Admin'
import HelpSupport from '@/components/pages/admin/Help'
import UserAccount from '@/components/pages/admin/Account'
import AdminGuides from '@/components/pages/admin/Guides'
import Dashboard from '@/components/pages/admin/Dashboard'
import SentQuotes from '@/components/pages/admin/SentQuotes'
import Bookings from '@/components/pages/admin/Bookings'
import BookingInfo from '@/components/pages/admin/BookingInfo'
import Signup from '@/components/pages/admin/Signup'
import SignupComplete from '@/components/pages/admin/SignupComplete'
import PasswordReset from '@/components/pages/admin/PasswordReset'

// Villa, community & about florida pages
import Villas from '@/components/pages/villas/Villas'
import VillaInfo from '@/components/pages/villas/VillaInfo'
import OptionalExtras from '@/components/pages/villas/OptionalExtras'
import Communities from '@/components/pages/communities/Communities'
import CommunityInfo from '@/components/pages/communities/CommunityInfo'
import AboutFlorida from '@/components/pages/about-florida/AboutFlorida'
import ThemeParks from '@/components/pages/about-florida/ThemeParks'
import ThemeParkRides from '@/components/pages/about-florida/ThemeParkRides'
import WildLife from '@/components/pages/about-florida/WildLife'
import Weather from '@/components/pages/about-florida/Weather'
import FloridaMap from '@/components/pages/about-florida/Map'

import TermsConditions from '@/components/pages/TermsConditions'

// Checkout pages
import Checkout from '@/components/pages/checkout/Checkout'
import CheckoutCompleted from '@/components/pages/checkout/CheckoutCompleted'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      pathToRegexpOptions: { strict: true },
      meta: {
        noMargin: true,
        pageTitle: 'Login'
      }
    },
    {
      path: '/admin/impersonate',
      name: 'Impersonate',
      component: Impersonate,
      pathToRegexpOptions: { strict: true },
      meta: {
        noMargin: true,
        pageTitle: 'Impersonate'
      }
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      pathToRegexpOptions: { strict: true },
      meta: {
        noMargin: true,
        pageTitle: 'Home',
        roles: ['AGENT', 'ORGANISATION', 'ACCOUNTS']
      }
    },
    {
      path: '/villas',
      name: 'Villas',
      component: Villas,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'Property Search',
        roles: ['AGENT']
      }
    },
    {
      path: '/villa/:id',
      name: 'Villa',
      component: VillaInfo,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: '',
        roles: ['AGENT']
      }
    },
    {
      path: '/optional-extras',
      name: 'Optional Extras',
      component: OptionalExtras,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'Optional Extras',
        roles: ['AGENT']
      }
    },
    {
      path: '/communities',
      redirect: '/communities/A',
      alias: '/about-florida/communities',
      name: 'About Florida Communities',
      component: Communities,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'Communities',
        roles: ['AGENT']
      }
    },
    {
      path: '/communities/:letter(A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z)?',
      name: 'Community Search',
      component: Communities,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'Communities',
        roles: ['AGENT']
      }
    },
    {
      path: '/community/:id',
      name: 'Community',
      component: CommunityInfo,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'Communities',
        roles: ['AGENT']
      }
    },
    {
      path: '/about-florida/theme-parks/:parkId',
      name: 'Theme park',
      component: ThemeParks,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'About Florida / Theme Parks',
        roles: ['AGENT']
      }
    },
    {
      path: '/about-florida/theme-parks/:parkId/:rideId',
      name: 'Theme park ride',
      component: ThemeParkRides,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'About Florida / Theme Parks',
        roles: ['AGENT']
      }
    },
    {
      path: '/about-florida/weather',
      name: 'Weather',
      component: Weather,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'About Florida / Weather',
        roles: ['AGENT']
      }
    },
    {
      path: '/about-florida/wildlife',
      name: 'Wildlife',
      component: WildLife,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'About Florida / Wildlife',
        roles: ['AGENT']
      }
    },
    {
      path: '/about-florida/map',
      name: 'Map',
      component: FloridaMap,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'About Florida / Map',
        roles: ['AGENT']
      }
    },
    {
      path: '/about-florida/:sectionName?',
      name: 'About Florida Sub Page',
      component: AboutFlorida,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'About Florida',
        roles: ['AGENT']
      }
    },
    {
      path: '/checkout/:product(reserve|all)/:stage(optional-extras|customer-details)/:ref?',
      name: 'Checkout',
      component: Checkout,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'Checkout',
        roles: ['AGENT']
      }
    },
    {
      path: '/checkout-completed/:ref',
      name: 'Checkout-Completed',
      component: CheckoutCompleted,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'Checkout Completed',
        roles: ['AGENT']
      }
    },
    {
      path: '/terms-conditions/:type(vc-booking|vc-agent-signup)',
      name: 'terms-conditions',
      component: TermsConditions,
      pathToRegexpOptions: { strict: true },
      meta: {
        pageTitle: 'Terms and Conditions',
        roles: ['AGENT']
      }
    },
    {
      path: '/admin/agent',
      component: Admin,
      pathToRegexpOptions: { strict: true },
      redirect: ('/admin/agent/dashboard'),
      children: [
        {
          path: 'password-reset/:apiUserId/:token/:apiUserPasswordResetId',
          pathToRegexpOptions: { strict: true },
          component: PasswordReset,
          meta: {
            pageTitle: 'Admin / Reset Password'
            // roles: ['AGENT']
          }
        },
        {
          path: 'signup/:agentInviteId/:token',
          pathToRegexpOptions: { strict: true },
          component: Signup,
          meta: {
            pageTitle: 'Admin / Signup'
            // roles: ['AGENT']
          }
        },
        {
          path: 'complete-signup',
          pathToRegexpOptions: { strict: true },
          component: SignupComplete,
          meta: {
            pageTitle: 'Admin / Complete Signup',
            roles: ['AGENT']
          }
        },
        {
          path: 'dashboard',
          pathToRegexpOptions: { strict: true },
          component: Dashboard,
          meta: {
            pageTitle: 'Admin',
            roles: ['AGENT']
          }
        },
        {
          path: 'bookings',
          pathToRegexpOptions: { strict: true },
          component: Bookings,
          meta: {
            pageTitle: 'Admin / Customer Bookings',
            roles: ['AGENT']
          }
        },
        {
          path: 'bookings/:ref',
          pathToRegexpOptions: { strict: true },
          component: BookingInfo,
          meta: {
            pageTitle: 'Admin / Customer Bookings',
            roles: ['AGENT']
          }
        },
        {
          path: 'quotes',
          pathToRegexpOptions: { strict: true },
          component: SentQuotes,
          meta: {
            pageTitle: 'Admin / Sent Quotes',
            roles: ['AGENT']
          }
        },
        {
          path: 'account',
          pathToRegexpOptions: { strict: true },
          component: UserAccount,
          meta: {
            pageTitle: 'Admin / Account',
            roles: ['AGENT']
          }
        },
        {
          path: 'support',
          pathToRegexpOptions: { strict: true },
          component: HelpSupport,
          meta: {
            pageTitle: 'Admin / Help and Support',
            roles: ['AGENT']
          }
        },
        {
          path: 'guides',
          pathToRegexpOptions: { strict: true },
          component: AdminGuides,
          meta: {
            pageTitle: 'Admin / Guides',
            roles: ['AGENT']
          }
        }
      ]
    },
    {
      path: '/admin/organisation',
      component: Admin,
      pathToRegexpOptions: { strict: true },
      redirect: ('/admin/organisation/dashboard'),
      children: [
        {
          path: 'dashboard',
          pathToRegexpOptions: { strict: true },
          component: Dashboard,
          meta: {
            pageTitle: '',
            roles: ['ORGANISATION']
          }
        }
      ]
    },
    {
      path: '/admin/accounts',
      component: Admin,
      pathToRegexpOptions: { strict: true },
      redirect: ('/admin/accounts/dashboard'),
      children: [
        {
          path: 'dashboard',
          pathToRegexpOptions: { strict: true },
          component: Dashboard,
          meta: {
            pageTitle: '',
            roles: ['ACCOUNTS']
          }
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: NotFound,
      meta: {
        pageTitle: 'Page not found',
        noMargin: true
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // console.log(to)
    // console.log(from)
    // console.log(savedPosition)
    if (savedPosition) {
      return savedPosition
    } else {
      let scrollPos = { x: 0, y: 0 }
      if (from.hasOwnProperty('params') && from.params.hasOwnProperty('stage') && from.params.stage === 'optional-extras' && to.hasOwnProperty('params') && to.params.hasOwnProperty('stage') && to.params.stage === 'optional-extras') {
        // do not go to the top of the page for an optional extras change - just load new values
        scrollPos = false
      }
      return scrollPos
    }
  }
})
