<template>
  <aside id="searchFilters">
    <b-link href="#" class="clear-filters" @click="clearFilters()" :disabled="selectedFilterCount < 1 ? true : false">Clear {{ selectedFilterCount }} {{ selectedFilterCount === 1 ? 'filter' : 'filters' }}</b-link>

    <h2>
      Filter your results
    </h2>

    <div class="filter-section" v-for="(section, sectionKey) in filterItems" role="tab">
      <h3>
        <b-link href="#"
          @click="filterGroupState[sectionKey] === false ? filterGroupState[sectionKey] = true : filterGroupState[sectionKey] = false"
          :class="filterGroupState[sectionKey] === false ? 'collapsed' : null"
          :aria-controls="'accordion' + sectionKey"
          :aria-expanded="filterGroupState[sectionKey] === false ? false : true">
          {{ section.displayName }}

          <b-img v-show="selectedFilters[section.section].length > 0" blank width="8" height="8" rounded="circle" blank-color="#ffc107" alt="Is active" class="filter-indicator" />

          <font-awesome-icon v-show="filterGroupState[sectionKey] === false" :icon="showContent"></font-awesome-icon>
          <font-awesome-icon v-show="filterGroupState[sectionKey] === true" :icon="hideContent"></font-awesome-icon>
        </b-link>
      </h3>
      <b-collapse :id="'accordion' + sectionKey"  v-model="filterGroupState[sectionKey]">
        <ul v-if="section.optionsGroupings" class="filter-options-groupings">
          <li v-for="(sectionOptionsGroupings, sectionOptionsGroupsKey) in section.optionsGroupings" @click.prevent="sectionOptionsGroupingsClicked(sectionKey, sectionOptionsGroupsKey)">
            <b-form-checkbox
              :indeterminate="sectionOptionsGroupings.selectedStatus === 'partial'"
              :checked="sectionOptionsGroupings.selectedStatus === 'full'"
              v-bind:disabled="disableAll === true">
              {{sectionOptionsGroupings.name}}
            </b-form-checkbox>
          </li>
        </ul>
        <ul>
          <li v-for="(item, itemKey) in section.options">
            <b-form-checkbox :id="'checkbox_filter_1_'+section.section+'_'+item.key"
                             v-model="selectedFilters[section.section]"
                             :value="item.key"
                             v-bind:disabled="((item.count < 1) || (disableAll === true))"
                             @change="filterItemChanged(section.section, item.key)">
              <span v-html="replaceSpaceHyphenSpace(item.value + ' ' + '(' + item.count + ')')"></span>
            </b-form-checkbox>
          </li>
        </ul>
      </b-collapse>
    </div>
  </aside>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'SearchFilters',
  props: {
    filterItems: Array,
    disableAll: Boolean
  },

  watch: {
    filterItems: function (newVal, oldVal) { // watch it
      this.countSelectedFilters()
    }
  },

  components: {
    FontAwesomeIcon
  },

  mounted () {
    this.preSelectFilters()
    this.countSelectedFilters()
  },

  methods: {
    filterItemChanged (section, value) {
      this.$nextTick(() => {
        // we use nexttick as the selected filters update on the same tick as this change handler so not in updated state until nextTick
        if (section === 'bedrooms' || section === 'ensuite') {
          // add greater values
          const newCheckedState = this.selectedFilters[section].indexOf(value) > -1
          const newSelectedValueAsInt = parseInt(value, 10)
          if (newCheckedState) {
            // we're adding this option - also include greater ones.
            let i; let j; let filtersSection; let option; let optionAsInt
            for (i in this.filterItems) {
              filtersSection = this.filterItems[i]
              if (filtersSection.section === section) {
                // console.dir(filtersSection.options)
                for (j in filtersSection.options) {
                  option = filtersSection.options[j].key
                  optionAsInt = parseInt(option, 10)
                  if (optionAsInt > newSelectedValueAsInt) {
                    if (this.selectedFilters[section].indexOf(option) < 0) {
                      this.selectedFilters[section].push(option)
                    }
                  }
                }
                break
              }
            }
          }
        }
        this.$emit('filterApplied', this.selectedFilters)
        this.countSelectedFilters()
      })
    },

    sectionOptionsGroupingsClicked (sectionKey, sectionOptionsGroupsKey) {
      let nextValue
      switch (this.filterItems[sectionKey].optionsGroupings[sectionOptionsGroupsKey].selectedStatus) {
        case 'full':
          nextValue = 'none'
          break
        default:
          nextValue = 'full'
      }
      // update the checkbox status
      this.$set(this.filterItems[sectionKey].optionsGroupings[sectionOptionsGroupsKey], 'selectedStatus', nextValue)
      const filterItemsSectionInfo = this.filterItems[sectionKey]
      const optionsGroupings = filterItemsSectionInfo.optionsGroupings[sectionOptionsGroupsKey]
      const newKeysForThisSection = [] // create new array of allowable keys for this section
      switch (nextValue) {
        case 'none':
          // remove the keys from these active filters for this section
          this.selectedFilters[filterItemsSectionInfo.section].forEach((key) => {
            // if an existing key is not in the optionsGroupings key - it must belong to anouther group so re-add it in the array of keys for this section
            if (!optionsGroupings.keys.includes(key)) {
              newKeysForThisSection.push(key)
            }
          })
          break
        case 'full':
          // recreate the keys already existing for this section
          this.selectedFilters[filterItemsSectionInfo.section].forEach((key) => {
            newKeysForThisSection.push(key)
          })
          // add the keys for this group to the active filters for this section
          optionsGroupings.keys.forEach((key) => {
            // need to check they are not already in the array - as if indeterminate some will already be present
            if (!newKeysForThisSection.includes(key)) {
              newKeysForThisSection.push(key)
            }
          })
          break
      }
      this.selectedFilters[filterItemsSectionInfo.section] = newKeysForThisSection
      this.$nextTick(() => {
        this.$emit('filterApplied', this.selectedFilters)
        this.countSelectedFilters()
      })
    },

    preSelectFilters () {
      for (let i = 0; i < this.filterItems.length; i++) {
        const sectionName = this.filterItems[i].section
        this.selectedFilters[sectionName] = []

        for (let a = 0; a < this.filterItems[i].options.length; a++) {
          if (this.filterItems[i].options[a].applied) {
            this.selectedFilters[sectionName].push(this.filterItems[i].options[a].key)
          }
        }
      }
    },

    countSelectedFilters () {
      let count = 0
      for (const sectionHeading in this.selectedFilters) {
        count += this.selectedFilters[sectionHeading].length
      }
      this.selectedFilterCount = count
    },

    replaceSpaceHyphenSpace (input) {
      return input.replace(' - ', '&nbsp;-<span class="d-none d-lg-inline"><br/></span><span class="d-none-lg">&nbsp;</span>').replace(' (', '&nbsp;(')
    },

    clearFilters () {
      this.selectedFilters = {
        bedrooms: [],
        property: [],
        ensuite: [],
        accommodation: [],
        outdoorliving: [],
        communitydetails: [],
        community: []
      }
      this.$nextTick(() => {
        this.$emit('filterApplied', this.selectedFilters)
        this.countSelectedFilters()
      })
    }
  },

  computed: {

    showContent () {
      return faPlusSquare
    },

    hideContent () {
      return faMinusSquare
    }
  },

  data () {
    return {
      filterGroupState: {
        0: true // bedrooms open by default
      },

      selectedFilterCount: 0,

      selectedFilters: {
        bedrooms: [],
        property: [],
        ensuite: [],
        accommodation: [],
        outdoorliving: [],
        communitydetails: [],
        community: []
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/widgets/searchfilter.scss';
</style>
