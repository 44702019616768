<template>
  <div id="quick-search">
    <h2>Quick Search</h2>
    <b-form-group
      id="queryGroup"
      label="Quick Search"
      :label-sr-only="true">
      <b-input-group>
        <b-form-input
          autocomplete="off"
          id="query"
          v-model="searchQueryLocal"
          @keydown.enter.native="searchPressed"
          placeholder="Enter property code"
          type="text"
          :disabled="searchQueryActive"
          ></b-form-input>
          <b-input-group-append v-if="!searchQueryActive">
            <b-btn @click="searchPressed" :disabled="searchQueryLocal.length < 2" variant="primary">GO</b-btn>
          </b-input-group-append>
          <b-input-group-append v-else>
            <b-btn @click="clearSearch" variant="danger">Clear</b-btn>
          </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>

export default {
  name: 'quick-search',

  methods: {

    clearSearch () {
      this.$emit('searchQuerySelected', '')
    },

    searchPressed () {
      if (this.searchQueryLocal.length < 2) {
        return
      }
      this.$emit('searchQuerySelected', this.searchQueryLocal)
    }

  },

  props: {
    searchQuery: {
      type: String,
      required: true
    },
    searchQueryActive: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    searchQuery: {
      handler (newVal) {
        this.searchQueryLocal = newVal
      }
    }
  },

  mounted () {
    this.searchQueryLocal = this.searchQuery
  },

  data () {
    return {
      searchQueryLocal: ''
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  #quick-search {
    background: $light-green;
    color: #fff;
    padding: $grid-gutter-width / 2;
    margin-bottom: $grid-gutter-width / 2;
    h2 {
      font-family: $font-family-serif;
      font-size: 1.3rem;
      text-transform: uppercase;
      border-bottom: 1px solid #fff;
      margin-bottom: $grid-gutter-width / 2;
      padding-bottom: $grid-gutter-width / 2;
      display: block;
    }
  }
</style>
