<template>
  <div>
    <b-form-group
      id="leadPostcodeGroup"
      label="Postcode"
      label-for="leadPostcode"
      :state="postcodeLookupOk"
      :invalid-feedback="postcodeLookupErrorMessage">
      <b-input-group>
        <b-form-input
          autocomplete="off"
          id="leadPostcode"
          v-model="unvalidatedPostcode"
          type="text"
          @keydown.enter.native="postcodeLookupifenterkey"></b-form-input>

        <b-input-group-append>
          <b-btn @click="lookUpPostCode(unvalidatedPostcode)">
            Lookup address - UK only
          </b-btn>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <loader width="28px" height="28px" v-if="postcodeLoading"></loader>

    <b-form-group
      label="Select Address"
      v-if="postcodeData.options.length">
      <!--<b-form-select
        :options="postcodeData.options"
        v-model="postcodeData.selectedValue"></b-form-select>-->
        <v-select :options="postcodeData.options" v-model="postcodeData.selectedValue" placeholder="Choose address"></v-select>
    </b-form-group>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

export default {
  name: 'address-lookup',

  components: {
    loader
  },

  watch: {
    'postcodeData.selectedValue': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.addressSelected(newVal)
        }
      },
      deep: true
    }
  },

  methods: {
    postcodeLookupifenterkey (e) {
      e.preventDefault()
      this.lookUpPostCode(this.unvalidatedPostcode)
    },

    addressSelected (selectedAddress) {
      this.$emit('addressSelected', selectedAddress.value)
    },

    lookUpPostCode (postcode) {
      if (postcode !== '') {
        this.postcodeLoading = true
        this.postcodeLookupOk = true
        const postcodeApiUrl = '/postcode-lookup?q=' + postcode
        this.$apiCaller({ route: postcodeApiUrl, method: 'GET', data: '' }).then((response) => {
          const responseData = response.data.response
          if (responseData.hasOwnProperty('error')) {
            this.postcodeLookupOk = false
            this.postcodeLookupErrorMessage = responseData.error
          } else {
            if (responseData.hasOwnProperty('FullAddress')) {
              const postcodeOptions = []
              for (const i in responseData.FullAddress.Premises) {
                const addressObj = {
                  label: responseData.FullAddress.Premises[i].replace('/', ', '),
                  value: {
                    address1: responseData.FullAddress.Premises[i].replace('/', ', '),
                    address2: responseData.FullAddress.Address2,
                    addTown: responseData.FullAddress.Town,
                    addCounty: responseData.FullAddress.County,
                    addPostcode: responseData.FullAddress.Postcode
                  }
                }
                postcodeOptions.push(addressObj)
              }
              this.postcodeData.options = postcodeOptions
            }
          }
          this.postcodeLoading = false
        })
          .catch(() => {
            // set page errors
          })
          .finally(() => {
            // set loaded
          })
      }
    }
  },

  data () {
    return {
      postcodeLookupOk: true,
      postcodeLookupErrorMessage: '',
      unvalidatedPostcode: '',
      postcodeLoading: false,
      postcodeData: {
        selectedValue: null,
        options: []
      }
    }
  }
}
</script>

<style lang="scss">
.v-select .vs__dropdown-toggle {
  background: #ffffff;

  .vs__clear {
    display: none;
  }
}
</style>
