<template>
  <article class="search-result">
    <b-row v-if="title || reference">
      <b-col md="8">
        <h1 v-if="title" class="text-center text-md-left">
          {{ title }}<span v-if="subTitle">, </span><small v-if="subTitle" v-html="subTitle"></small>
        </h1>
      </b-col>
      <b-col v-if="reference" md="4">
        <div class="reference text-center text-md-right mb-2 mb-sm-0">
          Property: <strong>{{ reference }}</strong>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="description || descriptionPoints || icons">
      <b-col :md="this.imageColSizes.md" :lg="this.imageColSizes.lg" :xl="this.imageColSizes.xl">
        <div class="villa-image-area" v-if="resultURL">
          <div class="villa-image-and-highlight-container">
            <template v-if="image">
              <b-link :to="resultURL" v-if="resultURL">
                <b-img :src="image.imgUrl" :alt="image.title" fluid ></b-img>
              </b-link>
            </template>
            <template v-if="carouselImages">
              <div class="carousel-image-number"><span class="carousel-image-number-current">1</span> / {{carouselImages.length}}</div>
              <slick :options="carouselOptions" @afterChange="afterChange">
                <template v-if="resultURL">
                  <b-link :to="resultURL" v-for="(image, key) in carouselImages" :key="key">
                    <img :data-lazy="image.imgUrl" :alt="image.title" class="img-fluid"/>
                  </b-link>
                </template>
              </slick>
            </template>
            <div v-if="propertyHighlight" class="villa-highlight">
              <img :src="propertyHighlight.logoUrl" :alt="propertyHighlight.name"/>
              <p v-html="propertyHighlight.nameDisplay"></p>
              <div class="villa-highlight-triangle-area">
                <div class="villa-highlight-triangle"></div>
              </div>
            </div>
          </div>
        </div>
        <b-img v-if="!resultURL && image" :src="image.imgUrl" :alt="image.title" fluid></b-img>
      </b-col>
      <b-col :md="12-this.imageColSizes.md" :lg="12-this.imageColSizes.lg" :xl="12-this.imageColSizes.xl">
        <div style="height: 100%; flex-wrap: wrap;" class="mt-2 mt-md-0" v-bind:class="{'d-flex': variant === 'secondary', 'align-items-start': variant === 'secondary'}">
          <p class="mb-md-auto mb-2">
            {{ description }}
          </p>

          <ul v-if="descriptionPoints" class="description-points">
            <li v-for="(point, key) in descriptionPoints" :key="key">{{ point }}</li>
          </ul>

          <ul v-if="icons" class="icons" :class="{'my-4': !!!descriptionPoints}">
            <li v-for="(icon, key) in icons" :key="key">
              <b-img :src="require('@/assets/images/icons/'+ icon.icon + '.png')" :alt="icon.name" :title="icon.name"></b-img>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>

    <footer v-if="variant === 'secondary' && resultURL" class="secondary">
      <b-row v-if="resultURL">
        <b-col v-if="resultURL" class="d-flex align-items-center justify-content-center">
          <div class="button-container ml-md-auto">
            <b-button variant="primary" :to="resultURL">
              {{ buttonText }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </footer>

    <footer v-if="variant !== 'secondary' && (resultURL || price)">
      <b-row v-if="resultURL || price">
        <b-col v-if="price" class="d-flex align-items-center" xl="8">
          <div class="price-area" style="width: 100%;">
            <div v-if="price.type === 'perNight'">
              Price from <strong>&pound;{{ price.amount }}</strong> per night
            </div>

            <b-row v-if="price.type === 'total'">
              <b-col class="text-center text-md-left" :xl="showCommission?9:12" :md="showCommission?8:12">
                Total price:<br class="d-sm-none"/> <strong>{{ price.amount | currency }}</strong>
                <span class="deposit-area" v-if="deposit.available"> only <strong>{{ deposit.amount | currency }}</strong> deposit</span>
                <br/>
                <small>{{ 'global.priceDisclaimer' | translate }}</small>
                <template v-if="showPoolHeatingCost">
                  <br />
                  <small>Plus an additional cost of {{ poolHeatingCost | currency }} for pool heating if required for your stay.</small>
                </template>
              </b-col>
              <b-col v-if="showCommission" class="text-center text-md-right text-lg-center" xl="3" md="4">
                <div class="mt-2 mb-2 mt-md-0 mb-md-0">
                  Commission:<br class="d-none d-xl-block"/> <strong>{{ commission | currency }}</strong>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col v-if="resultURL" class="d-flex align-items-center" xl="4">
          <div class="button-container mt-2 mt-lg-0 mt-xl-0 ml-md-auto ml-lg-auto ml-xl-auto text-center text-md-right" style="width: 100%;">
            <b-button variant="primary" :to="resultURL">
              {{ buttonText }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </footer>
  </article>
</template>

<script>
import Slick from 'vue-slick'
export default {
  components: {
    Slick
  },
  name: 'SearchResult',
  props: {
    title: String,
    subTitle: String,
    reference: String,
    image: Object,
    carouselImages: Array,
    description: String,
    descriptionPoints: Array,
    icons: Array,
    price: Object,
    deposit: Object,
    commission: Number,
    buttonText: String,
    resultURL: String,
    variant: String,
    showCommission: Boolean,
    showPoolHeatingCost: Boolean,
    poolHeatingCost: Number,
    propertyHighlight: Object
  },
  mounted () {
    if (this.carouselImages) {
      this.imageColSizes.lg = 5
      this.imageColSizes.xl = 5
    }
  },
  data () {
    return {
      imageColSizes: {
        md: 6,
        lg: 4,
        xl: 4
      },
      carouselOptions: {
        lazyLoad: 'anticipated',
        // slide: '.imgOuter',
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0
      }
    }
  },
  methods: {
    afterChange (event, slick, currentSlide) {
      // update the carousel image number / total carousel images
      const villaImageAndHighlightContainer = event.target.parentElement
      const carouselImageNumber = villaImageAndHighlightContainer.querySelector('.carousel-image-number-current')
      carouselImageNumber.textContent = (currentSlide + 1)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';
@import '@/assets/styles/widgets/searchresult.scss';
</style>
