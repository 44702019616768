<template>
  <article class="page-content">
    <div id="page-errors">
      <h3>Unfortunately there are errors</h3>
      <ul>
        <li v-for="(error, errorKey) in pageErrors" :key="errorKey">
          {{error}}
        </li>
      </ul>
    </div>
  </article>
</template>

<script>
export default {
  name: 'pageErrors',
  props: {
    pageErrors: Array
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
  #page-errors{
    text-align: left;
    h3{
      font-family: $font-family-sans-serif;
      font-size:1.3em;
    }
    ul {
      li {
        color:red;
      }
    }
  }
</style>
