<template>
  <b-container v-if="loaded">
    <masthead image-url="Community-Header.jpg" title="Thank You!"></masthead>
    <article class="page-content">
      <h2>
        <template v-if="reserve">Reserve</template>
        Booking Summary
      </h2>

      <p>
        <strong>Customer Name:</strong> {{ customerName }}
      </p>
      <p>
        <strong>Villa Choices booking reference:</strong> {{ ref }}
      </p>
      <p v-if="agentRef">
        <strong>Your booking reference:</strong> {{ agentRef }}
      </p>
      <p v-if="agentNotes">
        <strong>Your booking notes:</strong> {{ agentNotes }}
      </p>
      <p>
        <strong>Booking Total:</strong> {{ total | currency }}
      </p>
      <p v-if="agentCommission && $store.getters.getAgentView">
        <strong>Commission:</strong> {{ agentCommission | currency }}
      </p>
      <template v-if="reserve">
        <p class="warning">This booking will be held on "Reserve" for 24 Hours, you can upgrade it to a full booking from the <b-link :to="this.bookingInfoPage">booking info page</b-link> which can be found in the "Customer Bookings" section within your admin area.</p>
      </template>
      <template v-else>
        <p v-if="deposit">
          <strong>Deposit Amount:</strong> {{ deposit | currency }}
        </p>
        <p v-if="balanceDue">
          <strong>Remaining balance Due Date:</strong> {{ balanceDue }}
        </p>
        <p class="warning">{{deposit_balance_name}} required within 24hrs, payments can be made from the <b-link :to="this.bookingInfoPage">booking info page</b-link> which can be found in the "Customer Bookings" section within your admin area.</p>
      </template>
    </article>
  </b-container>

  <div class="loader-outer" v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

const Masthead = () => ({
  component: import('@/components/widgets/masthead'),
  loading: loader
})

export default {
  name: 'Home',

  components: {
    Masthead,
    loader
  },

  mounted () {
    this.getCompletedBookingInfo()
  },

  methods: {
    getCompletedBookingInfo () {
      this.loaded = false
      const apiUrl = this.$route.fullPath
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        const responseData = response.data.response
        if (responseData.hasOwnProperty('ref')) {
          this.ref = responseData.ref
        }
        if (responseData.hasOwnProperty('agentRef')) {
          this.agentRef = responseData.agentRef
        }
        if (responseData.hasOwnProperty('agentNotes')) {
          this.agentNotes = responseData.agentNotes
        }
        if (responseData.hasOwnProperty('client')) {
          this.customerName = [responseData.client.clientTitle, responseData.client.clientForename, responseData.client.clientSurname].join(' ')
        }
        if (responseData.hasOwnProperty('total')) {
          this.total = responseData.total
        }
        if (responseData.hasOwnProperty('deposit')) {
          this.deposit = responseData.deposit
        }
        if (responseData.hasOwnProperty('balanceDue')) {
          this.balanceDue = responseData.balanceDue
        }
        if (responseData.hasOwnProperty('reserve')) {
          this.reserve = responseData.reserve
        }
        if (responseData.hasOwnProperty('reserveReleaseDate')) {
          this.reserveReleaseDate = responseData.reserveReleaseDate
        }
        if (responseData.hasOwnProperty('agentCommission')) {
          this.agentCommission = responseData.agentCommission
        }
        this.bookingInfoPage = '/admin/agent/bookings/' + this.ref
        this.deposit_balance_name = (this.deposit && responseData.hasOwnProperty('balanceDue')) ? 'Deposit' : 'Full Balance'
        this.loaded = true
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },
  data () {
    return {
      loaded: false,
      agentCommission: false,
      customerName: '',
      ref: '',
      agentRef: '',
      agentNotes: '',
      total: '',
      deposit: false,
      balanceDue: false,
      reserve: false,
      reserveReleaseDate: '',
      deposit_balance_name: 'Deposit'
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/checkout/checkoutCompleted.scss';
</style>
