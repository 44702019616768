<template>
  <b-container id="adminArea">
    <b-row>
      <b-col md="12" lg="12">
        <b-nav justified pills class="mb-2" v-if="this.$store.getters.userRole == 'AGENT' && !this.$route.fullPath.includes('signup')">
          <b-nav-item to="/admin/agent/dashboard">Dashboard</b-nav-item>
          <b-nav-item to="/admin/agent/bookings">Customer Bookings</b-nav-item>
          <b-nav-item to="/admin/agent/quotes">Sent Quotes</b-nav-item>
          <b-nav-item to="/admin/agent/account">Your Account</b-nav-item>
          <b-nav-item to="/admin/agent/support">Help &amp; Support</b-nav-item>
          <b-nav-item to="/admin/agent/guides">Guides</b-nav-item>
        </b-nav>
        <b-nav justified pills class="mb-2" v-if="this.$store.getters.userRole == 'ACCOUNTS'">
          <b-nav-item to="/admin/accounts/dashboard">Dashboard</b-nav-item>
        </b-nav>
        <b-nav justified pills class="mb-2" v-if="this.$store.getters.userRole == 'ORGANISATION'">
          <b-nav-item to="/admin/organisation/dashboard">Dashboard</b-nav-item>
        </b-nav>
      </b-col>
      <b-col md="12" lg="12">
        <router-view></router-view>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'AdminArea'
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/admin/Admin.scss';
</style>
