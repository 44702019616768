<template>
  <div>
    <aside class="pricing-info">
      <div class="total-price">
        <template v-if="items.topPrices">
          <b-row v-for="(item) in items.topPrices" :key="item.name">
            <b-col cols="5">
              <strong>{{ item.name }}</strong>
            </b-col>
            <b-col cols="7" class="text-right">
              <strong class="price">
                {{ item.amount | currency }}
              </strong>
            </b-col>
          </b-row>
        </template>

        <b-row v-if="showCommission && items.hasOwnProperty('agentCommmission')">
          <b-col cols="5">
            <strong>Commission:</strong>
          </b-col>
          <b-col cols="7" class="text-right">
            <strong class="price">
              {{ items.agentCommmission | currency }}
            </strong>
          </b-col>
        </b-row>
      </div>

      <div class="breakdown">
        <template v-if="items.bottomPrices && items.bottomPrices.length > 0">
          <b-row v-for="(item, key) in items.bottomPrices" :key="key">
            <b-col cols="8">
              <strong>{{ item.name }}</strong>
            </b-col>
            <b-col cols="4" class="text-right">
              <strong class="price">
                {{ item.amount | currency }}
              </strong>
            </b-col>
          </b-row>
        </template>
      </div>

      <div class="pricing-disclaimer" v-if="items.strapline">
        {{ items.strapline }}
      </div>

      <ul class="check-in-out" v-if="items.dates">
        <li v-for="(item, key) in items.dates" :key="item.name">
          {{ item.name }} <strong>{{ item.date }}</strong>
        </li>
      </ul>
      <b-row v-if="items.bookNowURL || items.pdfButtons">
        <b-col cols="12" class="text-right text-lg-center text-xl-right" v-for="(pdfButton, key) in items.pdfButtons" :key="key">
          <b-button variant="primary" class="button mb-2 w-100" @click="pdfButtonClick(pdfButton.type)">{{pdfButton.name}}</b-button>
        </b-col>
        <b-col cols="12" class="text-center" v-if="items.bookNowURL">
          <b-button variant="primary" class="book button w-100" :to="items.bookNowURL">Next Step</b-button>
        </b-col>
      </b-row>
    </aside>

  </div>
</template>

<script>
export default {
  name: 'PriceBreakdown',

  props: {
    items: Object, // top prices, bottom prices, strapline, dates
    showCommission: Boolean
  },

  methods: {
    pdfButtonClick (type) {
      this.$emit('pdfButtonClicked', type)
    }
  },

  data () {
    return {}
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/widgets/pricebreakdown.scss';
</style>
