<template>
  <div v-if="!loading">
    <address-lookup @addressSelected="addressSelected"></address-lookup>

    <p><strong>Or enter it yourself, manually:</strong></p>

    <b-form-group
      label="Address line 1"
      label-for="address1"
      label-class="required">
      <b-form-input
        autocomplete="off"
        @input="updateReturnValues"
        @change="updateReturnValues"
        id="address1"
        name="address1"
        v-validate="{required: true, min:5, max:50}"
        data-vv-as="address line 1"
        :state="validationState('address1')"
        maxlength="50"
        type="text"
        v-model="address.address1"></b-form-input>
      <b-form-invalid-feedback>
        {{ errors.first('address1') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="Address line 2"
      label-for="address2">
      <b-form-input
        autocomplete="off"
        @input="updateReturnValues"
        @change="updateReturnValues"
        id="address2"
        name="address2"
        v-validate="{max:50}"
        data-vv-as="address line 2"
        :state="validationState('address2')"
        maxlength="50"
        type="text"
        v-model="address.address2"></b-form-input>
      <b-form-invalid-feedback>
        {{ errors.first('address2') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="Town"
      label-for="addTown"
      label-class="required">
      <b-form-input
        autocomplete="off"
        @input="updateReturnValues"
        @change="updateReturnValues"
        id="addTown"
        name="addTown"
        v-validate="{required: true, min:3, max:50}"
        data-vv-as="town"
        :state="validationState('addTown')"
        maxlength="50"
        type="text"
        v-model="address.addTown"></b-form-input>
      <b-form-invalid-feedback>
        {{ errors.first('addTown') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="County"
      label-for="addCounty">
      <b-form-input
        autocomplete="off"
        @input="updateReturnValues"
        @change="updateReturnValues"
        id="addCounty"
        name="addCounty"
        v-validate="{max:50}"
        data-vv-as="county"
        :state="validationState('addCounty')"
        maxlength="50"
        type="text"
        v-model="address.addCounty"></b-form-input>
      <b-form-invalid-feedback>
        {{ errors.first('addCounty') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="Country"
      label-for="leadCountry"
      label-class="required">
      <b-form-select
        autocomplete="off"
        @input="updateReturnValues"
        @change="updateReturnValues"
        id="addCountryId"
        name="addCountryId"
        v-validate="{required: true}"
        data-vv-as="country"
        :state="validationState('addCountryId')"
        :options="countryValues"
        v-model="address.addCountryId"></b-form-select>
      <b-form-invalid-feedback>
        {{ errors.first('addCountryId') }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="Postcode"
      label-for="addPostcode"
      label-class="required">
      <b-form-input
        autocomplete="off"
        @input="updateReturnValues"
        @change="updateReturnValues"
        id="addPostcode"
        name="addPostcode"
        v-validate="{required: true, min:5}"
        data-vv-as="postcode"
        :state="validationState('addPostcode')"
        v-model="address.addPostcode"></b-form-input>
      <b-form-invalid-feedback>
        {{ errors.first('addPostcode') }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
  <div v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

export default {
  name: 'address-fields',

  props: {
    formData: {
      type: Object,
      required: false
    }
  },

  components: {
    loader
  },

  mounted () {
    this.loadCountryValues().then(() => {
      if (this.formData) {
        for (const key in this.formData) {
          this.address[key] = this.formData[key]
        }
        this.$validator.validate()
      }
    })
  },

  methods: {
    validator () {
      return this.$validator
    },
    getCountryValues () {
      return this.countryValues
    },
    loadCountryValues () {
      this.loading = true
      return new Promise((resolve) => {
        const apiUrl = '/country-values'
        this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
          const responseData = response.data.response
          this.countryValues = responseData.country
          this.countryValues.unshift({ value: null, text: 'Please select an option' })
          this.loading = false
          resolve()
        })
          .catch(() => {
            // set page errors
          })
          .finally(() => {
            // set loaded
          })
      })
    },

    addressSelected (selectedAddress) {
      this.address = {
        address1: selectedAddress.address1,
        address2: selectedAddress.address2,
        addTown: selectedAddress.addTown,
        addCounty: selectedAddress.addCounty,
        addCountryId: 82, // UK hardcoded as we can only use uk postcodes
        addPostcode: selectedAddress.addPostcode
      }
      this.updateReturnValues()
    },

    updateReturnValues () {
      for (const key in this.address) {
        if (this.address.hasOwnProperty(key)) {
          this.output.data[key] = this.address[key]
        }
      }
      this.$emit('updated', this.output)
    },

    validationState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    }
  },

  data () {
    return {
      loading: false,
      countryValues: {},

      address: {
        address1: '',
        address2: '',
        addTown: '',
        addCounty: '',
        addCountryId: '',
        addPostcode: ''
      },

      output: {
        data: {
          address1: '',
          address2: '',
          addTown: '',
          addCounty: '',
          addCountryId: '',
          addPostcode: ''
        }
      }
    }
  }
}
</script>
