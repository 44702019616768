<template>
  <div id="help-support">
    <b-row>
      <b-col>
        <h1>Help &amp; Support</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <img src="@/assets/images/admin/help-support/Help-and-support.jpg"  style="max-width: 100%; max-height: 100%;" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <section class='main'>
          <p>We hope this website is intuitive enough for you to use without guidance or a training manual, but if you have any questions please do contact us.</p>
          <p>If you would like us to add other features or services to this website then please feel free to drop us a line.</p>
          <h2>Contact us</h2>
          <hr />
          <span class="contact-info">
            <font-awesome-icon :icon="telephoneIcon"></font-awesome-icon><strong>Phone</strong> {{ $t('global.vcPhoneNumber') }}<br/>
            <font-awesome-icon :icon="emailIcon"></font-awesome-icon><strong>Email</strong> <b-link :href="'mailto:' + $t('global.vcEmail')">{{ $t('global.vcEmail') }}</b-link><br/>
            <font-awesome-icon :icon="facebookIcon"></font-awesome-icon><strong>Facebook Group</strong> <b-link href="https://www.facebook.com/groups/villachoices/" target="_blank">https://www.facebook.com/groups/villachoices/</b-link>
          </span>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPhoneAlt, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

export default {
  name: 'Help',

  components: {
    FontAwesomeIcon
  },

  methods: {

  },

  computed: {
    telephoneIcon () {
      return faPhoneAlt
    },
    emailIcon () {
      return faEnvelopeOpen
    },
    facebookIcon () {
      return faFacebook
    }
  },

  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/admin/Help.scss';
</style>
