import axios from 'axios'
import { Service } from 'axios-middleware'
// https://emileber.github.io/axios-middleware/#/api/methods

const apiCaller = {
  // apiSettings = {baseUrl: '', hooks: {}}
  install (Vue, apiSettings = {}) {
    if (apiSettings.hasOwnProperty('hooks')) {
      const service = new Service(axios)

      const hookMethods = {}

      if (apiSettings.hasOwnProperty('hooks')) {
        for (const key in apiSettings.hooks) {
          if (apiSettings.hooks.hasOwnProperty(key)) {
            hookMethods[key] = apiSettings.hooks[key]
          }
        }
      }
      service.register(hookMethods)
    }
    // requestSettings = {method: '', route: '', data: '', params: {}}
    Vue.prototype.$apiCaller = (requestSettings = {}) => {
      let urlString = ''
      if (apiSettings.hasOwnProperty('baseUrl')) {
        urlString += apiSettings.baseUrl
      }

      if (requestSettings.hasOwnProperty('route')) {
        urlString += requestSettings.route
      }

      if (!requestSettings.hasOwnProperty('data')) {
        requestSettings.data = null
      }

      if (!requestSettings.hasOwnProperty('params')) {
        requestSettings.params = null
      }

      if (!requestSettings.hasOwnProperty('method')) {
        requestSettings.method = 'GET'
      }

      const axiosOptions = {
        url: urlString,
        data: requestSettings.data,
        params: requestSettings.params,
        method: requestSettings.method,
        displayErrorAlert: true
      }

      if (requestSettings.hasOwnProperty('displayErrorAlert')) {
        axiosOptions.displayErrorAlert = requestSettings.displayErrorAlert
      }

      if (requestSettings.hasOwnProperty('responseType')) {
        axiosOptions.responseType = requestSettings.responseType
      }

      return axios(axiosOptions) // this is a promise
    }
  }
}

export default apiCaller
