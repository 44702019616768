import {
  LOG_IN_SUCCESS,
  SET_WAS_AUTHENTICATED,
  SET_IS_AUTHENTICATED,
  SET_IS_IMPERSONATING,
  LOG_OUT,
  SET_SESSION_TIMED_OUT
} from '@/store/actions/authnew'

import {
  AGENT_VIEW_OFF
} from '@/store/actions/agent'

import router from '@/router'

const state = {
  isAuthenticated: false,
  isImpersonating: !!localStorage.getItem('isImpersonating'),
  sessionTimedOut: false,
  wasAuthenticated: false
}

const getters = {
  isAuthenticated: (state) => !!state.isAuthenticated,
  isImpersonating: (state) => !!state.isImpersonating,
  wasAuthenticated: (state) => !!state.wasAuthenticated,
  sessionTimedOut: (state) => !!state.sessionTimedOut
}

const actions = {
  [LOG_IN_SUCCESS]: ({ commit }) => {
    commit(SET_IS_AUTHENTICATED, true)
    commit(SET_WAS_AUTHENTICATED, true)
  },
  [LOG_OUT]: ({ commit }, { timedOut }) => {
    commit(SET_SESSION_TIMED_OUT, (!!timedOut))
    commit(SET_IS_AUTHENTICATED, false)
    commit(SET_IS_IMPERSONATING, {
      isImpersonating: false,
      userAdminId: null
    })
    commit(AGENT_VIEW_OFF)
    localStorage.clear()
    router.push('/login')
  },
  [SET_IS_IMPERSONATING]: ({ commit }, { isImpersonating, userAdminId = null }) => {
    commit(SET_IS_IMPERSONATING, {
      isImpersonating,
      userAdminId
    })
  }
}

const mutations = {
  [SET_IS_AUTHENTICATED]: (state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated
  },
  [SET_WAS_AUTHENTICATED]: (state, wasAuthenticated) => {
    state.wasAuthenticated = wasAuthenticated
  },
  [SET_IS_IMPERSONATING]: (state, { isImpersonating, userAdminId = null }) => {
    state.isImpersonating = isImpersonating

    localStorage.setItem('isImpersonating', isImpersonating)

    if (userAdminId === null) {
      localStorage.removeItem('userAdminId')
    } else {
      localStorage.setItem('userAdminId', userAdminId)
    }
  },
  [SET_SESSION_TIMED_OUT]: (state, timedOut) => {
    state.sessionTimedOut = timedOut
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
