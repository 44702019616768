<template>
  <div id="google-map-container">
    <div id="mapControlsContainer" :class="{left: !menuVisible}">
      <b-button @click="toggleMenu" variant="info" class="panel-toggle-button">
        <template v-if="menuVisible">&lt;</template>
        <template v-if="!menuVisible">&gt;</template>
      </b-button>
      <map-controls @markerVisibilityChanged="markerVisibilityChanged" :mapMarkerClicked="clickedMapMarker" @itemClicked="navItemClicked" @itemHover="navItemHover" v-if="markers" :places="markers"></map-controls>
    </div>
    <google-map ref="googleMap" :placeHover="hoveredNavItem" :placeClick="clickedNavItem" @mapClick="mapClick" @markerClick="markerClicked" :markers="markers"></google-map>
  </div>
</template>

<script>
import googleMap from './_googleMap.vue'
import mapControls from './_mapControls.vue'

export default {
  name: 'dv-google-map',

  props: {
    markers: {
      type: Object,
      required: false
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.windowWidth = window.innerWidth

      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  },

  watch: {
    windowWidth: {
      handler: function (newVal) {
        if (this.menuStateSetByUser === false) {
          if (newVal < 992) {
            this.menuVisible = false
          } else {
            this.menuVisible = true
          }
        }
      }
    }
  },

  components: {
    'google-map': googleMap,
    'map-controls': mapControls
  },

  methods: {
    markerClicked (event) {
      this.clickedMapMarker = event
    },

    mapClick () {
      this.markerClicked(null)
    },

    navItemClicked (event) {
      this.clickedNavItem = event

      if (this.windowWidth <= 576) {
        if (this.menuVisible === true) {
          this.menuVisible = false
        }
      }
    },

    navItemHover (event) {
      this.hoveredNavItem = event
    },

    markerVisibilityChanged (event) {
      this.$refs.googleMap.updateMarkerState(event)
    },

    toggleMenu () {
      if (this.menuStateSetByUser === false) {
        this.menuStateSetByUser = true
      }

      if (this.menuVisible === true) {
        this.menuVisible = false
      } else {
        this.menuVisible = true
      }
    }
  },

  data () {
    return {
      clickedMapMarker: null,
      clickedNavItem: null,
      hoveredNavItem: null,
      markerStates: null,
      windowWidth: 0,
      menuVisible: true,
      menuStateSetByUser: false
    }
  }
}
</script>

<style lang="scss">
  #google-map-container {
    position: relative;
    overflow: hidden;

    #mapControlsContainer {
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 2;
      transform: translate(0, 0);
      transition: transform 0.75s ease;

      &.left {
        transform: translate(-100%, 0);

        .panel-toggle-button {
          right: 0;
        }
      }

      .panel-toggle-button {
        position: absolute;
        right: 10px;
        transform: translate(100%, 0);
        transition: right 0.3s ease;
        padding-top: 22px;
        padding-bottom: 22px;
      }
    }
  }
</style>
