<template>
  <b-container id="bookingDetails">
    <b-modal id="payment-declined-modal" title="Payment Declined" centered ok-only header-bg-variant="danger" header-text-variant="light">
      <p class="my-4">The payment has been declined - check the payments area for further information.</p>
    </b-modal>
    <div v-if="loaded && booking.bookingInfo.bookRef">
      <div class="customer-overview">
        <b-row>
          <b-col>
            <h1>
              Villa Choices {{ booking.bookingInfo.bookRef }} / Booking status {{booking.bookingInfo.bookStatusCode}}
            </h1>
            <b-row>
              <b-col md="8">
                <strong class="sub-title">View / Edit {{ bookingTerminology }} information</strong>
                <p>These are the details we hold for {{ bookingTerminology }} {{ booking.bookingInfo.bookRef }}.</p>
              </b-col>
              <b-col md="4">
                <div class="text-right">
                  <b-button v-b-modal.amendBookingDetails variant="primary">Make enquiry</b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <article class="page-content">
        <section>
          <h2>Overview</h2>
          <h3>Property Info</h3>
          <table class="table b-table table-striped table-sm text-center">
            <thead>
              <tr>
                <th scope="col">Property Code</th>
                <th scope="col">Property Type</th>
                <th scope="col">Bedrooms</th>
                <th scope="col">Community</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{booking.propertyInfo.villaCode}}</td>
                <td>{{booking.propertyInfo.propertyTypeName}}</td>
                <td>{{booking.propertyInfo.bedrooms}}</td>
                <td>{{booking.propertyInfo.communityName}}</td>
              </tr>
            </tbody>
          </table>

          <h3>Length of Stay</h3>
          <table class="table b-table table-striped table-sm text-center">
            <thead>
              <tr>
                <th scope="col">Checkin Date and time</th>
                <th scope="col">Nights</th>
                <th scope="col">Checkout Date and time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{$moment(booking.propertyBookingInfo.arrival+' '+booking.propertyBookingInfo.checkInTime, 'YYYY-MM-DD HH:mm:ss').format(fullDateAndTimeMomentFormat)}}</td>
                <td>{{booking.propertyBookingInfo.nights}}</td>
                <td>{{$moment(booking.propertyBookingInfo.departure+' '+booking.propertyBookingInfo.checkOutTime, 'YYYY-MM-DD HH:mm:ss').format(fullDateAndTimeMomentFormat)}}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </article>

      <article class="page-content" v-if="!isQuotation">
        <section>
          <h2>Lead Passenger</h2>
          <template v-if="!editLeadPassenger && !leadPassengerLoading">
            <h3>Passenger Name</h3>
            <table class="table b-table table-striped table-sm text-center">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Forename</th>
                  <th scope="col">Surname</th>
                  <template v-if="booking.leadPassenger.dob">
                    <th scope="col">Date of Birth</th>
                  </template>
                  <template v-if="booking.leadPassenger.isDriving">
                    <th scope="col">Is Driving</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ booking.leadPassenger.clientTitle }}</td>
                  <td>{{ booking.leadPassenger.clientForename }}</td>
                  <td>{{ booking.leadPassenger.clientSurname }}</td>
                  <template v-if="booking.leadPassenger.dob">
                    <td>{{ $moment(booking.leadPassenger.dob, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</td>
                  </template>
                  <template v-if="booking.leadPassenger.isDriving">
                    <td>{{ booking.leadPassenger.isDriving ? 'Yes' : 'No' }}</td>
                  </template>
                </tr>
              </tbody>
            </table>

            <h3>Passenger Address</h3>
            <table class="table b-table table-striped table-sm text-center">
              <thead>
                <tr>
                  <th scope="col">Address 1</th>
                  <th scope="col">Address 2</th>
                  <th scope="col">Town</th>
                  <th scope="col">County</th>
                  <th scope="col">Postcode</th>
                  <th scope="col">Country</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ booking.leadPassenger.address1 }}</td>
                  <td>{{ booking.leadPassenger.address2 }}</td>
                  <td>{{ booking.leadPassenger.addTown }}</td>
                  <td>{{ booking.leadPassenger.addCounty }}</td>
                  <td>{{ booking.leadPassenger.addPostcode }}</td>
                  <td>{{ booking.leadPassenger.countryName }}</td>
                </tr>
              </tbody>
            </table>

            <h3>Contact Info</h3>
            <table class="table b-table table-striped table-sm text-center">
              <thead>
                <tr>
                  <th scope="col">Telephone Number</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Email Address</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ booking.leadPassenger.clientTelephone }}</td>
                  <td>{{ booking.leadPassenger.clientMobile }}</td>
                  <td>{{ booking.leadPassenger.clientEmail }}</td>
                </tr>
              </tbody>
            </table>
          </template>

          <template v-if="editLeadPassenger && !leadPassengerLoading">
            <p>
              Lead passenger must be aged 18 years or over, at time of booking.
            </p>

            <h3>Passenger Name</h3>
            <passenger-name ref="passengerName" :formData="{
              title: booking.leadPassenger.clientTitle,
              forename: booking.leadPassenger.clientForename,
              surname: booking.leadPassenger.clientSurname}"
              @updated="passengerNameUpdated"
              :excludeFields="['clientCompName']"></passenger-name>

            <b-row>
              <b-col lg="6">
                <section class="passenger-address">
                  <h3>Passenger Address</h3>

                  <passenger-address ref="passengerAddress" :formData="{
                    address1: booking.leadPassenger.address1,
                    address2: booking.leadPassenger.address2,
                    addTown: booking.leadPassenger.addTown,
                    addCounty: booking.leadPassenger.addCounty,
                    addPostcode: booking.leadPassenger.addPostcode,
                    addCountryId: booking.leadPassenger.addCountryId}"
                    @updated="passengerAddressUpdated"></passenger-address>
                </section>
              </b-col>

              <b-col lg="6">
                <b-row>
                  <b-col cols="12" class="mb-4">
                    <section class="contact-info">
                      <h3>Contact Info</h3>
                      <passenger-contact-info ref="passengerContactInfo" :formData="{
                        clientTelephone: booking.leadPassenger.clientTelephone,
                        clientMobile: booking.leadPassenger.clientMobile,
                        clientEmail: booking.leadPassenger.clientEmail}"
                        @updated="passengerContactInfoUpdated"></passenger-contact-info>
                    </section>
                  </b-col>
                  <b-col cols="12">
                    <section class="contact-info">
                      <h3>Additional Details</h3>
                      <passenger-additional-details
                        @updated="passengerAdditionalDetailsUpdated"
                        ref="passengerAdditionalDetails"
                        :formData="{
                          title: booking.leadPassenger.clientTitle,
                          forename: booking.leadPassenger.clientForename,
                          surname: booking.leadPassenger.clientSurname,
                          dob: booking.leadPassenger.dob,
                          isDriving: booking.leadPassenger.isDriving,
                          passengerType: 'Adult'
                        }"
                        :validateDob="!!booking.leadPassenger.dob"
                      >
                      </passenger-additional-details>
                    </section>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>

          <loader v-if="leadPassengerLoading" width="100px" height="100px"></loader>
          <template v-else>
            <hr/>
            <div class="mt-3 text-right">
              <b-button @click="leadPassengerCheckAndSaveChanges"
                v-if="editLeadPassenger"
                variant="primary"
                >Save</b-button>

              <b-button @click="toggleEditLeadPassenger" :variant="!editLeadPassenger ? 'primary' : ''">
                <template v-if="!editLeadPassenger">Edit</template>
                <template v-if="editLeadPassenger">Cancel</template>
              </b-button>
              <p v-if="formLeadPassengerValidationErrors === true">Please fix the form errors before submitting this form</p>
            </div>
          </template>
        </section>
      </article>

      <article class="page-content" v-if="!isQuotation">
        <section>
          <h2>Additional Passengers</h2>
          <passenger-list v-show="!additionalPassengersLoading" ref="additionalPassengersList" :editMode="editPassengerList" :maxOccupancy="booking.propertyInfo.maxOccupancy" :leadPassenger="{forename: booking.leadPassenger.clientForename, surname: booking.leadPassenger.clientSurname, passengerType: 'Adult', dob: booking.leadPassenger.dob, isDriving: booking.leadPassenger.isDriving}" :additionalPassengers.sync="additionalPassengers" :arrivalDate="booking.propertyBookingInfo.arrival"></passenger-list>

          <loader v-show="additionalPassengersLoading" width="100px" height="100px"></loader>

          <hr/>

          <div class="mt-3 text-right">
            <b-button @click="saveAdditionalPassengerChanges" v-if="editPassengerList" variant="primary">Save</b-button>
            <b-button @click="toggleEditPassengerList" :variant="!editPassengerList ? 'primary' : ''">
              <template v-if="!editPassengerList">Edit</template>
              <template v-if="editPassengerList">Cancel</template>
            </b-button>
          </div>
        </section>
      </article>

      <article class="page-content">
        <section>
          <h2>Customer Displayed Breakdown</h2>
          <table class="table b-table table-striped table-sm">
            <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(breakdownItem, index) in booking.customerBreakdown" :key="index">
                <th scope="row">{{ breakdownItem.name }}</th>
                <td>{{ breakdownItem.amount | currency }}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </article>

      <article class="page-content">
        <section>
          <h2>Agent Commission and Outstanding Balance</h2>
          <table class="table b-table table-striped table-sm">
            <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Booking total <small>(commissionable)</small></th>
                <td>{{ booking.agentSummary.agentTotalCommissionable | currency }}</td>
              </tr>
              <tr>
                <th scope="row">Booking total <small>(non commissionable)</small></th>
                <td>{{ booking.agentSummary.agentTotalNonCommissionable | currency }}</td>
              </tr>
              <tr>
                <th scope="row">Commission at {{(booking.agentSummary.commissionRate*100).toFixed(2)}}%</th>
                <td>{{ 0-booking.agentSummary.commissionAmount | currency }}</td>
              </tr>
              <tr v-if="booking.agentSummary.hasOwnProperty('vatRate') && booking.agentSummary.hasOwnProperty('vatAmount')">
                <th scope="row">VAT at {{(booking.agentSummary.vatRate*100)}}%</th>
                <td>{{ 0-booking.agentSummary.vatAmount | currency }}</td>
              </tr>
              <tr>
                <th scope="row">Payments received</th>
                <td>{{ 0-booking.agentSummary.paymentsReceived | currency }}</td>
              </tr>
              <tr>
                <th scope="row">Outstanding balance</th>
                <td>{{ booking.agentSummary.outstandingBalance | currency }}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </article>

      <article class="page-content" v-if="hasPaymentsSection">
        <section>
          <h2>Payments</h2>
          <template v-if="booking.payments.length">
            <table class="table b-table table-striped table-sm">
              <thead>
                <tr>
                  <th scope="col">Payment Type</th>
                  <th scope="col">Payment Referece</th>
                  <th scope="col">Payment Date / Time</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(paymentInfo, index) in booking.payments" :key="index">
                  <td>{{ paymentInfo.payTypeDesc }}</td>
                  <td>{{ paymentInfo.payReference }}</td>
                  <td>{{$moment(paymentInfo.payDate, 'YYYY-MM-DD HH:mm:ss').format(fullDateAndTimeMomentFormat)}}</td>
                  <td>{{ paymentInfo.payAmount | currency }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <p>No payments have been received</p>
          </template>
          <h2>Make a payment</h2>
          <b-row>
            <b-col v-if="booking.depositInfo.available && !booking.payments.length">
              <h3>Deposit {{booking.depositInfo.amount | currency}} <small>Due now</small></h3>
            </b-col>
            <b-col>
              <h3>Total Outstanding: {{booking.agentSummary.outstandingBalance | currency}} <small v-if="booking.depositInfo.available">Payable {{$moment(booking.depositInfo.balanceDue,'YYYY-MM-DD').format('MMM D, YYYY')}}</small><small v-else>Due now</small></h3>
            </b-col>
          </b-row>
          <template v-if="booking.agentSummary.outstandingBalance > 0">
            <hr />
            <p>If you wish to pay by bank transfer, our bank details are as follows (Please quote ref {{ booking.bookingInfo.bookRef }}):
              <br />Account name: {{ $t('bankAccount.name') }},  sort code: {{ $t('bankAccount.sort') }}, account number: {{ $t('bankAccount.number') }}
            </p>
            <hr />
            <worldpay-payment :amountMin="(cardPayments.min)" :amountMax="cardPayments.max" :bookingReference="booking.bookingInfo.bookRef" :paymentToken="booking.paymentToken"></worldpay-payment>
          </template>
        </section>
      </article>

      <article class="page-content">
        <section v-if="!editOptionalExtrasLoading">
          <h2>Optional Extras</h2>

          <h3>Purchased Extras</h3>
          <p v-if="booking.purchasedExtras.length < 1">
            You do not have any additional extras assigned to this booking.
          </p>
          <table class="table b-table table-striped table-sm" v-else>
            <thead>
              <tr>
                <th scope="col">Extra Name</th>
                <th scope="col">Booked From</th>
                <th scope="col">Nights Booked</th>
                <th scope="col">Cost</th>
                <th scope="col" v-show="editOptionalExtras">Remove Extra</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(extra, index) in booking.purchasedExtras">
                <tr v-for="(purchasedExtra, purchaseIndex) in extra.purchased" :key="'e_' + index + '_p_' + purchaseIndex">
                  <td>
                    {{ extra.info.extraName }}
                  </td>
                  <td>
                    <template v-if="extra.info.datesEditable == 1">
                      {{ $moment(purchasedExtra.bookFrom, 'YYYY-MM-DD').format(fullDateFormat) }}
                    </template>
                    <template v-else>
                      N/A
                    </template>
                  </td>
                  <td>
                    <template v-if="extra.info.datesEditable == 1">
                      {{ purchasedExtra.bookNights }}
                    </template>
                    <template v-else>
                      N/A
                    </template>
                  </td>
                  <td>
                    {{ purchasedExtra.bookChargeAmount | currency }}
                  </td>
                  <td v-show="editOptionalExtras">
                    <b-button size="sm" block variant="primary" v-confirm="{
                      ok: dialog => optionalExtraRemove(dialog, purchasedExtra),
                      message: 'Do you wish to remove the following extra: ' + extra.info.extraName + ' reducing the cost by ' + $options.filters.currency(purchasedExtra.bookChargeAmount) + '?',
                      okText: 'Yes',
                      cancelText: 'No'
                    }">Remove Extra</b-button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <br />
          <template v-if="booking.purchaseAbleExtras.length > 0">
            <h3>Available Extras</h3>
            <table class="table b-table table-striped table-sm">
              <thead>
                <tr>
                  <th scope="col">Extra Name</th>
                  <th scope="col">Cost</th>
                  <th scope="col" v-show="editOptionalExtras">Add Extra</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(extra, index) in booking.purchaseAbleExtras" :key="'pe_' + index" v-if="!optionalExtrasMaxQuantitiesReachedForExtraIds.includes(extra.extraId)">
                  <td>
                    {{ extra.extraName }}
                  </td>
                  <td>
                    <template v-if="extra.extraId !== '12'">
                      {{ extra.extraPrice | currency }}
                    </template>
                  </td>
                  <td v-show="editOptionalExtras">
                    <template v-if="extra.extraId === '12'">
                      Please contact us for a quote<br />and to add pool heating
                    </template>
                    <template v-else>
                      <b-button size="sm" block variant="primary" v-confirm="{
                        ok: dialog => optionalExtraAdd(dialog, extra),
                        message: 'Do you wish to add the following extra: ' + extra.extraName + ' at a cost of ' + $options.filters.currency(extra.extraPrice) + '?',
                        okText: 'Yes',
                        cancelText: 'No'
                      }">Add Extra</b-button>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <hr/>
          <div class="mt-3 text-right" v-if="!bookingLocked">
            <b-button @click="toggleEditOptionalExtras" :variant="!editOptionalExtras ? 'primary' : ''">
              <template v-if="!editOptionalExtras">Edit</template>
              <template v-if="editOptionalExtras">Cancel</template>
            </b-button>
          </div>
        </section>
        <loader v-else width="100px" height="100px"></loader>
      </article>

      <article class="page-content" v-if="booking.purchasedCustomExtras.length > 0">
        <section>
          <h2>Other Extras</h2>
          <h3>Purchased Custom Optional Extras</h3>
          <table class="table b-table table-striped table-sm">
            <thead>
              <tr>
                <th scope="col">Extra Name</th>
                <th scope="col">Extra Description</th>
                <th scope="col">Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(purchasedCustomExtra, purchaseIndex) in booking.purchasedCustomExtras" :key="'ce_' + index + '_p_' + purchaseIndex">
                <td>
                  {{ purchasedCustomExtra.customExtraName }}
                </td>
                <td>
                  {{ purchasedCustomExtra.customExtraDescription }}
                </td>
                <td>
                  {{ purchasedCustomExtra.bookChargeAmount | currency }}
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text-right">Please contact us to change or modify any of the above</p>
        </section>
      </article>

      <article class="page-content" v-if="!isQuotation">
        <section v-if="!agentsAdditionalDetailsLoading">
          <h2>Your Additional Information</h2>
          <b-alert variant="warning" :show="agentsAdditionalDetailsEditMode">
            Information you place in here may be viewed by Villa Choices.
          </b-alert>
          <b-form-group
            id="AgentBookingReferenceGroup"
            label="Your Booking Reference"
            label-for="AgentBookingReference">
            <b-form-input id="AgentBookingReference" v-model="agentsAdditionalDetails.agentRef" :plaintext="!agentsAdditionalDetailsEditMode"></b-form-input>
          </b-form-group>

          <b-form-group
            id="AgentBookingNotesGroup"
            label="Your Booking Notes"
            label-for="AgentBookingNotes">
            <b-form-textarea :rows="6" id="AgentBookingNotes" v-model="agentsAdditionalDetails.agentNotes" :plaintext="!agentsAdditionalDetailsEditMode"></b-form-textarea>
          </b-form-group>

          <hr/>

          <div class="mt-3 text-right">
            <b-button @click="saveBookingNotesChanges"
              v-if="agentsAdditionalDetailsEditMode"
              variant="primary">Save</b-button>

            <b-button @click="toggleEditBookingNotes" :variant="!agentsAdditionalDetailsEditMode ? 'primary' : ''">
              <template v-if="!agentsAdditionalDetailsEditMode">Edit</template>
              <template v-if="agentsAdditionalDetailsEditMode">Cancel</template>
            </b-button>
          </div>
        </section>
        <loader v-else width="100px" height="100px"></loader>
      </article>

      <article class="page-content" v-if="isQuotation">
        <section>
          <h2>Book this property</h2>
          <div v-if="quoteAvailableToBeBooked && !this.quoteExpiry.expired" class="text-right">
            <b-button :to="this.bookQuotationUrl" variant="primary">Book this property</b-button>
          </div>
          <div v-else>
            <template v-if="this.quoteExpiry.expired">
              <b-alert show variant="warning">
                This quotation has expired
              </b-alert>
              <div class="text-right">
                <b-button :to="this.repriceQuotationUrl" variant="primary">Check price / re-quote / book</b-button>
              </div>
            </template>
            <template v-else>
              <b-alert show variant="warning" >
                This property is no longer available to be booked
              </b-alert>
            </template>
          </div>
        </section>
      </article>

      <article class="page-content" v-if="this.booking.bookingInfo.bookStatusCode === 'RESERVE'">
        <section>
          <h2>Modify Reserve Booking</h2>
          <b-row>
            <b-col class='text-center'>
              <b-button variant="danger" v-confirm="{
                ok: dialog => modifyReserve(dialog, 'cancel'),
                message: 'Do you wish to cancel this reserve booking?',
                okText: 'Yes',
                cancelText: 'No'
              }"
              >Cancel this reserve booking</b-button>
            </b-col>
            <b-col class='text-center'>
              <b-button variant="secondary"  v-confirm="{
                ok: dialog => modifyReserve(dialog, 'upgrade'),
                message: 'Do you wish to upgrade this reserve booking to a full booking?',
                okText: 'Yes',
                cancelText: 'No'
              }">Upgrade to a full booking</b-button>
            </b-col>
          </b-row>
        </section>
      </article>
    </div>
    <div v-else class="loader-outer">
      <loader width="100px" height="100px"></loader>
    </div>

    <b-modal :ok-variant="this.changeRequestState !== 'hasContentAboutToDeleteWarning' ? 'primary' : 'danger' " size="lg" @hide="changeRequestModalCancel" centered ref="amendBookingDetails" id="amendBookingDetails" title="Amend Booking Details" @shown="changeRequestModalShown">
      <template v-if="this.changeRequestState === 'hasFailedToSend'">
        <b-alert variant="danger">
          Sorry the request failed to send, please try again later.
        </b-alert>
      </template>
      <template v-else>
        <b-form-group
          v-if="this.changeRequestState === 'normal'"
          id="changeRequestGroup"
          label="What would you like to do? (please be as detailed as possible)"
          label-for="changeRequest">
          <b-form-textarea ref="changeRequestText" :rows="8" id="changeRequest" v-model="changeRequest" name="changeRequest"
          v-validate="{required: true, min:10}"
          :state="validationState('changeRequest')"
          data-vv-as="change request"
          ></b-form-textarea>
          <b-form-invalid-feedback>
            {{ errors.first('changeRequest') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-alert variant="danger" :show="this.changeRequestState === 'hasContentAboutToDeleteWarning'">
          <h4>ARE YOU SURE?</h4>
          <p>
            You have unsaved changes. If you cancel sending now, your unsent message will be permanently deleted.
          </p>
          <p>
            Click <strong>DELETE MESSAGE</strong> to confirm you wish to delete your unsent message.<br/>
            Click <strong>CONTINUE EDITING</strong> to continue editing your message.
          </p>
        </b-alert>

        <b-alert variant="info" :show="this.changeRequestState === 'hasBeenSent'">
          Thanks. Your change request has been sent. We may contact you for further clarification, if required.
        </b-alert>

        <loader width="50px" height="50px" v-if="changeRequestSending"></loader>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="ok()" v-if="changeRequestState === 'normal'">
          Send Message
        </b-button>
        <b-button variant="secondary" @click="cancel()" v-if="changeRequestState === 'normal'">
          Send Another Message
        </b-button>

        <b-button variant="danger" @click="cancel()" v-if="changeRequestState === 'hasContentAboutToDeleteWarning'">
          Continue Editing
        </b-button>
        <b-button variant="primary" @click="ok()" v-if="changeRequestState === 'hasContentAboutToDeleteWarning'">
          Delete Message
        </b-button>

        <b-button variant="danger" @click="cancel()" v-if="changeRequestState === 'hasBeenSent'">
          Send Another Message
        </b-button>
        <b-button variant="primary" @click="ok()" v-if="changeRequestState === 'hasBeenSent'">
          Close window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import loader from '@/components/widgets/loader'
import passengerList from '@/components/widgets/passenger-list'
import passengerName from '@/components/widgets/form-fields/name'
import passengerAddress from '@/components/widgets/form-fields/address'
import contactInfo from '@/components/widgets/form-fields/contact-info'
import additionalDetails from '@/components/widgets/form-fields/additional-details'
import worldpayPayment from '@/components/widgets/worldpay-payment'
import qs from 'qs'

export default {
  name: 'BookingInfo',

  components: {
    loader,
    'passenger-list': passengerList,
    'passenger-name': passengerName,
    'passenger-address': passengerAddress,
    'passenger-contact-info': contactInfo,
    'passenger-additional-details': additionalDetails,
    'worldpay-payment': worldpayPayment
  },

  mounted () {
    this.loadBooking()

    // display a warning if we have returned to the booking info page after making a payment that has been declined. the url will be appended with ?payment-declined=1
    if (this.$route.query.hasOwnProperty('payment-declined')) {
      this.$bvModal.show('payment-declined-modal')
    }
  },

  methods: {

    modifyReserve (dialog, way) {
      const modifyReserveUrl = '/booking-modify/modify-reserve/' + this.booking.bookingInfo.bookRef
      const postData = { way: way.toUpperCase() }
      switch (way) {
        case 'upgrade':
          break
        case 'cancel':
          break
        default:
          return 'unknown case'
      }
      this.loaded = false // starts loading spinners
      this.$apiCaller({ route: modifyReserveUrl, method: 'POST', data: postData }).then((response) => {
        // all good
        this.loadBooking()
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    },

    passengerContactInfoUpdated (event) {
      this.formLeadPassengerValidationErrors = false
      this.booking.leadPassenger.clientTelephone = event.data.clientTelephone
      this.booking.leadPassenger.clientMobile = event.data.clientMobile
      this.booking.leadPassenger.clientEmail = event.data.clientEmail
    },

    passengerAdditionalDetailsUpdated (event) {
      this.formValidationErrors = false

      // Since we're nested a few levels deep, we need to do this the long way to trigger the reactivity
      this.booking = {
        ...this.booking,
        leadPassenger: {
          ...this.booking.leadPassenger,
          isDriving: event.data.isDriving,
          dob: event.data.dob
        }
      }
    },

    passengerNameUpdated (event) {
      this.formLeadPassengerValidationErrors = false
      this.booking.leadPassenger.clientTitle = event.data.title
      this.booking.leadPassenger.clientForename = event.data.forename
      this.booking.leadPassenger.clientSurname = event.data.surname
    },

    passengerAddressUpdated (event) {
      this.formLeadPassengerValidationErrors = false
      this.booking.leadPassenger.address1 = event.data.address1
      this.booking.leadPassenger.address2 = event.data.address2
      this.booking.leadPassenger.addTown = event.data.addTown
      this.booking.leadPassenger.addCounty = event.data.addCounty
      this.booking.leadPassenger.addPostcode = event.data.addPostcode
      this.booking.leadPassenger.addCountryId = event.data.addCountryId
    },

    optionalExtraAdd (dialog, extra) {
      this.editOptionalExtrasLoading = true
      this.saveChanges('optional-extras-add', { extraId: extra.extraId })
    },

    optionalExtraRemove (dialog, purchasedExtra) {
      this.editOptionalExtrasLoading = true
      this.saveChanges('optional-extras-remove', { villaBookExtraId: purchasedExtra.villaBookExtraId })
    },

    leadPassengerCheckAndSaveChanges () {
      this.leadPassengerLoading = true
      this.formLeadPassengerValidationErrors = false
      const nameValidator = this.$refs.passengerName.validator()
      const addressValidator = this.$refs.passengerAddress.validator()
      const contactValidator = this.$refs.passengerContactInfo.validator()
      Promise.all([nameValidator.validate(), addressValidator.validate(), contactValidator.validate()]).then((values) => {
        if (values.includes(false)) {
          this.leadPassengerLoading = false
          this.formLeadPassengerValidationErrors = true
        } else {
          this.saveChanges('lead-passenger', this.booking.leadPassenger)

          // We also need to save the additional passengers because of the DOB and driving fields
          // The DOB and driving is not apart of the lead passenger record
          // Not the best way of doing this
          this.saveAdditionalPassengerChanges()
        }
      })
    },

    saveChanges (type, values) {
      // this is only called once the section has validated
      const apiUrl = '/booking-modify/' + type + '/' + this.booking.bookingInfo.bookRef
      this.$apiCaller({ route: apiUrl, method: 'POST', data: values }).then((response) => {
        const responseData = response.data.response
        switch (type) {
          case 'lead-passenger':
            // The response data does not contain the DOB and driving fields, so need to manually add them back in
            this.booking.leadPassenger = {
              ...responseData.client,
              dob: this.booking.leadPassenger.dob,
              isDriving: this.booking.leadPassenger.isDriving
            }
            this.leadPassenger_backup = JSON.stringify(this.booking.leadPassenger) // update the backup version to the new values
            this.editLeadPassenger = false
            break
          case 'passenger-list':
            this.additionalPassengers = response.data.response

            this.formatAdditionalPassengers()

            this.additionalPassengers_backup = JSON.stringify(this.additionalPassengers)
            this.editPassengerList = false
            this.$refs.additionalPassengersList.setupComponent()
            break
          case 'agents-additional-details':
            this.agentsAdditionalDetails = response.data.response
            this.agentsAdditionalDetails_backup = JSON.stringify(this.agentsAdditionalDetails)
            this.agentsAdditionalDetailsEditMode = false
            break
          case 'optional-extras-add':
            if (responseData.hasOwnProperty('updated')) {
              // reload this booking info page.
              this.loadBooking()
            }
            this.editOptionalExtras = false
            break
          case 'optional-extras-remove':
            if (responseData.hasOwnProperty('removed')) {
              // reload this booking info page.
              this.loadBooking()
            }
            this.editOptionalExtras = false
            break
        }
      })
        .catch(() => {

        })
        .finally(() => {
          switch (type) {
            case 'lead-passenger':
              this.leadPassengerLoading = false
              break
            case 'passenger-list':
              this.additionalPassengersLoading = false
              break
            case 'agents-additional-details':
              this.agentsAdditionalDetailsLoading = false
              break
            case 'optional-extras-add':
            case 'optional-extras-remove':
              this.editOptionalExtrasLoading = false
              break
          }
        })
    },

    changeRequestModalShown () {
      if (this.$refs.changeRequestText) {
        this.$refs.changeRequestText.focus()
      }
    },

    abortChangeRequest () {
      this.$refs.amendBookingDetails.hide('usercancel')
      this.changeRequest = ''
      this.changeRequestCancelPaused = false
      this.changeRequestSent = false
    },

    sendChangeRequest () {
      this.changeRequestSending = true
      const enquiry = {
        subject: 'Enquiry from villa choices agent, Booking Ref [' + this.booking.bookingInfo.bookRef + ']',
        body: this.changeRequest
      }
      const apiUrl = '/send-enquiry'
      this.$apiCaller({ route: apiUrl, method: 'POST', data: enquiry, displayErrorAlert: false }).then((response) => {
        this.changeRequestState = 'hasBeenSent'
        this.changeRequest = ''
      })
        .catch(() => {
          this.changeRequestState = 'hasFailedToSend'
        })
        .finally(() => {
          this.changeRequestSending = false
        })
    },

    changeRequestModalCancel (e) {
      /* changeRequestState
          normal
          hasContentAboutToDeleteWarning
          hasBeenSent
          hasFailedToSend
        */
      switch (e.trigger) {
        case 'cancel':
        case 'backdrop':
        case 'headerclose':
        case 'esc':
          // cancel modal button / action
          switch (this.changeRequestState) {
            case 'normal':
              if (this.changeRequest.length > 0) { // we have content - display warning about closing the form
                this.changeRequestState = 'hasContentAboutToDeleteWarning'
                e.preventDefault()
              }
              break
            case 'hasContentAboutToDeleteWarning': // continue editing
              this.changeRequestState = 'normal'
              e.preventDefault()
              break
            case 'hasBeenSent': // send another
              this.changeRequestState = 'normal'
              e.preventDefault()
              break
          }
          break
        case 'ok':
          // ok modal button / action
          switch (this.changeRequestState) {
            case 'normal':
              e.preventDefault()
              this.$validator.validate().then((valid) => { // send request if valid ie length > 10
                if (valid) {
                  this.sendChangeRequest()
                }
              })
              break
            case 'hasContentAboutToDeleteWarning': // delete content after displaying warning
              this.changeRequest = ''
              this.changeRequestState = 'normal'
              break
            case 'hasBeenSent':
              this.changeRequestState = 'normal'
              break
          }
          break
        default:
      }
    },

    saveAdditionalPassengerChanges () {
      const newPassengerList = []
      for (let i = 0; i < this.additionalPassengers.length; i++) {
        if (this.$refs.additionalPassengersList.isRowPopulated(i)) {
          newPassengerList.push(this.additionalPassengers[i])
        }
      }

      // The additional passengers does not include the lead passenger, so need to manually add them back in before we send the passengers to the API
      newPassengerList.unshift({
        title: this.booking.leadPassenger.clientTitle,
        forename: this.booking.leadPassenger.clientForename,
        surname: this.booking.leadPassenger.clientSurname,
        passengerType: 'Adult',
        dob: this.booking.leadPassenger.dob,
        isDriving: this.booking.leadPassenger.isDriving
      })

      if (this.additionalPassengers_backup !== JSON.stringify(newPassengerList)) {
        this.additionalPassengersLoading = true
        this.saveChanges('passenger-list', newPassengerList)
      }
    },

    saveBookingNotesChanges () {
      if (JSON.stringify(this.agentsAdditionalDetails) !== this.agentsAdditionalDetails_backup) {
        this.agentsAdditionalDetailsLoading = true
        this.saveChanges('agents-additional-details', this.agentsAdditionalDetails)
      }
    },

    toggleEditLeadPassenger () {
      if (this.editLeadPassenger) {
        // we must have pressed cancel
        this.editLeadPassenger = false
        // revert to backup version
        this.booking.leadPassenger = JSON.parse(this.leadPassenger_backup)
      } else {
        // must have pressed edit
        this.editLeadPassenger = true
      }
    },

    toggleEditPassengerList () {
      if (this.editPassengerList) {
        // we must have pressed cancel
        this.editPassengerList = false
        // revert to backup version
        this.additionalPassengers = JSON.parse(this.additionalPassengers_backup)
      } else {
        // must have pressed edit
        this.editPassengerList = true
      }
    },

    toggleEditBookingNotes () {
      if (this.agentsAdditionalDetailsEditMode) {
        // must have pressed cancel.
        this.agentsAdditionalDetailsEditMode = false
        // revert to backup version
        this.agentsAdditionalDetails = JSON.parse(this.agentsAdditionalDetails_backup)
      } else {
        // must have pressed edit
        this.agentsAdditionalDetailsEditMode = true
      }
    },

    toggleEditOptionalExtras () {
      this.editOptionalExtras = !this.editOptionalExtras
    },

    loadBooking () {
      this.loaded = false
      this.booking = {}
      const apiUrl = '/bookings/' + this.$route.params.ref
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        if (response.data.response.hasOwnProperty('bookingInfo')) {
          this.booking = response.data.response
          if (this.booking.bookingInfo.bookStatusCode === 'QUOTATION') {
            this.isQuotation = true
            this.bookingTerminology = 'quote'
            this.quoteAvailableToBeBooked = this.booking.quoteAvailableToBeBooked
            if (!this.quoteAvailableToBeBooked) {
              this.bookingLocked = true
            }
            this.quoteExpiry = this.booking.quoteExpiry
            this.bookQuotationUrl = '/checkout/all/customer-details/' + this.booking.bookingInfo.bookRef
            if (this.quoteExpiry.expired) {
              // /checkout/all/optional-extras?criteria%5Barrival%5D=2019-07-15&criteria%5Bnights%5D=5&propertyid=2209&extras%5Bextra_18%5D=2
              const urlParams = {
                criteria: {
                  arrival: this.booking.propertyBookingInfo.arrival,
                  nights: this.booking.propertyBookingInfo.nights
                },
                propertyid: this.booking.propertyBookingInfo.propertyId
              }
              if (this.booking.purchasedExtras.length > 0) {
                urlParams.extras = {}
                for (const i in this.booking.purchasedExtras) {
                  const purchasedExtraInfo = this.booking.purchasedExtras[i]
                  urlParams.extras['extra_' + purchasedExtraInfo.info.extraId] = purchasedExtraInfo.purchased.length
                }
              }
              const queryString = qs.stringify(urlParams, { arrayFormat: 'brackets', encode: false })
              this.repriceQuotationUrl = '/checkout/all/optional-extras' + '?' + queryString
            }
          }
          if (!this.isQuotation) {
            this.additionalPassengers = this.booking.additionalPassengers

            if (this.additionalPassengers.length >= 1) {
              const leadPassenger = this.additionalPassengers[0]

              // This is an object of {y: year, m: month, d: day}
              // We need to turn this into a string of YYYY-MM-DD so the native date picker can read it
              if (leadPassenger.dob) {
                // Stitch the date back together
                const dob = leadPassenger.dob

                leadPassenger.dob = dob.y + '-' + dob.m + '-' + dob.d
              }

              // The dob and driving field is edited in the lead passenger section which is why we need to update it here
              this.booking.leadPassenger.dob = leadPassenger.dob
              this.booking.leadPassenger.isDriving = leadPassenger.isDriving

              this.formatAdditionalPassengers()

              this.additionalPassengers_backup = JSON.stringify(this.additionalPassengers)
            }

            this.agentsAdditionalDetails = this.booking.agentsAdditionalDetails
            this.agentsAdditionalDetails_backup = JSON.stringify(this.booking.agentsAdditionalDetails)

            this.leadPassenger_backup = JSON.stringify(this.booking.leadPassenger)
          }
          if (!this.booking.payments.length) {
            // no payments received - need at least the deposit
            this.cardPayments.min = this.booking.depositInfo.amount
          } else {
            this.cardPayments.min = 10
          }
          this.cardPayments.max = this.booking.agentSummary.outstandingBalance
          if (this.booking.agentSummary.outstandingBalance < 10) {
            this.cardPayments.min = this.booking.agentSummary.outstandingBalance
          }

          // Ensure the min and max is fixed to 2 decimal places and is a number
          this.cardPayments.min = Number(this.cardPayments.min.toFixed(2))
          this.cardPayments.max = Number(this.cardPayments.max.toFixed(2))

          // lock changes to the booking if in the following book statuses, only locks editing optional extras.
          if (['CANCELLED'].includes(this.booking.bookingInfo.bookStatusCode)) {
            this.bookingLocked = true
          }
          // have payment panel
          if (['PAYPENDING', 'BOOKING'].includes(this.booking.bookingInfo.bookStatusCode)) {
            this.hasPaymentsSection = true
          }
          // set an array of max quantities reached for optional extras
          const optionalExtraMaxQuantities = {}
          this.booking.purchaseAbleExtras.forEach((extra) => {
            optionalExtraMaxQuantities[extra.extraId] = parseInt(extra.maxQuantity, 10)
          })
          this.optionalExtrasMaxQuantitiesReachedForExtraIds = []
          this.booking.purchasedExtras.forEach((purchasedExtra) => {
            if (purchasedExtra.purchased.length === optionalExtraMaxQuantities[purchasedExtra.info.extraId]) {
              this.optionalExtrasMaxQuantitiesReachedForExtraIds.push(purchasedExtra.info.extraId)
            }
          })
        }
        this.loaded = true
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    },

    formatAdditionalPassengers () {
      // Remove the lead passenger from the additional passengers list
      this.additionalPassengers.shift()

      // This is for backwards compatibility with the old passenger list
      this.additionalPassengers = this.additionalPassengers.map((passenger) => {
        // This is an object of {y: year, m: month, d: day}
        // We need to turn this into a string of YYYY-MM-DD so the native date picker can read it
        if (passenger.dob) {
          const dob = passenger.dob

          passenger.dob = dob.y + '-' + dob.m + '-' + dob.d
        }

        return {
          forename: passenger.clientForename,
          surname: passenger.clientSurname,
          isDriving: passenger.isDriving || false,
          dob: passenger.dob || null
        }
      })
    },

    validationState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    }
  },

  data () {
    return {
      fullDateAndTimeMomentFormat: 'ddd Do MMM YYYY, h:mma',
      fullDateFormat: 'ddd Do MMM YYYY',
      bookingLocked: false,
      hasPaymentsSection: false,
      isQuotation: false,
      bookingTerminology: 'booking',
      changeRequest: '',
      changeRequestState: 'normal',
      changeRequestSending: false,
      editPassengerList: false,
      editLeadPassenger: false,
      formLeadPassengerValidationErrors: false,
      editOptionalExtras: false,
      editOptionalExtrasLoading: false,
      optionalExtrasMaxQuantitiesReachedForExtraIds: [],
      loaded: false,
      agentsAdditionalDetails: {
        agentNotes: '',
        agentRef: ''
      },
      agentsAdditionalDetails_backup: '',
      agentsAdditionalDetailsEditMode: false,
      agentsAdditionalDetailsLoading: false,
      leadPassenger_backup: '',
      leadPassengerLoading: false,
      additionalPassengers: [],
      additionalPassengers_backup: '',
      additionalPassengersLoading: false,
      cardPayments: {
        min: null,
        max: null
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/admin/BookingDetails.scss';
</style>
