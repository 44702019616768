<template>
  <b-container id="villas">

    <search-form
      :minNights="this.$store.getters.searchNights.min"
      :maxNights="this.$store.getters.searchNights.max"
      :startDate="this.$store.getters.searchDates.earliest"
      :endDate="this.$store.getters.searchDates.latest"
      :selectedCheckIn="((this.$route.query['criteria[arrival]'] && (this.$route.query['criteria[nights]'] > 0)) ? this.$route.query['criteria[arrival]'] : null)"
      :nights="(this.$route.query['criteria[nights]'] > 0) ? this.$route.query['criteria[nights]'] : null"
      @datesSelected="searchDatesUpdated"
      v-if="this.$store.getters.searchDates.earliest && this.$store.getters.searchDates.latest"></search-form>

    <b-row>
      <b-col lg="3">
        <quickSearch @searchQuerySelected="searchQuerySelected" :searchQuery="searchQuery" :searchQueryActive="searchQueryActive"></quickSearch>
        <search-filters
        v-if="filters && filters.length > 0"
        :filter-items="filters"
        :disableAll="disableFilters"
        @filterApplied="updateSearchFilters"></search-filters>
      </b-col>
      <b-col lg="9">
        <flexible-dates
          @flexibleDateSelected="flexibleWithDates"
          :search-dates="flexibleDates"
          :earliestBookableDate="this.$store.getters.searchDates.earliest"
          :latestBookableDate="this.$store.getters.searchDates.latest"
          show-title
          v-if="flexibleDates.length && loaded"></flexible-dates>

        <div class="pagination-outer" v-if="loaded && villaCount > 0">
          <b-row>
            <b-col lg="6" class="d-flex align-items-center">
              <h1 class="page-title mb-3 mb-lg-0 mb-xl-0 text-center text-lg-left text-xl-left" style="width: 100%;">
                {{ 'pages.villas.villaCount.chooseFrom' | translate }} <strong>{{ villaCount }}</strong> {{ 'pages.villas.villaCount.propertyType' | translate }}<small v-show="searchParamsWithoutRppAndPage">, {{ 'pages.villas.villaCount.matching' | translate }}</small>.
              </h1>
            </b-col>
            <b-col lg="6">
              <b-pagination-nav
                v-if="totalPages > 1"
                class="pag-nav justify-content-center justify-content-lg-end justify-content-xl-end d-flex"
                :number-of-pages="totalPages"
                :link-gen="linkGen"
                v-model="currentPage"
                :limit="7"
                use-router
                />
            </b-col>
          </b-row>
        </div>

        <div class="loader-outer">
          <loader v-if="!loaded" width="100px" height="100px"></loader>
        </div>

        <search-result v-if="loaded && villaCount < 1" :title="$t('pages.villas.villaCount.noVillas') + ' ' + $t('pages.villas.villaCount.propertyType') + ', ' + $t('pages.villas.villaCount.matching')" :description="$t('pages.villas.villaCount.noVillasHelper')"></search-result>

        <search-result v-if="loaded" v-for="(result, index) in properties" :key="index" :title="result.fullCommunityName" :sub-title="propertySubTitleString(result)"
                       :reference="result.propertyCode"
                       :carouselImages="result.carouselImages"
                       :image="result.image"
                       :description="result.descriptionShort"
                       :description-points="result.descriptionPoints"
                       :icons="result.icons" button-text="View Details"
                       :resultURL="'/villa/' + result.propertyId + '?' + searchParamsWithoutRppAndPage + (result.searchResultNumber?'&criteria[rpp]=1&criteria[page]=' + result.searchResultNumber:'')"
                       :price="result.price"
                       :deposit="result.deposit"
                       :commission="result.agentCommission"
                       v-bind:showCommission="$store.getters.getAgentView"
                       :showPoolHeatingCost="result.hasOwnProperty('poolHeatingCost')"
                       :poolHeatingCost="result.poolHeatingCost"
                       :propertyHighlight="result.highlight"></search-result>

       <div class="pagination-outer" v-if="loaded && villaCount > 0">
         <b-row align-h="end">
           <b-col md="6">
             <b-pagination-nav
               v-if="totalPages > 1"
               class="pag-nav justify-content-center justify-content-lg-end justify-content-xl-end d-flex"
               align="right"
               :number-of-pages="totalPages"
               :link-gen="linkGen"
               v-model="currentPage"
               :limit="7"
               use-router
               />
           </b-col>
         </b-row>
       </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import qs from 'qs'
import loader from '@/components/widgets/loader'
import quickSearch from '@/components/widgets/form-fields/quick-search.vue'
import SearchForm from '@/components/widgets/search-form'
import SearchResult from '@/components/widgets/search-result'
import SearchFilters from '@/components/widgets/search-filters'
import FlexibleDates from '@/components/widgets/flexible-date-selector'

export default {
  name: 'Villas',

  components: {
    SearchResult,
    SearchForm,
    SearchFilters,
    FlexibleDates,
    quickSearch,
    loader
  },

  mounted () {
    this.searchProperties()
  },

  watch: {
    '$route.query': {
      handler (newVal) {
        this.searchProperties()
      },
      deep: true
    }
  },

  computed: {
    searchParams () {
      return qs.stringify(this.$route.query, { arrayFormat: 'brackets', encode: false })
    },

    searchParamsWithoutRppAndPage () {
      /* TODO swap this out for the qs filter function - see villa info for example and perhaps globalise the filter function */
      const queryWithAllAttributes = qs.parse(qs.stringify(this.$route.query, { arrayFormat: 'brackets', encode: false }))
      if (queryWithAllAttributes.hasOwnProperty('criteria')) {
        if (queryWithAllAttributes.criteria.hasOwnProperty('page')) {
          delete queryWithAllAttributes.criteria.page
        }
        if (queryWithAllAttributes.criteria.hasOwnProperty('rpp')) {
          delete queryWithAllAttributes.criteria.rpp
        }
      }
      return qs.stringify(queryWithAllAttributes, { arrayFormat: 'brackets', encode: false })
    }
  },

  methods: {
    searchQuerySelected (searchQueryText) {
      this.updateSearchQueryParameter('query', searchQueryText)
    },

    filtersFromCurrentQueryString () {
      const filtersFromQueryString = {}
      const parsedRoute = qs.parse(this.$route.query)
      if (parsedRoute.hasOwnProperty('filters')) {
        for (const filterSection in parsedRoute.filters) {
          if (parsedRoute.filters.hasOwnProperty(filterSection)) {
            const combiningOperator = this.combiningOperatorForFilterSection(filterSection)
            filtersFromQueryString[filterSection] = parsedRoute.filters[filterSection].split('-' + combiningOperator + '-')
          }
        }
      }
      return filtersFromQueryString
    },

    criteriaFromCurrentQueryString () {
      const criteriaFromQueryString = {}
      const parsedRoute = qs.parse(this.$route.query)
      if (parsedRoute.hasOwnProperty('criteria')) {
        for (const criteriaParam in parsedRoute.criteria) {
          if (parsedRoute.criteria.hasOwnProperty(criteriaParam)) {
            criteriaFromQueryString[criteriaParam] = parsedRoute.criteria[criteriaParam]
          }
        }
      }
      return criteriaFromQueryString
    },

    changeFiltersToApiQueryFilters (filters) {
      const filtersForApiQuery = {}
      for (const filterSection in filters) {
        const combiningOperator = this.combiningOperatorForFilterSection(filterSection)
        filtersForApiQuery[filterSection] = filters[filterSection].join('-' + combiningOperator + '-')
      }
      return filtersForApiQuery
    },

    combiningOperatorForFilterSection (filterSection) {
      let combiningOperator
      switch (filterSection) {
        case 'options':
          combiningOperator = 'and'
          break
        default:
          combiningOperator = 'or'
      }
      return combiningOperator
    },

    updateSearchQueryParameter (type, params) {
      const newQuery = {
        criteria: this.criteriaFromCurrentQueryString(),
        filters: this.changeFiltersToApiQueryFilters(this.filtersFromCurrentQueryString())
      }

      switch (type) {
        case 'query':
          if (params.length > 0) {
            newQuery.criteria.q = params
          } else {
            delete newQuery.criteria.q
          }
          break
        case 'arrival':
          newQuery.criteria.arrival = params
          break
        case 'arrivalAndNights':
          newQuery.criteria.arrival = params.arrival
          newQuery.criteria.nights = params.nights
          break
        case 'filters':
          newQuery.filters = this.changeFiltersToApiQueryFilters(params)
          break
      }

      if (newQuery.criteria.hasOwnProperty('page')) {
        newQuery.criteria.page = 1 // reset page back to 1
      }
      const query = qs.stringify(newQuery, { arrayFormat: 'brackets', encode: false })
      this.$router.push('/villas?' + query)
    },

    updateSearchFilters (filters) {
      const filterParams = {}
      let filterKey
      for (const filterSection in filters) {
        switch (filterSection) {
          case 'accommodation':
          case 'outdoorliving':
          case 'communitydetails':
            filterKey = 'options'
            break
          default:
            filterKey = filterSection
        }
        if (filters.hasOwnProperty(filterSection)) {
          if (filters[filterSection].length > 0) {
            if (!filterParams.hasOwnProperty(filterKey)) {
              filterParams[filterKey] = []
            }
            for (let i = 0; i < filters[filterSection].length; i++) {
              filterParams[filterKey].push(filters[filterSection][i])
            }
          }
        }
      }
      this.updateSearchQueryParameter('filters', filterParams)
    },

    flexibleWithDates (date) {
      this.updateSearchQueryParameter('arrival', date)
    },

    searchDatesUpdated (dates) {
      const arrivalAndNights = {
        arrival: dates.checkIn,
        nights: dates.nights
      }
      this.updateSearchQueryParameter('arrivalAndNights', arrivalAndNights)
    },

    searchProperties () {
      this.disableFilters = true
      this.loaded = false
      this.$apiCaller({ route: '/property-search?' + this.searchParams }).then((response) => {
        if (response.data.response.hasOwnProperty('flexibleDates')) {
          this.flexibleDates = response.data.response.flexibleDates
        } else {
          this.flexibleDates = []
        }
        this.filters = response.data.response.filterCountsAndOtherCounts
        this.properties = response.data.response.properties
        this.villaCount = parseInt(response.data.response.propertiesTotal, 10)
        this.currentPage = parseInt(response.data.response.criteria.page, 10)
        this.maxResultsPerPage = parseInt(response.data.response.criteria.rpp, 10)
        this.totalPages = parseInt(Math.ceil(response.data.response.propertiesTotal / response.data.response.criteria.rpp), 10)
        this.disableFilters = false
        this.loaded = true
        if (response.data.response.criteria.hasOwnProperty('q')) {
          this.searchQuery = response.data.response.criteria.q
          this.searchQueryActive = true
        } else {
          this.searchQuery = ''
          this.searchQueryActive = false
        }
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    },

    linkGen (pageNum) {
      const currentQuery = qs.parse(this.$route.query)
      if (!currentQuery.hasOwnProperty('criteria')) {
        currentQuery.criteria = {}
      }
      currentQuery.criteria.page = pageNum
      const newQuery = qs.stringify(currentQuery, { arrayFormat: 'brackets', encode: false })
      return {
        path: '/villas?' + newQuery
      }
    },

    propertySubTitleString (property) {
      return `${property.bedrooms} bedroom ${property.propertyType} <span class="sleeps-up-to">(sleeps up to ${property.maxOccupancy} guests)</span>`
    }
  },

  data () {
    return {
      loaded: false,
      flexibleDates: [],
      villaCount: 0,
      currentPage: 1,
      totalPages: 1,
      maxResultsPerPage: 0,
      filters: [],
      properties: [],
      disableFilters: false,
      searchQuery: '',
      searchQueryActive: false
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/villas/Villas.scss';
</style>
