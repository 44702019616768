<template>
  <b-container>
    <div class="login-jumbo">

      <b-alert :show="alertType === 'WELCOME'" variant="info" dismissible fade>
        <h4 class="alert-heading">Welcome to Villa Choices</h4>
        <p v-if="!agentInfoToggled">If you're not a registered agent, <b-link style="color:inherit;text-decoration:underline;" @click="openMoreAgentInfo">click here for more information</b-link></p>
        <template v-if="agentInfoToggled">
          <section style="border-top:3px solid orange;border-bottom:3px solid orange;padding-top:12px;padding-bottom:12px;">
            <b-row>
              <b-col cols="10">
                <h4 class="alert-heading">Benefits of joining Villa Choices</h4>
              </b-col>
              <b-col cols="2" class="text-right">
                <b-link style="" @click="closeMoreAgentInfo">Close</b-link>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <ul class="login-bullets">
                  <li>Commission on every booking</li>
                  <li>A large portfolio of Orlando villas hand-picked by us</li>
                  <li>Pre-arrival inspection carried out by our team</li>
                  <li>Free Welcome Pack for every guest</li>
                  <li>Instant quotes, branded with your company details, that can be sent to your customer</li>
                  <li>Breakage Insurance included with every booking</li>
                  <li>Detailed property information sheets, branded with your company details, on each property that can be sent to your customer</li>
                  <li>Agent support seven days a week, including weekday evenings up to 8pm</li>
                  <li>Informative and easy to use website</li>
                  <li>Live pricing and availability</li>
                </ul>
              </b-col>
            </b-row>
          </section>
          <b-row>
            <b-col>
              <p style="margin-top:12px;font-size:16px;"><strong>Email us to register <b-link :href="'mailto:' + $t('global.vcEmail')">{{ $t('global.vcEmail') }}</b-link></strong></p>
            </b-col>
          </b-row>
        </template>
      </b-alert>

      <b-alert :show="alertType === 'AUTH_ERROR'" variant="danger" dismissible fade>
        <h4>Oh dear...</h4>
        <p>We could not log you in. Ensure your username and password are correct.</p>
      </b-alert>

      <template v-if="alertType === 'FORGOT_PASSWORD' && passwordRequested">
        <b-alert :show="passwordResetSuccessful" variant="success" dismissible fade>
          <h4>Check your email</h4>
          <p>We have sent an email to your registered email address with further instructions on how to reset your password.</p>
        </b-alert>
        <b-alert :show="!passwordResetSuccessful" variant="danger" dismissible fade>
          <h4>Oops!</h4>
          <p>We tried to reset your password, but there was a problem. Try again in a few minutes.</p>
        </b-alert>
      </template>

      <template v-if="alertType === 'LOGGED_OUT'">
        <b-alert :show="$store.getters.sessionTimedOut" variant="info" dismissible fade>
          <h4>Just so you know...</h4>
          <p>You were inactive for a long period of time. For your security, we automatically logged you out.</p>
          <p><strong>Changes that you made, but did not save, will have been lost.</strong></p>
        </b-alert>
        <b-alert :show="!$store.getters.sessionTimedOut" variant="success" dismissible fade>
          <h4>Logged out</h4>
          <p>You have logged out.</p>
        </b-alert>
      </template>

      <section class="login-box" v-bind:class="{ error: authError }">
        <b-form class="login-form" @submit.prevent="onSubmit">
          <h1>
            <span v-if="!forgotPassword">Agent Log In</span>
            <span v-if="forgotPassword">To reset your password please enter the email address used to register</span>
          </h1>
          <div class="form-group">
            <input type="email" class="form-control" id="userName" aria-describedby="emailHelp" placeholder="Enter email" v-model="form.email" required>
          </div>
          <div class="form-group" v-if="!forgotPassword">
            <input type="password" class="form-control" id="password" placeholder="Enter password" v-model="form.password" required>
          </div>

          <b-row>
            <b-col v-show="loading">
              <loader color="#ffffff" width="35px" height="35px"></loader>
            </b-col>

            <b-col v-show="!loading && forgotPassword === false">
              <b-link class="pw-recovery" href="#" @click="initiatePasswordRecovery" v-if="forgotPassword === false">Forgot password?</b-link>
            </b-col>

            <b-col v-show="!loading && forgotPassword === true">
              <b-link class="pw-recovery" href="#" @click="cancelPasswordRecovery" v-if="forgotPassword === true">Go back</b-link>
            </b-col>

            <b-col class="text-right">
              <button type="submit" class="btn btn-primary" v-if="forgotPassword === false" :disabled="loading">Log in</button>

              <button type="submit" class="btn btn-primary" v-if="forgotPassword === true" :disabled="loading">Reset Password</button>
            </b-col>
          </b-row>
        </b-form>
      </section>
    </div>
  </b-container>

</template>

<script>
import {
  UPDATE_CONFIG
} from '@/store/actions/config'

import {
  LOG_IN_SUCCESS,
  SET_IS_IMPERSONATING
} from '@/store/actions/authnew'

import {
  UPDATE_USER_ROLE
} from '@/store/actions/usernew'

import loader from '@/components/widgets/loader.vue'

export default {
  name: 'Login',

  components: {
    loader
  },

  mounted () {
    // reset values
    this.loginAttempts = 0
    this.forgotPassword = false
    this.passwordRequested = false
    this.passwordResetSuccessful = null
    if (this.$store.getters.wasAuthenticated) {
      this.alertType = 'LOGGED_OUT'
    }
    if (this.$store.getters.isAuthenticated) {
      this.$router.push({ path: '/' }) // redirect to homepage)
    }
  },

  methods: {
    onSubmit () {
      this.alertType = ''
      this.showWelcomeMessage = false
      this.loading = true
      this.authError = false
      this.passwordRequested = false
      if (this.forgotPassword) { // user forgot password
        this.passwordResetSuccessful = null
        this.$apiCaller({ route: '/reset-password', method: 'POST', data: this.form, displayErrorAlert: false }).then((response) => {
          this.passwordResetSuccessful = true
        })
          .catch(() => {
            this.passwordResetSuccessful = false
          })
          .finally(() => {
            this.loading = false
            this.alertType = 'FORGOT_PASSWORD'
            this.passwordRequested = true
          })
      } else { // user logging in
        this.$apiCaller({ route: '/login', method: 'POST', data: this.form, displayErrorAlert: false }).then((response) => {
          const token = response.data.token
          localStorage.setItem('user-token', token)
          if (response.data.hasOwnProperty('userRole')) {
            localStorage.setItem('userRole', response.data.userRole)
            this.$store.dispatch(UPDATE_USER_ROLE, response.data.userRole)
          }
          if (response.data.hasOwnProperty('config')) {
            localStorage.setItem('config', JSON.stringify(response.data.config))
            this.$store.dispatch(UPDATE_CONFIG, response.data.config)
          }
          if (response.data.hasOwnProperty('accountSignupIncomplete') && response.data.accountSignupIncomplete) {
            localStorage.setItem('accountSignupIncomplete', 1)
          }

          let userId = 0
          if (response.headers.hasOwnProperty('x-api-user-id')) {
            userId = response.headers['x-api-user-id']
          }

          // Set User Id and email (used by bugsnag to identify users)
          localStorage.setItem('userId', userId)
          localStorage.setItem('userEmail', this.form.email)

          this.$store.dispatch(SET_IS_IMPERSONATING, {
            isImpersonating: false,
            userAdminId: null
          })
          this.$store.dispatch(LOG_IN_SUCCESS)
          this.$router.push({ path: '/' }) // redirect to homepage
        })
          .catch((error) => {
            if (error.response.status === 401) {
              this.loginAttempts++
            }
            this.authError = true
            this.alertType = 'AUTH_ERROR'
            localStorage.removeItem('user-token')/* don't think this is needed here? */
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    initiatePasswordRecovery (evt) {
      evt.preventDefault()
      this.alertType = ''
      this.authError = false
      this.forgotPassword = true
      this.passwordResetSuccessful = null
      this.passwordRequested = false
    },

    cancelPasswordRecovery (evt) {
      evt.preventDefault()
      this.forgotPassword = false
      this.alertType = ''
    },

    openMoreAgentInfo () {
      this.agentInfoToggled = true
    },

    closeMoreAgentInfo () {
      this.agentInfoToggled = false
    }
  },

  data () {
    return {
      showWelcomeMessage: true,
      loading: false,
      authError: false,
      form: {
        email: '',
        password: '',
        user_type: 10 // 'white-label-agent', 'white-label-organisation' is 11
      },

      loginAttempts: 0,

      forgotPassword: false,
      passwordRequested: false,
      passwordResetSuccessful: null,
      alertType: 'WELCOME',
      agentInfoToggled: false
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/Login.scss';

  ul.login-bullets {

    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
    list-style-type: none;
    @include media-breakpoint-down(sm) {
      -moz-column-count: 1;
      -moz-column-gap: 0px;
      -webkit-column-count: 1;
      -webkit-column-gap: 0px;
      column-count: 1;
      column-gap: 0px;
    }
    padding: 0;
    list-style: none;

    li {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      position: relative;
      border-color: $dark-green;
      padding: 0 20px;

      &::before,
      &::after {
        position: absolute;
        content: '';
        left: 0px;
        top: 11px;
        width: 11px;
        height: 5px;
        border-color: transparent;
        border-style: solid;
        border-width: 0 3px;
      }

      &::before {
        margin-top: -5px;
        border-bottom: 5px solid;
        border-bottom-color: inherit;
      }

      &::after {
        margin-top: 0;
        border-top: 5px solid;
        border-top-color: inherit;
      }
    }
  }
</style>
