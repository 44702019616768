<template>
  <div id="dashboard">
    <b-row>
      <b-col>
        <section class="welcome">
          <p>Welcome to Villa Choices by Debbie's Villas. Specialising in Orlando only, it is our aim to provide you with all the tools you need to give your clients the perfect villa holiday to the theme park capital of the world. We hope this website is intuitive enough for you to use without guidance or a training manual, but if you have any questions please do contact us on:</p>
          <p class="contact">
            <b-row>
              <b-col lg="4">
                <font-awesome-icon :icon="telephoneIcon"></font-awesome-icon>{{ $t('global.vcPhoneNumber') }}
              </b-col>
              <b-col lg="4">
                <font-awesome-icon :icon="emailIcon"></font-awesome-icon><b-link :href="'mailto:' + $t('global.vcEmail')">{{ $t('global.vcEmail') }}</b-link><br />
              </b-col>
              <b-col lg="4">
                <font-awesome-icon :icon="facebookIcon"></font-awesome-icon><b-link href="https://www.facebook.com/groups/villachoices/" target="_blank">Join Our Facebook Group</b-link>
              </b-col>
            </b-row>
          </p>
        </section>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <div class="approved-villas">
          <p>Debbie's Villas staff have personally visited each and every one of these villas, we continuously vet every property on this website to ensure that your customers get the best possible experience.</p>
        </div>
      </b-col>

      <b-col lg="6">
        <div class="welcome-pack">
          &nbsp;
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPhoneAlt, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

export default {
  name: 'Dashboard',
  components: {
    FontAwesomeIcon
  },

  methods: {

  },

  computed: {
    telephoneIcon () {
      return faPhoneAlt
    },
    emailIcon () {
      return faEnvelopeOpen
    },
    facebookIcon () {
      return faFacebook
    }
  },

  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/admin/Dashboard.scss';
</style>
