<template>
  <footer class="footer">
    <div class="container"></div>
  </footer>
</template>

<script>
export default {
  name: 'Page-Footer',
  props: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/base/footer.scss';
</style>
