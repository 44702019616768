<template>

  <div id="searchForm">
    <b-form class="search-form">
      <b-container fluid>
        <b-row>
          <b-col cols="12" lg="3" xl="4">
            <strong class="title">
              Property Search
            </strong>
          </b-col>

          <b-col>
            <b-form-group
              id="datesGroup"
              label=""
              label-for="dates"
              class="mb-md-0">

              <div class="picker-container">
                <v-date-picker :min-date="minDate" :max-date="maxDate" mode="single" v-model="searchForm.arriving" @input="dateChanged">
                  <div class="input-container" slot-scope='{ inputValue, updateValue }'>
                    <font-awesome-icon :icon="calendarIcon" class="calendar-icon"></font-awesome-icon>
                    <input
                    readonly
                     type='text'
                     :value='inputValue'
                     placeholder="Choose a date"
                     @change='updateValue($event.target.value, { formatInput: true, hidePopover: false })'>
                  </div>
                </v-date-picker>
                <v-select placeholder="Select number of nights" :options="nightsSelectorOptions" :disabled="disableNightsSelect" v-model="nightsSelectorModel"></v-select>
              </div>
            </b-form-group>
          </b-col>

          <b-col class="d-none d-xl-block" xl="2">&nbsp;</b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>

</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'Search-Form',
  components: {
    FontAwesomeIcon
  },

  props: [
    'minNights',
    'maxNights',
    'startDate',
    'endDate',
    'selectedCheckIn',
    'nights'
  ],

  mounted () {
    if (this.searchForm.arriving !== null && this.searchForm.nights > 0) {
      this.nightsSelectorModel = this.nightsSelectedOnMount()
      this.nightsSelectorOptions = this.nightsSelect()
    }
  },

  computed: {
    calendarIcon () {
      return faCalendarAlt
    },

    clearIcon () {
      return faTimes
    },

    checkOut () {
      if (this.searchForm.arriving !== null && this.searchForm.nights > 0) {
        return this.$moment(this.searchForm.arriving, 'YYYY-MM-DD').add(this.searchForm.nights, 'days')
      }

      return ''
    },

    disableNightsSelect () {
      if (this.searchForm.arriving === null) {
        return true
      } else {
        return false
      }
    },

    minDate () {
      return this.$moment(this.startDate, 'YYYY-MM-DD').toDate()
    },

    maxDate () {
      return this.$moment(this.endDate, 'YYYY-MM-DD').toDate()
    }
  },

  watch: {
    selectedCheckIn: {
      handler: function (newValue) {
        if (this.selectedCheckIn !== null && this.nights !== null) {
          this.searchForm.arriving = this.$moment(newValue, 'YYYY-MM-DD').toDate()
          this.dateChanged()
        } else {
          this.searchForm.nights = 0
          this.searchForm.arriving = null
        }
      }
    },

    // property passed in
    nights: {
      handler: function (newValue) {
        if (this.selectedCheckIn !== null && this.nights !== null) {
          this.searchForm.nights = newValue
        } else {
          this.searchForm.nights = 0
          this.searchForm.arriving = null
        }
      }
    },

    // search form model
    nightsSelectorModel: {
      handler: function (newVal) {
        if (this.searchForm.arriving !== null) {
          this.searchForm.nights = newVal.value
          this.updateSelectedDates()
        }
      },
      deep: true
    }
  },

  methods: {
    dateChanged () {
      if (this.searchForm.nights !== 0 && this.searchForm.nights !== null) {
        this.nightsSelectorModel = this.nightsSelectedOnMount()
        this.updateSelectedDates()
      }

      this.nightsSelectorOptions = this.nightsSelect()
    },

    nightsSelect () {
      const nightsArr = []
      for (let i = this.minNights; i <= this.maxNights; i++) { // iterate through each night
        const selectedCheckIn = this.$moment(this.searchForm.arriving)

        const nightObj = {
          label: i + ' nights ' + '(checkout ' + selectedCheckIn.add(i, 'days').format('ddd Do MMM YYYY') + ')',
          value: i
        }

        nightsArr.push(nightObj)
      }

      return nightsArr
    },

    nightsSelectedOnMount () {
      const selectedCheckIn = this.$moment(this.searchForm.arriving)

      return {
        label: this.searchForm.nights + ' nights ' + '(checkout ' + selectedCheckIn.add(this.searchForm.nights, 'days').format('ddd Do MMM YYYY') + ')',
        value: this.searchForm.nights
      }
    },

    // Runs when a date is selected.
    // Updates search form data object state
    // Emits event of checkin date and nights
    updateSelectedDates () {
      const computedCheckout = this.$moment(this.searchForm.arriving).add(this.searchForm.nights, 'days').format('YYYY-MM-DD')

      const obj = {
        checkIn: this.$moment(this.searchForm.arriving).format('YYYY-MM-DD'),
        checkOut: computedCheckout,
        nights: this.searchForm.nights
      }
      this.$emit('datesSelected', obj)
    }
  },

  data () {
    return {
      nightsSelectorOptions: [],
      nightsSelectorModel: null,
      searchForm: {
        arriving: this.selectedCheckIn ? this.$moment(this.selectedCheckIn, 'YYYY-MM-DD').toDate() : null,
        nights: this.nights ? this.nights : 0
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/widgets/searchform.scss';
</style>
