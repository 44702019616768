export default {
  global: {
    header: {
      navigation: {
        properties: 'Search Properties',
        optionalExtras: 'Optional Extras',
        communities: 'Communities',
        aboutFlorida: 'About Florida',
        agentAdmin: 'Agent Admin'
      },
      logOut: 'Log out'
    },

    priceDisclaimer: 'Price includes all taxes, breakage insurance and charges',
    optionalExtrasNonCommissionable: 'These optional extras are <strong>Not</strong> commissionable',

    vcPhoneNumber: '01892 311001',
    vcEmail: 'info@villachoices.co.uk',
    passwordChangedMessage: {
      preLink: 'Your password has been successfully changed. Please return to ',
      postLink: ' page to sign back in using your new password.'
    }
  },

  bankAccount: {
    name: 'Debbie\'s Villas',
    sort: '60-21-28',
    number: '55159648'
  },

  widgets: {
    sendPdf: {
      recipientMessages: {
        property: 'Please find attached a PDF of the {bedrooms} bedroom {propertyTypeName} on {communityFullName} community (Property ref {propertyCode}) we spoke about. If I can be of any further assistance please do not hesitate to contact me.',
        community: 'Please find attached a PDF of {communityFullName} community we spoke about. If I can be of any further assistance please do not hesitate to contact me.',
        quote: 'Please find attached the quote for your Orlando {propertyTypeName} as discussed. Please check carefully that the dates and property match what we discussed. All prices quoted are in "real-time" and are subject to change at any point up until the moment of booking.',
        guide: 'Please find attached the {guideName} PDF document.'
      },
      buttonNames: {
        property: 'Send Property Details',
        community: 'Send Community Details',
        quote: 'Send / Generate Quote',
        guide: 'Send this PDF'
      },
      modalTitles: {
        property: 'Send a PDF of this property',
        community: 'Send a PDF of this community',
        quote: 'Generate and send a PDF of this quote',
        guide: 'Send this PDF'
      },
      ccApiUser: 'Send myself a copy of this email'
    }
  },

  pages: {
    checkout: {
      customerDetails: {
        registerSubTitle: 'Register customer information',
        introText: 'You\'re nearly there, we just need you to fill in the passenger information below.'
      }
    },

    villas: {
      villaCount: {
        chooseFrom: 'Choose from',
        propertyType: 'villas',
        matching: 'matching your current search criteria',
        noVillas: 'Sorry we do not have any',
        noVillasHelper: 'Please modify your search and try again.'
      }
    },

    villaInfo: {
      overview: {
        propertyRef: 'Property reference',
        bedrooms: 'bedroom',
        quickStats: {
          bedrooms: 'Bedrooms',
          sleeps: 'Sleeps',
          bathrooms: 'Bathrooms'
        }
      },

      pricingInfo: {
        checkAvailability: 'Check availability'
      },

      generalInfo: {
        facillities: {
          title: 'Facillities'
        },

        accommodation: {
          title: 'Accommodation',
          kingBeds: 'King beds',
          queenBeds: 'Queen beds',
          doubleBeds: 'Double/Full beds',
          singleBeds: 'Single beds',
          enSuite: 'En-suite'
        },

        squareFootage: {
          title: 'Square Footage',
          unit: 'ft'
        },

        occupancy: {
          title: 'Maximum Occupancy',
          excluding: 'excluding infants in cots'
        },

        floors: {
          title: 'Number of Floors'
        },

        bedrooms: {
          title: 'Bedrooms'
        },

        additionalBathrooms: {
          title: 'Additional Bathrooms'
        }
      },

      propertyDetails: {
        title: 'Villa Details',
        communityLink: 'Read more about {communityName}',

        accomodation: {
          title: 'Living Accommodation'
        },

        bedrooms: {
          title: 'Bedrooms'
        },

        outdoors: {
          title: 'Outdoor Living'
        },

        entertainment: {
          title: 'Entertainment'
        },

        disabledMods: {
          title: 'Accessibility'
        },

        poolSafety: {
          title: 'Pool Safety'
        }
      },

      numberOfNightsModal: {
        submitButton: 'Submit',
        minNightsErrorMessage: 'Minimim number of nights will not fit in here. Please choose a different date.'
      }
    }
  }
}
