<template>
  <div id="flexibleDateSelector">
    <h2 v-if="showTitle">
      Flexible with your dates?
    </h2>

    <div class="outer">
      <b-link
        class="day"
        href="#"
        @click="datesFetch(date)"
        v-bind:class="{
          today: date.today === 0,
          yesterday: date.today === -1,
          tomorrow: date.today === 1,
          'hidden-sm': (date.today <= -3) || (date.today >= 3),
          'hidden-xs': (date.today <= -2) || (date.today >= 2),
          disabled: checkIfDisabled(date.dateFormatted) }"
        v-for="(date, key) in searchDates"
        :key="key">

        <div class="inner">
          <span class="weekday">{{ date.day }}</span>
          <span class="date">{{ date.date }}</span>
          <span class="month">{{ date.month }}</span>
          <span class="count">({{ checkIfDisabled(date.dateFormatted) ? 0 : date.count }})</span>
        </div>

      </b-link>
    </div>
  </div>
</template>

<script>
// import qs from 'qs'
export default {
  name: 'FlexibleDates',
  props: {
    showTitle: Boolean,
    searchDates: Array,
    earliestBookableDate: String,
    latestBookableDate: String
  },
  methods: {
    checkIfDisabled (proposedDate) {
      return this.$moment(proposedDate, 'YYYY-MM-DD') < this.$moment(this.earliestBookableDate, 'YYYY-MM-DD') || this.$moment(proposedDate, 'YYYY-MM-DD') > this.$moment(this.latestBookableDate, 'YYYY-MM-DD')
    },

    datesFetch (date) {
      // event.preventDefault()
      if (date.today !== 0) {
        if (!this.checkIfDisabled(date.dateFormatted)) {
          this.$emit('flexibleDateSelected', date.dateFormatted)
        }
      }
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/widgets/flexibledateselector.scss';
</style>
