<template>
  <b-row>
    <b-col lg="12">
      <b-form-group id="additionalDetailsInfoGroupDriving" label="Will they be driving? *" label-for="additionalDetailsInfo">
        <b-form-select id="additionalDetailsInfoDriving" name="isDriving" v-model="additionalInfo.isDriving"
          @input="updateReturnValues" @change="updateReturnValues" :options="[
            { value: '', text: 'Please select' },
            { value: '1', text: 'Yes' },
            { value: '0', text: 'No' }
          ]" v-validate="{ required: true }" :state="validationState('isDriving')">
        </b-form-select>
        <b-form-invalid-feedback v-if="errors.first('isDriving')">
          {{ errors.first('isDriving').replace('isDriving', 'is Driving') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="additionalDetailsInfoGroupDob" label="Date of birth *" label-for="additionalDetailsInfo">
        <b-form-input id="additionalDetailsInfoDob" name="dob" v-model="additionalInfo.dob" @input="updateReturnValues"
          @change="updateReturnValues" type="date" v-validate="{ required: validateDob }"
          :state="validationState('dob')"></b-form-input>
        <b-form-invalid-feedback v-if="errors.first('dob')">
          {{ errors.first('dob').replace('dob', 'Date of Birth') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'additional-details',
  props: {
    formData: {
      type: Object,
      required: false
    },
    validateDob: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    if (this.formData) {
      for (const key in this.formData) {
        this.additionalInfo[key] = this.formData[key]
      }
      this.$validator.validate()
    }
  },
  methods: {
    validator () {
      return this.$validator
    },
    updateReturnValues () {
      for (const key in this.additionalInfo) {
        if (this.additionalInfo.hasOwnProperty(key)) {
          this.output.data[key] = this.additionalInfo[key]
        }
      }
      this.$emit('updated', this.output)
    },

    validationState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    }
  },
  data () {
    return {
      additionalInfo: {
        isDriving: '',
        dob: ''
      },
      output: {
        data: {
          isDriving: '',
          dob: ''
        }
      }
    }
  }
}
</script>
