<template>
  <b-container id="Signup">
    <div v-if="loaded">
      <div v-if="invalidCredentials">
        <h1>Agent Signup</h1>
        <section>
          <p>{{errorMessage}}</p>
        </section>
      </div>
      <div v-else>
        <h1>Agent Signup</h1>
        <div v-if="signupSuccess">
          <section>
            <p>Thank you, you’re now all set to <b-link to='/login'>sign-in</b-link> to our website using the information you’ve just provided. If you encounter any problems then please email us at <b-link :href="'mailto:' + $t('global.vcEmail')">{{ $t('global.vcEmail') }}</b-link></p>
          </section>
        </div>
        <div v-else>
          <section>
            <p>Fill in the details below. These details will be used on all forms and correspondence you send your customers.</p>
          </section>
          <b-form @submit.prevent="checkForm" autocomplete="off" :novalidate="true">
            <fieldset :disabled="signupFormSubmitting">
              <section>
                <h2>Contact details:</h2>
                <contact-name @updated="nameUpdated" ref="name"></contact-name>
              </section>
              <section>
                <h2>Phone numbers for customer use:</h2>
                <contact-info @updated="contactInfoUpdated" ref="contactInfo" :excludeFields="['clientEmail']" :telCols="6" :mobileRequired="false"></contact-info>
              </section>
              <section>
                <password-and-confirm @updated="passwordUpdated" ref="password" passwordLabel="Choose a Password"></password-and-confirm>
              </section>
              <article class="page-content text-center">
                <loader v-if="signupFormSubmitting"></loader>
                <div v-else>
                  <b-btn variant="primary" size="lg" type="submit">
                    Signup
                  </b-btn>
                  <p v-if="formValidationErrors === true">Please fix the form errors above before submitting this form</p>
                </div>
              </article>
            </fieldset>
          </b-form>
          <b-modal ref="serverValidationErrors" title="The following form fields contain errors" header-text-variant="light" centered header-bg-variant="danger" ok-only>
            <ul v-if="serverValidationErrors">
              <li v-for="theError in validationErrors">
                {{theError.error}}
              </li>
            </ul>
          </b-modal>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="loader-outer">
        <loader width="100px" height="100px"></loader>
      </div>
    </div>
  </b-container>
</template>

<script>
import loader from '@/components/widgets/loader'
import contactName from '@/components/widgets/form-fields/name'
import passwordAndConfirm from '@/components/widgets/form-fields/password-and-confirm'
import contactInfo from '@/components/widgets/form-fields/contact-info'

export default {
  name: 'Signup',

  components: {
    loader,
    'contact-name': contactName,
    'password-and-confirm': passwordAndConfirm,
    'contact-info': contactInfo
  },

  mounted () {
    this.checkSignupDetails()
  },

  methods: {

    validationState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    },

    nameUpdated (event) {
      this.formValidationErrors = false
      this.signupUserDetails.title = event.data.title
      this.signupUserDetails.forename = event.data.forename
      this.signupUserDetails.surname = event.data.surname
      this.signupUserDetails.clientCompName = event.data.clientCompName
    },

    passwordUpdated (event) {
      this.formValidationErrors = false
      this.signupUserDetails.password = event.data.password
      this.signupUserDetails.passwordConfirm = event.data.passwordConfirm
    },

    contactInfoUpdated (event) {
      this.formValidationErrors = false
      this.signupUserDetails.clientTelephone = event.data.clientTelephone
      this.signupUserDetails.clientMobile = event.data.clientMobile
    },

    checkForm (evt) {
      this.signupFormSubmitting = true
      this.formValidationErrors = false
      const nameValidator = this.$refs.name.validator()
      const passwordValidator = this.$refs.password.validator()
      const contactInfoValidator = this.$refs.contactInfo.validator()
      Promise.all([nameValidator.validate(), passwordValidator.validate(), contactInfoValidator.validate()]).then((values) => {
        if (values.includes(false)) {
          this.signupFormSubmitting = false
          this.formValidationErrors = true
        } else {
          this.submitForm()
        }
      })
    },

    submitForm () {
      const apiUrl = '/agent/signup/' + this.$route.params.agentInviteId + '/' + this.$route.params.token
      this.$apiCaller({ route: apiUrl, method: 'POST', data: this.signupUserDetails, displayErrorAlert: false }).then((response) => {
        this.signupSuccess = true // change to true to progress to next form
      })
        .catch((error) => {
          const responseData = error.response.data.data
          if (responseData.hasOwnProperty('response') && responseData.response.hasOwnProperty('validationErrors')) {
            this.serverValidationErrors = true
            this.validationErrors = responseData.response.validationErrors
            this.$refs.serverValidationErrors.show()
          } else {
            this.invalidCredentials = true
            this.errorMessage = 'Sorry we are unable to locate your agent invite information, check with the organisation that sent you the invite, it may be that the invite invitation link has expired.'
          }
        })
        .finally(() => {
          this.loaded = true
        })
      this.signupFormSubmitting = false
    },

    checkSignupDetails () {
      this.loaded = false
      const apiUrl = '/agent/signup/' + this.$route.params.agentInviteId + '/' + this.$route.params.token
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '', displayErrorAlert: false }).then((response) => {
        // id and token are good
      })
        .catch(() => {
          this.invalidCredentials = true
          this.errorMessage = 'Sorry we are unable to locate your agent invite information, check with the organisation that sent you the invite, it may be that the invite invitation link has expired.'
        })
        .finally(() => {
          this.loaded = true
        })
    }
  },

  data () {
    return {
      loaded: false,
      invalidCredentials: false,
      signupFormSubmitting: false,
      formValidationErrors: false,
      serverValidationErrors: false,
      signupSuccess: false,
      signupUserDetails: {
        title: '',
        forename: '',
        surname: '',
        clientCompName: '',
        clientTelephone: '',
        clientMobile: '',
        password: '',
        passwordConfirm: ''
      }
    }
  }
}
</script>
