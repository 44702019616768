<template>
  <b-container id="themeParkRides" v-if="!loading">
    <b-row align-h="center">
      <b-col md="8" lg="9">
        <h1>{{ themeparkPage.name }}</h1>

        <article>
          <section class="page-content">
            <b-card id="rideImage" v-bind:class="{'ride-stats-right': themeparkPage.templateType === 'default', 'ride-stats-left': themeparkPage.templateType === 'extended'}">
              <div class="inner">
                <div class="img-container">
                  <b-img fluid-grow :src="headImageUrl"
                         alt="Header image"></b-img>
                </div>
                <div class="ride-stats">
                  <ul>
                    <li v-if="themeparkPage.points" v-for="(info, key) in themeparkPage.points" :key="key">
                      <b-img :src="info.icon"></b-img>
                      <div class="text-container">
                        <strong>{{ info.pointName }}</strong>
                        {{ info.text }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <p class="card-text">
                {{ themeparkPage.description }}
              </p>
            </b-card>
          </section>

          <section id="rideFactsReviews">
            <b-row>
              <b-col v-if="themeparkPage.testimonials || leftImage" cols="12" :md="(!rightImage && !themeparkPage.facts) ? 12 : 6">
                <section class="page-content">
                  <ul class="testimonials">
                    <li v-for="(testimonial, index) in themeparkPage.testimonials">
                      <div class="inner text-center">
                        {{ testimonial }}
                      </div>
                    </li>
                  </ul>
                </section>

                <section class="page-content" v-if="leftImage">
                  <div class="img-container" :style="'background-image:' + ' url(' + leftImage + ');'"></div>
                </section>
              </b-col>

              <b-col v-if="rightImage || themeparkPage.facts" cols="12" :md="(!themeparkPage.testimonials && !leftImage) ? 12 : 6">
                <section class="page-content" v-if="rightImage">
                  <div class="img-container" :style="'background-image:' + ' url(' + rightImage + ');'"></div>
                </section>

                <section class="page-content tips" v-if="themeparkPage.facts">
                  <h2>Tips and Facts</h2>
                  <ul class="facts">
                    <li v-for="(fact, index) in themeparkPage.facts">{{ fact }}</li>
                  </ul>
                </section>
              </b-col>
            </b-row>
          </section>

          <section class="page-content" id="characterGrid" v-if="themeparkPage.subInfo">
            <h2>{{ themeparkPage.subInfo.heading }}</h2>
            <b-row>
              <b-col md="6" lg="3" class="character-col" v-for="(point, index) in themeparkPage.subInfo.points" :key="index">
                <div class="character">
                  <h4>
                    {{ point.heading }}
                    <small>{{ point.subHeading }}</small>
                  </h4>
                  <p>
                    {{ point.text }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </section>

          <section class="page-content" v-if="themeparkPage.otherAttractions" id="otherRides">
            <h2>Other Attractions</h2>
            <b-row>
              <b-col v-for="(ride, index) in themeparkPage.otherAttractions" :key="index">
                <b-link :to="'/about-florida/theme-parks/' + $route.params.parkId + '/' + ride.item_id" class="other-ride">
                  <b-card :img-src="ride.img.imgUrl"
                          :img-alt="ride.name"
                          img-top>
                    <p class="card-text">
                      {{ ride.name }}
                    </p>
                  </b-card>
                </b-link>
              </b-col>
            </b-row>
          </section>
        </article>
      </b-col>
      <b-col md="4" lg="3" v-if="a_to_zItems">
        <side-bar title="A - Z of" :title-small="themeParkName" :items="a_to_zItems"></side-bar>
      </b-col>
    </b-row>
  </b-container>

  <div class="loader-outer" v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

const SideBar = () => ({
  component: import('@/components/widgets/side-bar'),
  loading: loader
})

export default {
  name: 'ThemeParks',
  components: {
    SideBar,
    loader
  },

  mounted () {
    this.getPageContent()
  },

  computed: {
    a_to_zItems () {
      let ret = false

      if (this.themeparkPage) {
        if (this.themeparkPage.themeParkInfo) {
          if (this.themeparkPage.themeParkInfo.azitems) {
            if (this.themeparkPage.themeParkInfo.azitems.length > 0) {
              const aToz = []
              for (let i = 0; i < this.themeparkPage.themeParkInfo.azitems.length; i++) {
                const item = this.themeparkPage.themeParkInfo.azitems[i]
                const obj = {
                  url: '/about-florida/theme-parks/' + this.$route.params.parkId + '/' + item.item_id,
                  name: item.name,
                  active: item.active ? item.active : false
                }
                aToz.push(obj)
              }

              ret = aToz
            }
          }
        }
      }

      return ret
    },

    themeParkName () {
      let ret = ''

      if (this.themeparkPage) {
        if (this.themeparkPage.themeParkInfo) {
          if (this.themeparkPage.themeParkInfo.name) {
            ret = this.themeparkPage.themeParkInfo.name
          }
        }
      }

      return ret
    },

    headImageUrl () {
      let ret = ''

      if (this.themeparkPage) {
        if (this.themeparkPage.headImage) {
          if (this.themeparkPage.headImage.imgUrl) {
            ret = this.themeparkPage.headImage.imgUrl
          }
        }
      }

      return ret
    },

    leftImage () {
      let ret = false

      if (this.themeparkPage) {
        if (this.themeparkPage.subImages) {
          if (this.themeparkPage.subImages.length > 1) {
            ret = this.themeparkPage.subImages[1].imgUrl
          }
        }
      }

      return ret
    },

    rightImage () {
      let ret = false

      if (this.themeparkPage) {
        if (this.themeparkPage.subImages) {
          if (this.themeparkPage.subImages.length > 0) {
            ret = this.themeparkPage.subImages[0].imgUrl
          }
        }
      }

      return ret
    }
  },

  watch: {
    '$route.query': {
      handler (newVal) {
        this.getPageContent()
      },
      deep: true
    }
  },

  methods: {
    getPageContent () {
      this.loading = true
      this.themeparkPage = {}
      const apiUrl = this.$route.fullPath
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        this.themeparkPage = response.data.response
        const title = 'About Florida / Theme Parks / ' + this.themeparkPage.themeParkInfo.name + ' / ' + this.themeparkPage.name
        this.$emit('setPageMetaInfo', { title })
        this.loading = false
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },

  data () {
    return {
      loading: true,
      themeparkPage: {}
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/about-florida/theme-parks/ThemeParksGlobal.scss';
  @import '@/assets/styles/pages/about-florida/theme-parks/ThemeParkRides.scss';
</style>
