<template>
  <b-container id="villaExtras" v-if="!loading">
    <masthead image-url="villa_extras.jpg" title="Optional Extras"></masthead>
    <b-alert show variant="danger" v-if="$store.getters.getAgentView" v-html="$t('global.optionalExtrasNonCommissionable')"></b-alert>
    <article class="page-content" v-for="(extra, key) in bookingExtras" :key="key" v-if="(Object.keys(bookingExtras).length > 0)">
      <h2>{{ key }}</h2>
      <b-row>
        <b-col v-for="(item, key) in extra.products" :key="key" cols="12">
          <section>
            <h3 class="my-4">{{ item.extraName }}</h3>
            <b-row>
              <b-col cols="12" lg="4" class="text-center mb-4 text-lg-left mb-lg-0">
                <img :src="item.imgUrl" :alt="item.extraName" />
              </b-col>
              <b-col cols="12" lg="8">
                <p>
                  {{ item.extraDesc }}
                </p>

                <template v-if="item.seasons && item.seasons.length">
                  <p class="mt-3"><strong>Please note this extra is seasonal and only available if you book dates within any of the following date ranges:</strong></p>
                  <ul>
                    <li v-for="(season, seasonkey) in item.seasons" :key="seasonkey">
                      {{ formatSeason(season) }}
                    </li>
                  </ul>
                </template>

                <strong v-html="splitBreakdown(item.extraBreakdown)"></strong>

                <template  v-if="item.extraDescAdditional">
                  <p class="descriptionAdditional" v-for="(desc, key) in item.extraDescAdditional" :key="key">
                    <em>
                      {{ desc }}
                    </em>
                  </p>
                </template>
              </b-col>
            </b-row>
          </section>
        </b-col>
      </b-row>
    </article>
  </b-container>
  <div class="loader-outer" v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

const Masthead = () => ({
  component: import('@/components/widgets/masthead'),
  loading: loader
})

export default {
  name: 'OptionalExtras',

  components: {
    Masthead,
    loader
  },

  mounted () {
    this.getExtras()
  },

  methods: {
    formatSeason (season) {
      const format = 'Do MMMM YYYY'
      const start = this.$moment(season.startDate, 'YYYY-MM-DD').format(format)
      const end = this.$moment(season.endDate, 'YYYY-MM-DD').format(format)
      return start + ' - ' + end
    },
    splitBreakdown (breakdown) {
      if (!breakdown) {
        return ''
      }

      return breakdown.replace(' Minimum', '<br />Minimum')
    },

    getExtras () {
      this.bookingExtras = {}
      this.loading = true
      const apiUrl = this.$route.fullPath
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        this.bookingExtras = response.data.response
        this.loading = false
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    },

    parseCategory (key) {
      return (key.charAt(0).toLocaleUpperCase() + key.slice(1)).replace(/_/g, ' ')
    }
  },

  data () {
    return {
      loading: true,
      bookingExtras: {}
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/villas/VillaExtras.scss';
</style>
