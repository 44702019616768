<template>
  <b-container id="wildLife" v-if="!loading">
    <masthead :image-url="wildlifePage.mainImage" :title="wildlifePage.mainHeading"></masthead>

    <b-row>
      <b-col md="8" lg="9">
        <article class="page-content" v-if="wildlifePage.openingParagraph">
          <h2>{{ wildlifePage.openingHeading }}</h2>
          <p>
            {{ wildlifePage.openingParagraph }}
          </p>
        </article>

        <article class="page-content wildlife-info" v-if="wildlifePage.wildlifeSections" v-for="(section, index) in wildlifePage.wildlifeSections">
          <h2>{{ section.name }}</h2>
          <p v-if="section.sectionDescription" class="mb-4">
            {{ section.sectionDescription }}
          </p>

          <section v-for="(animal, animalIndex) in section.items">
            <h3>
              {{ animal.name }}
              <small v-if="animal.nameSmall">{{ animal.nameSmall }}</small>
            </h3>
            <b-row>
              <b-col v-if="animal.img" md="4">
                <b-img :src="animal.img" :alt="animal.name" fluid-grow></b-img>
              </b-col>
              <b-col v-if="animal.name">
                <p v-if="animal.description">
                  {{ animal.description }}
                </p>
              </b-col>
            </b-row>
          </section>
        </article>
      </b-col>
      <b-col md="4" lg="3">
        <side-bar v-if="wildlifePage.bulletPoints" :title="wildlifePage.bulletPoints.title" :items="wildlifePage.bulletPoints.points" bullets></side-bar>
      </b-col>
    </b-row>
  </b-container>

  <div class="loader-outer" v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

const Masthead = () => ({
  component: import('@/components/widgets/masthead'),
  loading: loader
})

const SideBar = () => ({
  component: import('@/components/widgets/side-bar'),
  loading: loader
})

export default {
  name: 'Wildlife',
  components: {
    Masthead,
    SideBar,
    loader
  },

  mounted () {
    this.getPageContent()
  },

  methods: {
    parseSectionName (key) {
      return (key.charAt(0).toLocaleUpperCase() + key.slice(1)).replace(/_/g, ' ')
    },

    getPageContent () {
      this.loading = true
      this.wildlifePage = {}
      const apiUrl = this.$route.fullPath
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        this.wildlifePage = response.data.response
        this.loading = false
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },
  data () {
    return {
      loading: true,
      wildlifePage: {}
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/about-florida/WildLife.scss';
</style>
