<template>
  <b-container id="checkout">
    <!--<div style="background-color:red;margin:5px;line-height:30px;">DEBUG: {{$route.params.product}} / {{$route.params.stage}} / {{$route.params.ref}}</div>-->
    <OptionalExtras v-if="($route.params.stage === 'optional-extras')"></OptionalExtras>
    <CustomerDetails v-if="($route.params.stage === 'customer-details')"></CustomerDetails>
  </b-container>
</template>

<script>
import loader from '@/components/widgets/loader'

const OptionalExtras = () => ({
  component: import('@/components/pages/checkout/stages/OptionalExtras'),
  loading: loader
})

const CustomerDetails = () => ({
  component: import('@/components/pages/checkout/stages/CustomerDetails'),
  loading: loader
})

export default {
  name: 'Checkout',
  components: {
    OptionalExtras,
    CustomerDetails
  }
}
</script>
