<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group
          id="leadTitleGroup"
          label="Title"
          label-for="title"
          label-class="required">
          <b-form-input
            autocomplete="off"
            @input="updateReturnValues"
            @change="updateReturnValues"
            id="title"
            name="title"
            data-vv-as="title"
            :state="validationState('title')"
            v-validate="{required: true, min: 2, max: 10}"
            maxlength="10"
            type="text"
            v-model="person.title"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('title') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          id="leadForenameGroup"
          label="Forename"
          label-for="leadForename"
          label-class="required">
          <b-form-input
            autocomplete="off"
            @input="updateReturnValues"
            @change="updateReturnValues"
            id="leadForename"
            name="leadForename"
            v-validate="{required: true, min:2, max:50}"
            data-vv-as="forename"
            :state="validationState('leadForename')"
            maxlength="50"
            type="text"
            v-model="person.forename"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('leadForename') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          id="leadSurnameGroup"
          label="Surname"
          label-for="leadSurname"
          label-class="required">
          <b-form-input
            autocomplete="off"
            @input="updateReturnValues"
            @change="updateReturnValues"
            id="leadSurname"
            name="leadSurname"
            v-validate="{required: true, min:2, max:50}"
            data-vv-as="surname"
            :state="validationState('leadSurname')"
            maxlength="50"
            type="text"
            v-model="person.surname"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('leadSurname') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!fieldsToExclude.includes('clientCompName')">
      <b-col>
        <b-form-group
          id="clientCompName"
          label-for="clientCompName">
          <template #label>Company Name<small> (Leave blank if it's the same as your organisation name)</small></template>
          <b-form-input
            autocomplete="off"
            @input="updateReturnValues"
            @change="updateReturnValues"
            id="clientCompName"
            name="clientCompName"
            v-validate="{required: false, min:5, max:50}"
            data-vv-as="company name"
            :state="validationState('clientCompName')"
            maxlength="50"
            type="text"
            v-model="person.clientCompName"></b-form-input>
          <b-form-invalid-feedback>
            {{ errors.first('clientCompName') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'name-fields',

  props: {
    formData: {
      type: Object,
      required: false
    },
    excludeFields: {
      type: Array,
      required: false
    }
  },

  beforeMount () {
    this.fieldsToExclude = []
    if (this.excludeFields) {
      for (const fieldKey in this.excludeFields) {
        const fieldName = this.excludeFields[fieldKey]
        this.$delete(this.person, fieldName)
        this.$delete(this.output.data, fieldName)
        this.fieldsToExclude.push(fieldName)
      }
    }
  },

  mounted () {
    if (this.formData) {
      for (const key in this.formData) {
        this.person[key] = this.formData[key]
      }
      this.$validator.validate()
    }
  },

  methods: {
    validator () {
      return this.$validator
    },
    updateReturnValues () {
      for (const key in this.person) {
        if (this.person.hasOwnProperty(key)) {
          this.output.data[key] = this.person[key]
        }
      }
      this.$emit('updated', this.output)
    },

    validationState (ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref)
      }
      return null
    }
  },

  data () {
    return {
      fieldsToExclude: [],
      person: {
        title: '',
        forename: '',
        surname: '',
        clientCompName: ''
      },

      output: {
        data: {
          title: '',
          forename: '',
          surname: '',
          clientCompName: ''
        }
      }
    }
  }
}
</script>
