<template>
  <div class="container" v-if="!loading">
    <pdf-generator ref="pdfGen" :type="pdfGen.type" :typeId="pdfGen.typeId" :recipientMessage="pdfGen.recipientMessage"></pdf-generator>
    <div class="community-overview">
      <b-row>
        <b-col md="6" lg="8">
          <h1>{{ communityInfo.fullName }}</h1>
          <template v-if="communityInfo.hasOwnProperty('descriptions')">
            <div v-if="communityInfo.descriptions.short" v-html="communityInfo.descriptions.short.description"></div>
          </template>
        </b-col>

        <b-col md="6" lg="4">
          <aside class="send-info">
            <h2>Send Community Info</h2>

            <b-btn variant="primary" class="send-info-button " @click="pdfButtonClicked('community')">
              {{ 'widgets.sendPdf.buttonNames.community' | translate }}
            </b-btn>

          </aside>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col>
        <dv-carousel :images="communityInfo.images"></dv-carousel>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4">
        <div class="general-info" v-if="communityInfo.icons || communityInfo.distances">
          <div class="info-item" v-if="communityInfo.icons">
            <h3>Facilities</h3>
            <ul class="icon-list">
              <li v-for="(item, key) in communityInfo.icons">
                <b-img :src="require('@/assets/images/icons/' + item.icon + '.png')"></b-img> <small>{{ item.name }}</small>
              </li>
            </ul>
          </div>

          <div class="info-item" v-if="communityInfo.distances">
            <h3>Driving Times</h3>
            <ul>
              <li v-for="(drivingTime, key) in communityInfo.distances">
                <strong>{{ drivingTime.name }}</strong>: {{ drivingTime.formatted }}
              </li>
            </ul>
          </div>
        </div>
      </b-col>

      <b-col md="8">
        <article class="community-description">
          <template v-if="communityInfo.hasOwnProperty('descriptions')">
            <h2>Community Information</h2>
            <template v-for="(descriptionSection) in communityInfo.descriptionsOrder">
              <template v-if="communityInfo.descriptions.hasOwnProperty(descriptionSection)">
                <h3>{{communityInfo.descriptions[descriptionSection].descriptionTypeName}}</h3>
                <div v-html="communityInfo.descriptions[descriptionSection].description"></div>
              </template>
            </template>
          </template>
          <p v-if="communityInfo.longDesc">{{ communityInfo.longDesc }}</p>
          <p>
            <b-link :to="'/villas?filters%5Bcommunity%5D=' + this.$route.params.id">See available properties on {{ communityInfo.fullName }}</b-link>
          </p>
        </article>

        <b-img class="communityMap" blank-color="#bbb" :src="communityInfo.mapUrl" fluid :alt="communityInfo.fullName" />

        <article class="map-driving">
          <b-row>
            <b-col>
              <h3>Map Key</h3>
              <ol class="key">
                <li>Epcot</li>
                <li>Magic Kingdom</li>
                <li>Universal Studios</li>
                <li>Sea World</li>
                <li>Discovery Cove</li>
              </ol>
            </b-col>
          </b-row>
        </article>
      </b-col>
    </b-row>
  </div>

  <div class="loader-outer" v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'
import pdfGenerator from '@/components/widgets/pdf-generator'

const DVCarousel = () => ({
  component: import('@/components/widgets/carousel'),
  loading: loader
})

export default {
  name: 'CommunityInfo',
  components: {
    'dv-carousel': DVCarousel,
    loader,
    'pdf-generator': pdfGenerator
  },

  mounted () {
    this.getCommunityInfo()
  },

  methods: {
    pdfButtonClicked (type) {
      switch (type) {
        case 'community':
          this.pdfGen.type = type
          this.pdfGen.typeId = this.communityInfo.comId
          const pdfMessage = this.$t('widgets.sendPdf.recipientMessages.community', {
            communityFullName: this.communityInfo.fullName
          })
          this.pdfGen.recipientMessage = pdfMessage
          this.$refs.pdfGen.init()
          break
      }
    },

    splitSeasonName (seasonName) {
      const season = seasonName.replace(' ', '<br />')

      return season
    },

    getCommunityInfo () {
      this.loading = true
      this.communityInfo = {}
      const apiUrl = this.$route.fullPath
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        this.communityInfo = response.data.response
        this.communityPdfApiEndpoint = 'send-pdf/community/' + response.data.response.comId
        if (this.communityInfo) {
          const title = 'Communities / ' + this.communityInfo.fullName
          this.$emit('setPageMetaInfo', { title })
        }
        this.loading = false
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },

  data () {
    return {
      pdfGen: {
        type: '',
        typeId: '',
        recipientMessage: ''
      },
      loading: true,
      communityPdfApiEndpoint: '',
      communityInfo: {}
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/communities/CommunityInfo.scss';
</style>
