<template>
  <b-container id="weather" v-if="!loading">
    <masthead :image-url="weatherPage.mainImage" :title="weatherPage.mainHeading"></masthead>

    <b-row>
      <b-col md="8" lg="9">
        <article class="page-content" v-if="weatherPage.openingParagraph">
          <h2>{{ weatherPage.openingHeading }}</h2>

          {{ weatherPage.openingParagraph }}
        </article>

        <article v-for="(chart, index) in weatherPage.charts" :key="index" class="page-content">
          <h2 v-if="chart.name">{{ chart.name }}</h2>
          <p v-if="chart.paragraph" style="margin-bottom:10px;">{{ chart.paragraph }}</p>
          <b-img v-if="chart.img" :src="chart.img" :alt="chart.name" fluid-grow></b-img>
        </article>

        <article class="page-content" v-if="weatherPage.textSections" v-for="(section, index) in weatherPage.textSections">
          <h2>{{ section.name }}</h2>
          <p v-for="(paragraph, index) in section.paragraphs">{{paragraph}}</p>
        </article>

        <article class="page-content">
          <section id="topNav" v-if="weatherPage.weatherMonths">
            <h2>Weather Month by Month</h2>
            <ol class="weather_nav">
              <li v-for="(month, key) in weatherPage.weatherMonths" :key="key">
                <b-link v-scroll-to="'#' + 'scrollNav_' + key">Weather in {{ month.month }}</b-link>
              </li>
            </ol>
          </section>

          <section v-for="(month, key) in weatherPage.weatherMonths" :key="key" :id="'scrollNav_' + key">
            <h2>{{ month.heading }}
              <small>
                <b-link v-scroll-to="'#topNav'">Back to list <span class="smaller">▲</span></b-link>
              </small>
            </h2>
            <ul class="sunrise_sunset">
              <li>
                <strong>Sunrise:</strong> Start of the month {{ month.sunrise.startOfMonth }}, end of the month {{ month.sunrise.endOfMonth }}
              </li>

              <li>
                <strong>Sunset:</strong> Start of the month {{ month.sunset.startOfMonth }}, end of the month {{ month.sunset.endOfMonth }}
              </li>
            </ul>

            <p v-if="month.timeChange">
              <em>{{month.timeChange}}</em>
            </p>

            <p v-for="(description, key) in month.descriptions" :key="key">
              {{ description }}
            </p>
          </section>
        </article>

      </b-col>
      <b-col md="4" lg="3">
        <side-bar v-if="weatherPage.bulletPoints" :items="weatherPage.bulletPoints.points" :title="weatherPage.bulletPoints.title" bullets></side-bar>
      </b-col>
    </b-row>
  </b-container>

  <div class="loader-outer" v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

const Masthead = () => ({
  component: import('@/components/widgets/masthead'),
  loading: loader
})

const SideBar = () => ({
  component: import('@/components/widgets/side-bar'),
  loading: loader
})

export default {
  name: 'Weather',
  components: {
    Masthead,
    SideBar,
    loader
  },

  mounted () {
    this.getPageContent()
  },

  methods: {
    parseMonth (key) {
      return (key.charAt(0).toLocaleUpperCase() + key.slice(1)).replace(/_/g, ' ')
    },

    getPageContent () {
      this.loading = true
      this.weatherPage = {}
      const apiUrl = this.$route.fullPath
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        this.weatherPage = response.data.response
        this.loading = false
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },
  data () {
    return {
      loading: true,
      weatherPage: {}
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/about-florida/Weather.scss';
</style>
