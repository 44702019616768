<template>
  <b-container id="themeParks" v-if="!loading">
    <b-row align-h="center">
      <b-col md="8" lg="9">
        <h1>{{ themeparkPage.name }}</h1>
        <article>
          <section class="page-content">
            <b-card v-if="themeparkPage.headImage" :img-src="themeparkPage.headImage.imgUrl"
                    img-alt="Header image"
                    img-top>
              <p class="card-text">
                {{ themeparkPage.shortDescription }}
              </p>
            </b-card>
          </section>

          <section class="page-content" v-if="themeparkPage.overviewMarkdown">
            <h2>Overview</h2>
            <vue-markdown v-if="themeparkPage.overviewMarkdown">{{ themeparkPage.overviewMarkdown }}</vue-markdown>
          </section>
        </article>
      </b-col>
      <b-col md="4" lg="3" v-if="a_to_zItems">
        <side-bar title="A - Z of" :title-small="themeparkPage.name" :items="a_to_zItems"></side-bar>
      </b-col>
    </b-row>
  </b-container>

  <div class="loader-outer" v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

const SideBar = () => ({
  component: import('@/components/widgets/side-bar'),
  loading: loader
})

export default {
  name: 'ThemeParks',
  components: {
    SideBar,
    loader
  },

  mounted () {
    this.getPageContent()
  },

  computed: {
    a_to_zItems () {
      let ret = false

      if (this.themeparkPage) {
        if (this.themeparkPage.azitems) {
          if (this.themeparkPage.azitems.length > 0) {
            const aToz = []
            for (let i = 0; i < this.themeparkPage.azitems.length; i++) {
              const item = this.themeparkPage.azitems[i]

              const obj = {
                url: this.$route.fullPath + '/' + item.item_id,
                name: item.name
              }
              aToz.push(obj)
            }

            ret = aToz
          }
        }
      }

      return ret
    }
  },

  methods: {
    getPageContent () {
      this.loading = true
      const apiUrl = this.$route.fullPath
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        this.themeparkPage = response.data.response
        const title = 'About Florida / Theme Parks / ' + this.themeparkPage.name
        this.$emit('setPageMetaInfo', { title })
        this.loading = false
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },

  data () {
    return {
      loading: true,
      themeparkPage: {}
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/about-florida/theme-parks/ThemeParksGlobal.scss';
  @import '@/assets/styles/pages/about-florida/theme-parks/ThemeParks.scss';
</style>
