<template>
  <div>
    <div class="calendar-container" v-if="calendar">
      <b-container class="key-area">
        <b-row>
          <b-col md="3">
            <h4>Check Availability</h4>
            <p>Choose Dates</p>
          </b-col>
          <b-col md="9">
            <div class="text-right keys-area">
              <span>Availability for each season</span><br class="d-lg-none"><br class="d-lg-none">
              <template v-for="(season) in ['Available', 'Unavailable']">
                <span class="key" :class="'season-'+season.toLowerCase()">&nbsp;</span>{{ season }}
              </template>
              <template v-if="showRatesNotAvailableKey">
                <span class="key season-rates-not-available">&nbsp;</span>Unavailable - Rates not available yet
              </template>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p>Dates marked with an * indicate that a booking cannot start on this date due to minimum rental periods.</p>
          </b-col>
        </b-row>
      </b-container>

        <div class="month-container" v-for="(month, monthKey) in calendar" :key="monthKey">
          <div class="month">
            <div class="week faux-month-heading">
              <div class="day">&nbsp;</div>
              <div class="day">&nbsp;</div>
              <div class="day">&nbsp;</div>
              <div class="day">&nbsp;</div>
              <div class="day">&nbsp;</div>
              <div class="day">&nbsp;</div>
              <div class="day">&nbsp;</div>
              <h4>{{ parseMonthKey(monthKey) }}</h4>
            </div>
            <div class="week heading">
              <div class="day" v-for="(day, dayKey) in weekDays">{{ day }}</div>
            </div>

            <div class="week">
              <div class="day empty start calendarDays" v-for="es in emptyCells[monthKey]['start']">&nbsp;</div>

              <div class="day calendarDays"
                v-for="(day, dayKey) in calendar[monthKey]"
                v-on:click="dayClicked(day.date, day.disabled)"
                :class="{
                  booking: day.booking,
                  'disabled-event': day.disabled,
                  past: day.dateInPast,
                  'unbookable-day': (day.unbookableDay),
                  'available': !day.booking && !day.dateInPast && !day.unbookableDay && !day.disabled,
                  'rate-not-available': (day.rateNotAvailable)
                  }">
                  {{ dayKey }}{{!day.dateInPast && !day.booking && day.unbookableDay?' *':''}}
              </div>

              <div class="day empty end calendarDays" v-for="ee in emptyCells[monthKey]['end']">&nbsp;</div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'dv-calendar',

  props: {
    weekStarts: {
      validator: function (value) {
        const isFloat = (value - Math.floor(value)) !== 0

        if (!isFloat && (value > 0 && value <= 7)) {
          return true
        } else {
          return false
        }
      }
    },
    calendarStartMonth: {
      validator: function (value) {
        const isFloat = (value - Math.floor(value)) !== 0

        if (!isFloat && (value > 0 && value <= 12)) {
          return true
        } else {
          return false
        }
      }
    },
    calendarStartYear: {
      validator: function (value) {
        const isFloat = (value - Math.floor(value)) !== 0

        if (!isFloat && value > -1) {
          return true
        } else {
          return false
        }
      }
    },
    monthsToDisplay: {
      validator: function (value) {
        if ((value - Math.floor(value)) === 0) {
          return true
        } else {
          return false
        }
      }
    },
    calendarDates: {
      type: Object
    }
  },

  methods: {
    isDateInPast (calendarDate) {
      const cal = moment(calendarDate, 'YYYY-MM-DD')
      const now = moment()
      const diff = Math.ceil(cal.diff(now, 'days', true))

      if (diff <= 0) {
        return true
      } else {
        return false
      }
    },

    dayClicked (day, isDisabledDay) {
      if (isDisabledDay) {
        return // date in past or booked day clicked
      }
      this.$emit('calendarDayClicked', day)
    },

    generateWeekDays () {
      this.weekDays = []
      let weekday = parseInt(this.weekStarts)

      for (let i = 1; i <= 7; i++) {
        this.weekDays.push(moment(weekday, 'E').format('ddd'))
        weekday = (weekday === 7 ? 1 : weekday + 1)
      }
    },

    parseMonthKey (monthKey) {
      return moment(monthKey, 'YYYY-M').format('MMMM YYYY')
    },

    parseBookingDates (dates) {
      const allDates = []
      let i; let j; let dateBlock; let datesArray
      for (i in dates) {
        dateBlock = dates[i]
        datesArray = this.makeDatesArray(dateBlock.start, dateBlock.end)
        for (j in datesArray) {
          allDates.push(datesArray[j])
        }
      }
      return allDates
    },

    makeDatesArray (start, end) {
      const datesArray = []
      const startDate = moment(start, 'YYYY-MM-DD')
      const endDate = moment(end, 'YYYY-MM-DD')
      const numDays = endDate.diff(startDate, 'days')
      for (let d = 1; d <= (numDays + 1); d++) {
        datesArray.push(startDate.format('YYYY-MM-DD'))
        startDate.add(1, 'days')
      }
      return datesArray
    },

    parseSeasonsDates (seasonsData) {
      const seasons = {}
      let datesArray
      let i; let j; let seasonsBlock
      for (i in seasonsData) {
        seasonsBlock = seasonsData[i]
        datesArray = this.makeDatesArray(seasonsBlock.dateFrom, seasonsBlock.dateTo)
        for (j in datesArray) {
          seasons[datesArray[j]] = seasonsBlock.seasons_id.toLowerCase()
        }
      }
      return seasons
    },

    generateWeekOrder () {
      // this should probably be generated with a smart algorithm - but left here hardcoded as AM had created it - GE Jan 2021
      return {
        // week start days
        1: {
          // day of the week (as a number), and it's week position
          1: 1,
          2: 2,
          3: 3,
          4: 4,
          5: 5,
          6: 6,
          7: 7
        },
        2: {
          1: 7,
          2: 1,
          3: 2,
          4: 3,
          5: 4,
          6: 5,
          7: 6
        },
        3: {
          1: 6,
          2: 7,
          3: 1,
          4: 2,
          5: 3,
          6: 4,
          7: 5
        },
        4: {
          1: 5,
          2: 6,
          3: 7,
          4: 1,
          5: 2,
          6: 3,
          7: 4
        },
        5: {
          1: 4,
          2: 5,
          3: 6,
          4: 7,
          5: 1,
          6: 2,
          7: 3
        },
        6: {
          1: 3,
          2: 4,
          3: 5,
          4: 6,
          5: 7,
          6: 1,
          7: 2
        },
        7: {
          1: 2,
          2: 3,
          3: 4,
          4: 5,
          5: 6,
          6: 7,
          7: 1
        }
      }
    },

    calendarStructure () {
      this.generateWeekDays() // set default ordering on days of the week
      const bookings = this.parseBookingDates(this.calendarDates.bookings)
      const unbookableDays = this.parseBookingDates(this.calendarDates.unbookableDates)
      const ratesNotAvailable = this.parseBookingDates(this.calendarDates.ratesNotAvailable)
      const seasons = this.parseSeasonsDates(this.calendarDates.seasons)
      let loopDate = moment(this.calendarStartYear + '-' + moment(this.calendarStartMonth, 'M').format('MM') + '-' + '01', 'YYYY-MM-DD')
      const calendar = {}
      const emptyCells = {}
      const weekOrder = this.generateWeekOrder()

      for (let m = 0; m < this.monthsToDisplay; m++) {
        const formattedYear = loopDate.format('YYYY')
        const formattedMonth = loopDate.format('M')
        const daysInMonth = loopDate.daysInMonth()

        if (!calendar.hasOwnProperty(formattedYear + '-' + formattedMonth)) {
          calendar[formattedYear + '-' + formattedMonth] = {}
        }

        if (!emptyCells.hasOwnProperty(formattedYear + '-' + formattedMonth)) {
          emptyCells[formattedYear + '-' + formattedMonth] = {}
        }

        emptyCells[formattedYear + '-' + formattedMonth] = {
          start: (weekOrder[this.weekStarts][loopDate.format('E')] - 1),
          end: (6 * 7) - (daysInMonth + (weekOrder[this.weekStarts][loopDate.format('E')] - 1))
        }

        for (let d = 1; d <= daysInMonth; d++) {
          const formattedDay = loopDate.format('D')
          const calendarDayOfWeek = loopDate.format('E')
          const formattedDate = loopDate.format('YYYY-MM-DD')
          const hasBooking = (bookings.indexOf(formattedDate) > -1)
          const hasUnbookableDate = (unbookableDays.indexOf(formattedDate) > -1)
          const dateInPast = this.isDateInPast(formattedDate)
          const rateNotAvailable = ratesNotAvailable.indexOf(formattedDate) > -1
          let season = seasons[formattedDate]
          if (dateInPast || hasBooking) {
            season = ''
          }
          calendar[formattedYear + '-' + formattedMonth][formattedDay] = {
            calendarDayOfWeek: parseInt(calendarDayOfWeek),
            weekOrder: weekOrder[this.weekStarts][calendarDayOfWeek],
            date: formattedDate,
            booking: hasBooking,
            dateInPast,
            disabled: (hasBooking || dateInPast || hasUnbookableDate || rateNotAvailable),
            season,
            unbookableDay: hasUnbookableDate,
            rateNotAvailable
          }
          loopDate = loopDate.add(1, 'days')
        }
      }
      this.calendar = calendar
      this.emptyCells = emptyCells
      this.showRatesNotAvailableKey = ratesNotAvailable.length > 0
    }
  },

  mounted () {
    this.calendarStructure()
  },

  data () {
    return {
      weekDays: [],
      calendar: {},
      emptyCells: {},
      showRatesNotAvailableKey: 1
    }
  }
}
</script>

<style lang="scss">
$season-color-booked: #989998;
$season-color-not-booked: #98d9ef;
$season-color-past: white;
$season-color-rates-not-available: #FADFDC;
$calendar-cell-date-color: #808080;

  .key-area{
    margin-top:10px;
    font-family: "Arsenal", sans-serif;
    h4{
      font-weight: bold;
      color: #696969;
    }
    p{
      // color:blue;
    }

    .keys-area {
      line-height: 24px;
      span.key {
        margin-left: 20px;
        margin-right:8px;
        width:24px;
        height:24px;
        display: inline-block;
        &.season-available{
          background-color: $season-color-not-booked;
        }
        &.season-unavailable{
          background-color: $season-color-booked;
        }
        &.season-rates-not-available{
          background-color: $season-color-rates-not-available;
        }
      }

    }

  }

  .calendar-container {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -5px;
    margin-right: -5px;

    .month-container {
      padding: 10px;
      text-align: center;

      @media (max-width: 576px) {
        flex-basis: 100%;
      }

      @media (min-width: 577px) and (max-width: 768px) {
        flex-basis: 50%;
      }

      @media (min-width: 769px) and (max-width: 992px) {
        flex-basis: 50%;
      }

      @media (min-width: 993px) and (max-width: 1200px) {
        flex-basis: 33.33333333333%;
      }

      @media (min-width: 1201px) {
        flex-basis: 25%;
      }

      .month {

        h4 {
          font-family: "Arsenal", sans-serif;
          font-weight: bold;
          position: absolute;
          margin-left: 10%;
          margin-right: 10%;
          width: 80%;
          line-height: 35px;
          font-size: 1.1em;
          z-index: 2;
          color: #696969;
          border-top: 1px solid #d4d4d4;
        }

        .week {
          display: flex;
          flex-wrap: wrap;

          &.heading {
            font-weight: bold;
            color: #696969;

            .day {
              &:last-of-type{
                border-right: 1px solid #d4d4d4;
              }
              &:hover {
                cursor: auto;
                box-shadow: none;
                -webkit-box-shadow: none;
                position: static;
                color: inherit;
              }
            }
          }

          &.faux-month-heading{
            position:relative;
            .day{
              border:0;
              line-height: 35px;
              &:hover {
                cursor: auto;
                box-shadow: none;
                -webkit-box-shadow: none;
                position: static;
                color: inherit;
              }
              &:first-of-type{
                border-left: 1px solid #d4d4d4;
                border-top: 1px solid #d4d4d4;
                border-top-left-radius: 7px;
              }
              &:last-of-type{
                border-right: 1px solid #d4d4d4;
                border-top: 1px solid #d4d4d4;
                border-top-right-radius: 7px;
              }
            }
          }

          .day {
            border-left: 1px solid #d4d4d4;
            border-top: 1px solid #d4d4d4;
            background-color: white;
            flex-basis: 14.285714285714286%;
            line-height: 45px;
            font-size: 10px;
            transition: all 0.3s ease;

            &.calendarDays{
              color: $calendar-cell-date-color;
              &:nth-child(7), &:nth-child(14), &:nth-child(21), &:nth-child(28), &:nth-child(35), &:nth-child(42){
                  border-right: 1px solid #d4d4d4;
              }
              &:nth-last-child(-n+7){
                border-bottom: 1px solid #d4d4d4;
              }
              &:nth-last-child(1){
                border-bottom: 1px solid #d4d4d4;
                border-bottom-right-radius: 7px;
              }
              &:nth-last-child(7){
                border-bottom: 1px solid #d4d4d4;
                border-bottom-left-radius: 7px;
              }
            }

            &:hover {
              position: relative;
              z-index: 1;
              color: #00ca9d;
              -webkit-box-shadow: 0 0 10px 3px rgba(66,75,83,.4);
              box-shadow: 0 0 10px 3px rgba(66,75,83,.4);
              cursor: pointer;
              font-weight: bold;
            }

            &.unbookable-day{
              background-color: lighten($season-color-booked, 30%);
            }
            &.rate-not-available{
              background-color: $season-color-rates-not-available;
            }

            &.unbookable-day, &.rate-not-available{
              &:hover {
                cursor: auto;
                box-shadow: none;
                -webkit-box-shadow: none;
                position: static;
                color: #606060;
                font-weight: normal;
              }
            }

            &.booking {
              font-weight: bold;
              background: $season-color-booked;
              color: #606060;

              &:hover {
                // cursor: pointer;
                box-shadow: none;
                -webkit-box-shadow: none;
                position: static;
                // background: rgba(0,202,157,0.5);
              }

              &.disabled-event {
                &:hover {
                  cursor: auto;
                  // background: #00ca9d;
                }
              }

              &.past {
                background-color: $season-color-past;
                // background: rgba(0,202,157,0.5);
                // color: rgba(#000000, 0.3);
                font-weight: normal;

                &:hover {
                  // background-color: #fae5d7;
                  // background: rgba(0,202,157,0.5);
                  font-weight: normal;
                }
              }
            }

            &.empty{
              background-color: white;
            }

            &.past{
              background-color: $season-color-past;
            }

            &.available {
              background-color: $season-color-not-booked;
            }

            &.empty, &.past {
              // color: rgba(#000000, 0.3);

              &:hover {
                cursor: auto;
                box-shadow: none;
                -webkit-box-shadow: none;
                position: static;
                font-weight: normal;
              }

              &.start {} // start of month
              &.end {} // end of month
            }
          }
        }
      }
    }
  }
</style>
