// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from '@/App'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import router from '@/router'
import store from '@/store'

import vuexI18n from 'vuex-i18n'
import HeadFul from 'vue-headful'

import BootstrapVue from 'bootstrap-vue'

import VueSelect from 'vue-select'
import 'vue-select/src/scss/vue-select.scss'
import VueScrollTo from 'vue-scrollto'
import ToggleButton from 'vue-js-toggle-button'
import VueTimers from 'vue-timers'
import VueMoment from 'vue-moment'
import moment from 'moment'
import VueMarkdown from 'vue-markdown'
import VueCurrencyFilter from 'vue-currency-filter'
import VCalendar from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
import AddressLookup from '@/components/widgets/form-fields/address-lookup.vue'

import translationsEn from '@/translations/en.js'
import VeeValidate from 'vee-validate'

import VuejsDialog from 'vuejs-dialog'
import apiCaller from '@/plugins/apiCaller'
import VueGtag from 'vue-gtag'
// import VuejsDialogMixin from 'vuejs-dialog/vuejs-dialog-mixin.min.js' // only needed in custom components

// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import {
  LOG_OUT
} from '@/store/actions/authnew'

import {
  MODAL_WARNING_THRESHOLD
} from '@/store/actions/config'

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API,
    releaseStage: process.env.VUE_APP_RELEASE_STAGE,
    plugins: [new BugsnagPluginVue()],
    onError: function (event) {
    // Get Local storage and add to meta data
      const localStorage = window.localStorage
      event.addMetadata('Session', {
        ...localStorage
      })

      event.setUser(localStorage.getItem('userId'), localStorage.getItem('userEmail'))
    }
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)
}

// Tell Vue to install the plugin.
Vue.use(VuejsDialog, {
  html: true
})

Vue.use(VeeValidate, {
  // this is the default
  inject: true,
  // important to name this something other than 'fields'
  fieldsBagName: 'veeFields'
})

VeeValidate.Validator.extend('verify_password', {
  getMessage: (field) => 'The password must be at least 8 characters long, and contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)',
  validate: (value) => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
    return strongRegex.test(value)
  }
})

Vue.use(vuexI18n.plugin, store)
Vue.i18n.add('en', translationsEn)
Vue.i18n.set('en')

Vue.use(VueCurrencyFilter, {
  symbol: '£',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false
})

Vue.component('vue-markdown', VueMarkdown)
Vue.component('address-lookup', AddressLookup)

Vue.component('vue-headful', HeadFul)

Vue.use(BootstrapVue)
Vue.component('v-select', VueSelect)
Vue.use(VueScrollTo, {
  offset: -60
})

Vue.use(ToggleButton)
Vue.use(VueTimers)
Vue.use(VueMoment)
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_ID,
    enabled: process.env.NODE_ENV === 'production'
  }
}, router)

Vue.mixin({
  methods: {
    displayBathrooms: (bathrooms) => {
      return bathrooms.replace('.0', '')
    }
  }
})

/* mixin to randomise the string in the autocomplete field so autocomplete not filled out as can be lots of different customer names and addresses */
Vue.mixin({
  mounted () {
    if (this.$options.name === 'BFormInput') {
      if (this.$options.propsData.autocomplete === 'off') {
        const randomAutocompleteString = this.makeRandomString(12)
        this.$el.setAttribute('autocomplete', randomAutocompleteString)
      }
    }
  },
  methods: {
    makeRandomString (length) {
      const result = []
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
      }
      return result.join('')
    }
  }
})

Vue.use(VCalendar, {
  firstDayOfWeek: 1, // Monday
  popoverVisibility: 'focus', // have to click it rather than it open on hover which is its default behaviour
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['DD/MMM/YYYY', 'L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'L'
  }
})

Vue.use(apiCaller, {
  baseUrl: process.env.VUE_APP_API_URL,
  hooks: {
    onRequest: (config) => {
      if (localStorage.hasOwnProperty('user-token')) {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('user-token')
      }

      const isImpersonating = !!localStorage.getItem('isImpersonating')
      const userAdminId = localStorage.getItem('userAdminId')

      if (isImpersonating && userAdminId !== null) {
        config.headers['X-Impersonate-Admin-User-Id'] = userAdminId
      }

      return config
    },

    onResponse: (response) => {
      // console.log(response)

      let sessionTimeRemaining = 0
      const latency = 10
      if (response.headers.hasOwnProperty('x-api-user-token-expiry')) {
        sessionTimeRemaining = parseInt(response.headers['x-api-user-token-expiry'])
        // sessionTimeRemaining = 30 // for debug purposes to test the sessin expiry window opening
      }

      const sessionExpiry = moment().add((sessionTimeRemaining - latency), 'seconds').format('YYYY-MM-DD HH:mm:ss')
      const logoutWarning = moment(sessionExpiry, 'YYYY-MM-DD HH:mm:ss').subtract(MODAL_WARNING_THRESHOLD, 'seconds').format('YYYY-MM-DD HH:mm:ss')

      localStorage.setItem('session-expires', sessionExpiry)
      localStorage.setItem('logout-warning-opens', logoutWarning)

      // console.log('onResponse')
      return response
    },

    onRequestError: (error) => {
      return new Promise((resolve, reject) => {
        // console.log('Could not make request to server')
        // console.log(error.response)
        reject(error)
      })
    },

    onResponseError: (error) => {
      return new Promise((resolve, reject) => {
        // console.log('The response returned an error. :(')
        const defaultErrorMessage = 'Sorry there was an error performing the request - please refresh the page, try again or please contact us if the problem persists.'
        let displayAlertError = true
        if (error.config.hasOwnProperty('displayErrorAlert')) {
          displayAlertError = error.config.displayErrorAlert
        }
        let displayAlertErrorMessage = defaultErrorMessage
        let displayAlertTitle = 'ERROR'
        switch (error.response.status) {
          case 401:
            displayAlertError = false
            if (store.getters.isAuthenticated) {
              store.dispatch(LOG_OUT, { timedOut: false })
            }
            break
          case 404:
            displayAlertError = true
            displayAlertErrorMessage = 'Sorry the page or requested resource either doesn\'t exist or no longer exists.'
            break
          case 422:
            displayAlertTitle = 'The following form fields contain errors'
            // validation errors
            if (error.response.data) {
              const errorData = error.response.data
              if (errorData.length) {
                const errorDataParsed = JSON.parse(errorData)
                let response
                if (errorDataParsed.hasOwnProperty('response')) { // api server ones are nested under response
                  response = errorDataParsed.response
                } else {
                  response = errorDataParsed // cloud functions dont have it nested under response
                }
                if (response.hasOwnProperty('validationErrors')) {
                  displayAlertErrorMessage = '<ul>'
                  const responseValidationErrors = response.validationErrors
                  for (const i in responseValidationErrors) {
                    displayAlertErrorMessage += '<li>' + responseValidationErrors[i].error + '</li>'
                  }
                  displayAlertErrorMessage += '</ul>'
                }
              }
            }
            // displayAlertError = true
            break
          case 500:
            displayAlertError = true
            // server error - default error message ok
            break
          default:
            // 400 errors should have an error message
            let errors = false
            if (error.response.data) {
              const errorData = error.response.data
              if (errorData.length) {
                const errorDataParsed = JSON.parse(errorData)
                if (errorDataParsed.hasOwnProperty('errors')) {
                  errors = errorDataParsed.errors.join(',')
                }
              }
            }
            if (errors) {
              displayAlertErrorMessage = errors
            }
        }

        if (displayAlertError) {
          Vue.dialog.alert({ body: displayAlertErrorMessage, title: displayAlertTitle })
        }
        reject(error)
      })
    }
  }
})

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const currentRole = localStorage.getItem('userRole')
  const loggedIn = (localStorage.getItem('user-token') !== null)
  if (loggedIn) {
    if (localStorage.getItem('accountSignupIncomplete') !== null && !to.fullPath.includes('/admin/agent/complete-signup')) {
      return next('/admin/agent/complete-signup')
    }

    if (to.fullPath === '/login') {
      return next('/') // already logged in, no need to go to the login page - redirect to home page
    }
    if (to.meta.hasOwnProperty('roles')) {
      if (to.meta.roles.includes(currentRole)) {
        return next()
      } else {
        return next('/?rolestatus=invalid')
      }
    } else {
      return next()
    }
  } else {
    // check if we're signing an agent up
    if (to.fullPath.includes('/admin/agent/signup/')) {
      return next()
    }
    // chck if were doing a PasswordReset
    if (to.fullPath.includes('/admin/agent/password-reset/')) {
      return next()
    }

    // Check if we're doing an impersonation
    if (to.fullPath.includes('/admin/impersonate')) {
      return next()
    }

    // to stop from being redirected also need to add the above in the app.vue sessionCountDown function - TODO add these non redirected routes to some config somewhere and use that in both of these places.

    // not logged in
    if (to.fullPath !== '/login') {
      return next('/login')
    }
    // else we should be going to /login
    return next()
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App }
})
