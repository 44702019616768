<template>
  <div id="app" v-bind:class="{nomargin: noMargin}">
    <vue-headful :title="pageMeta.title"></vue-headful>
    <Header></Header>
    <router-view @setPageMetaInfo="updatePageTitleAndMetaInfo"></router-view>

    <page-footer></page-footer>

    <b-modal ref="logoutWarning" id="logoutWarning" size="lg" title="Are you still there?" @hide="modalEvents" centered hide-header-close cancel-title="Log out" ok-title="Keep me logged in">
      <b-container fluid>
        <b-row>
          <b-col>
            <p>You have been inactive for a little while, and will be logged out.</p>
            <p>
              <strong>Any unsaved changes in your account will be lost.</strong><br/>If you need more time, click <strong>keep me logged in</strong>.
            </p>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col cols="auto">
            <div class="seconds-remaining">
              <div class="inner">
                {{ sessionTimeHMS }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-progress
              height="2rem"
              :value="parseInt($store.getters.getWarningThreshold, 10) - parseInt(sessionTimeRemaining, 10)"
              :max="parseInt($store.getters.getWarningThreshold, 10)"
              class="mb-2"
              variant="danger"></b-progress>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!-- <b-modal ref="apiServerErrorModal" @hide="dismissApiServerError" title="Server Error" header-bg-variant="danger" header-text-variant="light" ok-only hide-header-close>
      <b-container fluid>
        <b-row>
          <b-col>
            <span v-html="this.$store.getters.apiServerErrorMessage"></span>
            <page-errors v-if="apiServerErrors" :pageErrors="pageErrors" ></page-errors>
          </b-col>
        </b-row>
      </b-container>
    </b-modal> -->
  </div>
</template>

<script>
import Header from '@/components/base/Header.vue'
import pageErrors from '@/components/widgets/page-errors'
import PageFooter from '@/components/base/PageFooter'
import {
  LOG_OUT,
  LOG_IN_SUCCESS
} from '@/store/actions/authnew'
import { UPDATE_CONFIG } from '@/store/actions/config'
import { UPDATE_USER_ROLE } from '@/store/actions/usernew'
import moment from 'moment'

export default {
  name: 'app',

  timers: {
    sessionCountDown: {
      time: 1000,
      repeat: true,
      autostart: true
    }
  },

  components: {
    Header,
    PageFooter,
    // eslint-disable-next-line vue/no-unused-components
    'page-errors': pageErrors
  },

  watch: {
    '$route.meta': {
      handler: function (newVal) {
        let title = 'Villa Choices'
        if (newVal.pageTitle) {
          title += (' / ' + newVal.pageTitle)
        }
        this.pageMeta.title = title
      },
      deep: true
    }
  },

  created () {
    document.getElementById('warningMess').classList.remove('vue-error')
    document.getElementById('loading').classList.add('hidden')

    if (localStorage.hasOwnProperty('config')) {
      // update config from values last stored in localStorage
      this.$store.dispatch(UPDATE_CONFIG, JSON.parse(localStorage.getItem('config')))
    }
    if (localStorage.hasOwnProperty('userRole')) {
      // update user role from values last stored in localStorage
      this.$store.dispatch(UPDATE_USER_ROLE, localStorage.getItem('userRole'))
    }
  },

  mounted () {
    // console.log(this.$store)
  },

  computed: {
    noMargin () {
      return this.$route.meta.noMargin
    },

    modalTime () {
      return this.$store.getters.getWarningThreshold
    },

    sessionTimeHMS () {
      return new Date(this.sessionTimeRemaining * 1000).toISOString().substr(14, 5)
    }
  },

  methods: {

    updatePageTitleAndMetaInfo (pageMeta) {
      let title = 'Villa Choices'
      if (pageMeta.title) {
        title += (' / ' + pageMeta.title)
        this.pageMeta.title = title
      }
    },

    sessionCountDown () {
      if (this.$router.currentRoute.path.includes('/admin/agent/signup/')) {
        // agent is signing up we dont want to redirect to /login or log them out.
        return
      }

      if (this.$router.currentRoute.path.includes('/admin/agent/password-reset/')) {
        // agent is changing their password.
        return
      }

      if (this.$router.currentRoute.path.includes('/admin/impersonate')) {
        // Impersonating a user
        return
      }

      if (this.$store.getters.isAuthenticated) {
        if (localStorage.hasOwnProperty('user-token')) {
          this.sessionTimeRemaining = moment(localStorage.getItem('session-expires'), 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'seconds')
          const now = moment()
          if (now > moment(localStorage.getItem('session-expires'), 'YYYY-MM-DD HH:mm:ss')) {
            this.$refs.logoutWarning.hide()
            this.$store.dispatch(LOG_OUT, { timedOut: true })
          } else {
            if (now >= moment(localStorage.getItem('logout-warning-opens'), 'YYYY-MM-DD HH:mm:ss')) {
              this.$refs.logoutWarning.show()
            } else {
              this.$refs.logoutWarning.hide()
            }
          }
        } else {
          this.$refs.logoutWarning.hide()
          // probably logged out from another window
          this.logout()
        }
      } else {
        if (localStorage.hasOwnProperty('user-token')) {
          // probably logged out from another window
          this.$store.dispatch(LOG_IN_SUCCESS)
          return
        }

        this.sessionTimeRemaining = 0

        if (this.$route.fullPath !== '/login') {
          this.$router.push('/login')
        }
      }
    },

    modalEvents (evt) {
      switch (evt.trigger) {
        case 'backdrop': // modal backdrop clicked
          this.renewSession()
          break
        case 'ok': // keep me logged in
          this.renewSession()
          break
        case 'esc': // escape key pressed
          this.renewSession()
          break
        case 'cancel': // log out button
          this.logout()
          break
      }
    },

    logout () {
      this.$store.dispatch(LOG_OUT, { timedOut: false })
    },

    renewSession () {
      const apiUrl = '/extend-session'
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        this.$refs.logoutWarning.hide('renew-session')
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },

  data () {
    return {
      sessionTimeRemaining: localStorage.getItem('session-expires') ? moment(localStorage.getItem('session-expires'), 'YYYY-MM-DD HH:mm:ss').diff(moment(), 'seconds') : 0,

      pageMeta: {
        title: this.$route.meta.title ? this.$route.meta.title : 'Villa Choices'
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_custombootstrap.scss';
  @import '@/assets/styles/_global.scss';
  .modal-dialog {
    text-align: center;

    .seconds-remaining {
      color: #ffffff;
      font-weight: bold;
      background: $danger;
      border-radius: 50%;
      margin: 10px;
      width: 100px;
      height: 100px;
      position: relative;
      font-size: 1.7em;

      .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
</style>
