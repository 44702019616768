<template>
  <b-container id="communityPage" v-if="!loadingPagination">
    <masthead image-url="Community-Header.jpg" title="Communities"></masthead>

    <section class="page-intro">
      <p>
        {{ communityPage.description }}
      </p>

      <letter-pagination :character-count="communityPage.pagination"></letter-pagination>
    </section>

    <div class="loader-outer" v-if="loadingCommunities">
      <loader width="100px" height="100px"></loader>
    </div>
    <search-result v-else v-for="(result, key) in communityPage.communities" :title="result.fullName" :key="key"
                   :image="result.image" :description="result.shortDesc"
                   button-text="View details"
                   :icons="result.icons"
                   :resultURL="'/community/' + result.comId" variant="secondary"></search-result>

  </b-container>
  <div class="loader-outer" v-else>
    <loader width="100px" height="100px"></loader>
  </div>
</template>

<script>
import loader from '@/components/widgets/loader'

const Masthead = () => ({
  component: import('@/components/widgets/masthead'),
  loading: loader
})

const SearchResult = () => ({
  component: import('@/components/widgets/search-result'),
  loading: loader
})

const LetterPagination = () => ({
  component: import('@/components/widgets/letter-pagination'),
  loading: loader
})

export default {
  name: 'Communities',
  components: {
    Masthead,
    LetterPagination,
    SearchResult,
    loader
  },

  watch: {
    '$route.params': {
      handler (newVal) {
        this.getCommunityListForCurrentLetter()
      },
      deep: true
    }
  },

  mounted () {
    this.getCommunityList()
  },

  methods: {

    getCommunityList () {
      this.loadingPagination = true
      this.loadingCommunities = true

      const apiUrl = '/communities-list'
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        const communitiesForLetters = {}
        for (const i in response.data.response) {
          const thisCommunity = response.data.response[i]
          const characterForPagination = thisCommunity.value.charAt(0).toUpperCase()
          if (!communitiesForLetters.hasOwnProperty(characterForPagination)) {
            communitiesForLetters[characterForPagination] = 0
          }
          communitiesForLetters[characterForPagination]++
        }
        for (const i in this.communityPage.pagination) {
          if (communitiesForLetters.hasOwnProperty(i)) {
            this.communityPage.pagination[i] = communitiesForLetters[i]
          } else {
            this.communityPage.pagination[i] = 0
          }
        }
        this.loadingPagination = false
        this.getCommunityListForCurrentLetter()
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    },

    getCommunityListForCurrentLetter () {
      this.loadingCommunities = true
      this.communityPage.communities = []
      const apiUrl = '/communities-list/' + this.$route.params.letter
      this.$apiCaller({ route: apiUrl, method: 'GET', data: '' }).then((response) => {
        this.communityPage.communities = response.data.response
        const title = 'Communities / ' + 'Beginning with ' + this.$route.params.letter
        this.$emit('setPageMetaInfo', { title })
        this.loadingCommunities = false
      })
        .catch(() => {
          // set page errors
        })
        .finally(() => {
          // set loaded
        })
    }
  },
  data () {
    return {
      loadingPagination: true,
      loadingCommunities: true,
      communityPage: {
        description: 'Making the right choice for your holiday home is important, but so is choosing the right community to stay on. Here is an in-depth look at each of the communities we have rental villas, town homes and apartments on in the popular Orlando areas of Kissimmee, Davenport, Clermont and Haines City. We have tried to be as accurate as possible with our descriptions and photographs, and all information was correct at the time we visited each community, however, please be aware that any services, facilities or opening times we mention may be subject to change. These changes are outside of our control and often we are not informed about them beforehand.',
        communities: [],
        pagination: {
          A: 0,
          B: 0,
          C: 0,
          D: 0,
          E: 0,
          F: 0,
          G: 0,
          H: 0,
          I: 0,
          J: 0,
          K: 0,
          L: 0,
          M: 0,
          N: 0,
          O: 0,
          P: 0,
          Q: 0,
          R: 0,
          S: 0,
          T: 0,
          U: 0,
          V: 0,
          W: 0,
          X: 0,
          Y: 0,
          Z: 0
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/styles/_variables.scss';
  @import '@/assets/styles/pages/communities/Communities.scss';
</style>
